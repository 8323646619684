import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "../../../../components/button/Button";
import { Heading } from "../../../../components/heading/Heading";
import { IconLogoSuccessFactoryNoText } from "../../../../components/icon/IconLogoSuccessFactoryNoText";
import { Panel } from "../../../../components/panel/Panel";
import Price from "../../../../components/price/Price";
import { PaymentSourceScrCredit, PaymentSourceShopCredit } from "../../../../graphql/schema";
import { PaymentMethodsSource } from "../payment-methods-section/PaymentMethodsSection";

import styles from "./top-up-section.module.scss";

interface TopUpSectionProps {
  topUpRoute: string;
  paymentSources?: PaymentMethodsSource[] | null;
}

export const TopUpSection: FC<TopUpSectionProps> = ({ paymentSources, topUpRoute }) => {
  // access translation keys
  const { t } = useTranslation();
  const { push } = useHistory();

  const topUp = getShopCreditInfo(paymentSources);
  const scrInfo = getScrCreditInfo(paymentSources);

  return (
    <Panel className={styles.wrap} contentClassName={styles["wrap-content"]}>
      <Panel className={styles.panel} contentClassName={styles.content} panelStyle="TERTIARY">
        <IconLogoSuccessFactoryNoText className={styles.logo} width={41} height={50} />
        <span className={styles.credit}>
          {topUp ? (
            <Price price={topUp.available} currency={topUp.currency.code} hideSign />
          ) : (
            // empty placeholder to prevent content 'jumping' when data hasn't loaded
            <div style={{ height: "37px" }} />
          )}
        </span>
        <span className={styles.label}>{t("USDS")}</span>
        <Button
          className={styles.button}
          height="MEDIUM"
          kind="SOLID"
          shape="RECT"
          borderRadius="MEDIUM"
          color="ORANGE"
          weight="BOLD"
          fontSize={16}
          onClick={() => push(topUpRoute)}
        >
          {t("Top up")}
        </Button>
      </Panel>

      <div className={styles["tip-wrap"]}>
        <Heading className={styles["tip-title"]} level={6}>
          <span dangerouslySetInnerHTML={{ __html: t(`<strong>TIP</strong> about payment methods`) }} />:
        </Heading>
        <p className={styles.tip}>
          {t(
            "When making a payment, the system first deducts from your SCR balance, using as much as available but not exceeding the maximum allowable amount per product. Any remaining amount is then covered using your USDS balance.",
          )}
        </p>
      </div>
      <Panel className={styles.panel} contentClassName={styles.content} panelStyle="TERTIARY">
        <IconLogoSuccessFactoryNoText className={styles.logo} width={41} height={50} />
        <span className={styles.credit}>
          {scrInfo ? (
            <Price price={scrInfo.available} currency={scrInfo.currency.code} hideSign />
          ) : (
            // empty placeholder to prevent content 'jumping' when data hasn't loaded
            <div style={{ height: "37px" }} />
          )}
        </span>
        <p className={styles.label}>{t("SCR")}</p>
        <div className={styles.button} />
      </Panel>
    </Panel>
  );
};

function getShopCreditInfo(sources?: PaymentMethodsSource[] | null) {
  if (!sources) {
    return null;
  }

  const topUpSource = sources.filter((e) => e.info && e.info.__typename === "PaymentSourceShopCredit")[0];

  return topUpSource.info as PaymentSourceShopCredit;
}
function getScrCreditInfo(sources?: PaymentMethodsSource[] | null) {
  if (!sources) {
    return null;
  }

  const topUpSource = sources.filter((e) => e.info && e.info.__typename === "PaymentSourceScrCredit")[0];

  return topUpSource.info as PaymentSourceScrCredit;
}
