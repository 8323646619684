import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ServiceProductCode, useProductHunterSystemsViewQuery } from "../../graphql/schema";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";
import { translateProductName } from "../../services/translateProductName";
import NotFoundView from "../not-found-view/NotFoundView";
import { ProductHunterSystemsIntroSection } from "./intro-section/ProductHunterSystemsIntroSection";
import { ProductHunterSystemsBannerSection } from "./banner-section/ProductHunterSystemsBannerSection";

export const ProductHunterSystemsView: React.FC = () => {
  const { data, loading, called } = useProductHunterSystemsViewQuery({
    variables: {
      productCodes: [
        ServiceProductCode.HUNTER_SYSTEMS_BASIC,
        ServiceProductCode.HUNTER_SYSTEMS_STANDARD,
        ServiceProductCode.HUNTER_SYSTEMS_PLUS,
        ServiceProductCode.HUNTER_SYSTEMS_PRO,
      ],
    },
  });
  const { t } = useTranslation();

  // check what product is selected
  const [selectedHunterSystemsProductCode, setSelectedHunterSystemsProductCode] = useState<ServiceProductCode>(
    ServiceProductCode.HUNTER_SYSTEMS_BASIC,
  );

  const pCodes = [
    ServiceProductCode.HUNTER_SYSTEMS_BASIC,
    ServiceProductCode.HUNTER_SYSTEMS_STANDARD,
    ServiceProductCode.HUNTER_SYSTEMS_PLUS,
    ServiceProductCode.HUNTER_SYSTEMS_PRO,
  ];

  const products = (data?.services ?? [])
    .map((product) => {
      const offer = product && product.offers.length > 0 ? product?.offers[0] : undefined;

      if (!product || !offer) {
        return null;
      }

      return {
        ...offer,
        name: translateProductName(product, t) ?? product.name,
        value: product.code,
        currencyCode: offer.currency.code,
      };
    })
    .filter(notUndefinedOrNull)
    .sort((a, b) => pCodes.indexOf(a.value) - pCodes.indexOf(b.value));

  const selectedProduct = products.find((p) => p.value === selectedHunterSystemsProductCode);
  if (!loading && called && !hasAvailableOffers(products)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      <ProductHunterSystemsBannerSection
        productCode={selectedHunterSystemsProductCode}
        options={products}
        onOptionChange={setSelectedHunterSystemsProductCode}
      />

      <ProductHunterSystemsIntroSection productCode={selectedHunterSystemsProductCode} product={selectedProduct} />
    </>
  );
};
