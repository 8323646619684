import { ServiceOfferAvailability } from "../graphql/schema";

export interface Offer {
  fullPrice: string;
  discountedPrice?: string | null;
  availability: keyof typeof ServiceOfferAvailability;
}

/** finds best offer based on availability and price */
export function findBestServiceOffer<T extends Offer>(offers: T[] = []): T | undefined {
  const cheapestOffers = sortOffersByPrice(offers, "ASC");
  const priciestOffers = sortOffersByPrice(offers, "DESC");

  return (
    filterOffersByAvailability(priciestOffers, "UNAVAILABLE_ALREADY_SUBSCRIBED")[0] ||
    filterOffersByAvailability(priciestOffers, "UNAVAILABLE_ALREADY_IN_CART")[0] ||
    filterOffersByAvailability(cheapestOffers, "AVAILABLE_FOR_UPGRADE")[0] ||
    filterOffersByAvailability(cheapestOffers, "AVAILABLE_FOR_PURCHASE")[0] ||
    cheapestOffers[0]
  );
}

function sortOffersByPrice<T extends Offer>(offers: T[], order: "ASC" | "DESC") {
  const dir = order === "ASC" ? 1 : -1;

  return [...offers].sort((a, b) => (getPriceOrDiscountPrice(a) > getPriceOrDiscountPrice(b) ? dir : dir * -1));
}

function getPriceOrDiscountPrice<T extends Offer>(offer: T) {
  return offer.discountedPrice ? Number(offer.discountedPrice) : Number(offer.fullPrice);
}

function filterOffersByAvailability<T extends { availability: keyof typeof ServiceOfferAvailability }>(
  offers: T[],
  availability: keyof typeof ServiceOfferAvailability,
) {
  return offers.filter((offer) => offer.availability === availability);
}
