import React, { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import * as yup from "yup";

import { Routes, ServiceInfo, TheViewProductCodes } from "../../../services/constants";
import { DigitalProduct } from "../../../components/digital-product/DigitalProduct";
import { Loader } from "../../../components/loader/Loader";
// import Price from "../../../components/price/Price";
import { Section } from "../../../components/section/Section";
import { notUndefinedOrNull } from "../../../services/notUndefinedOrNull";
// import { isFirstPeriodDiscounted } from "../../../services/isFirstPeriodDiscounted";
import { findBestServiceOffer } from "../../../services/findBestServiceOffer";
import {
  ServiceProductCode,
  useDigitalProductsViewQuery,
  PaymentEntityType,
  useDigitalProductsCreateTheViewForDagsCartMutation,
  useDigitalProductsCreateProductsForDagsCartMutation,
  ServiceOfferAvailability,
  ServiceSubscriptionStatus,
  useViewerQuery,
  UserXeraInfo,
  useRegisterWebinarMutation,
  useUpdateUserWalletMutation,
  UserWalletTypesEnum,
  useGetAwenturesLoginLinkMutation,
  ServiceProduct,
} from "../../../graphql/schema";
import { showGraphqlValidationErrors } from "../../../services/showGraphqlValidationErrors";
import NotFound from "../../../components/not-found/NotFound";
import { ServiceIcon } from "../../../components/service-icon/ServiceIcon";
import { TheView, TheViewExclusiveOfferMembership } from "./the-view/TheView";
import { OfferSubmitMethod } from "../../../components/digital-product-exclusive-offer/offers/Offers";
import { useHandelPayment } from "../../../hooks/useHandlePayment";
import assertNever from "../../../services/assertNever";
// import { useServicePeriodTranslation } from "../../../hooks/useServicePeriodTranslation";
import { hasAvailableOffers } from "../../../services/hasAvailableOffers";
import { getServicesOffers } from "../../../services/getServicesOffers";
import { PAYMENT_METHODS_ORDER } from "../../../components/payment-methods-list/PaymentMethodsList";
import { Badge } from "../../../components/badge/Badge";
import { Countdown } from "../../../components/countdown/Countdown";
import { DigitalProductBase } from "../../../components/digital-product-base/DigitalProductBase";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Button } from "../../../components/button/Button";
import { Color } from "../../../services/buttonLinkConstants";
import { Modal, ModalKind } from "../../../components/modal/Modal";
import { PanelWidth } from "../../../components/panel/Panel";
import Field from "../../../components/field/Field";
import { Link } from "../../../components/link/Link";
import { isFirstPeriodDiscounted } from "../../../services/isFirstPeriodDiscounted";
import Price from "../../../components/price/Price";
import { useServicePeriodTranslation } from "../../../hooks/useServicePeriodTranslation";
import { HunterSystems } from "./hunter-systems-view/HunterSystems";
import { translateProductName } from "../../../services/translateProductName";
import { Separator } from "../../../components/separator/Separator";

import styles from "./digital-products-view.module.scss";

interface WalletFormInfo {
  walletType: keyof typeof UserWalletTypesEnum;
  address: string;
}

type NewServiceProduct = {
  code: ServiceProductCode;
  isActive: boolean;
  isQuantifiable: boolean;
  name: string;
  label?: string;
  offers?: any[];
};
// Validation schemas for wallet addresses
const walletAddressSchemas: Record<UserWalletTypesEnum, yup.StringSchema<string | undefined>> = {
  BEP20: yup.string().matches(/^0x[a-fA-F0-9]{40}$/, "Invalid BEP20 wallet address"),
  ERC20: yup.string().matches(/^0x[a-fA-F0-9]{40}$/, "Invalid ERC20 wallet address"),
  TRC20: yup.string().matches(/^T[a-zA-Z0-9]{33}$/, "Invalid TRC20 wallet address"),
  SOL: yup.string().matches(/^[1-9A-HJ-NP-Za-km-z]{32,44}$/, "Invalid SOL wallet address"),
};
//window.open(result.link, '_blank');
// Validation schema for the form
const walletValidationSchema: yup.SchemaOf<WalletFormInfo> = yup.object().shape({
  walletType: yup
    .mixed<UserWalletTypesEnum>()
    .oneOf(
      [UserWalletTypesEnum.BEP20, UserWalletTypesEnum.ERC20, UserWalletTypesEnum.SOL, UserWalletTypesEnum.TRC20],
      "Invalid wallet type",
    )
    .required("Wallet type is required"),
  address: yup
    .string()
    .when("walletType", (walletType: string, schema: yup.StringSchema<string | undefined>) => {
      const selectedSchema = walletAddressSchemas[walletType as keyof typeof walletAddressSchemas];
      return selectedSchema || schema;
    })
    .required("Wallet address is required"),
});

function useWeb3WalletForm() {
  const { register, handleSubmit, errors, reset } = useForm<WalletFormInfo>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(walletValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
    reset,
  };
}

type WebinarFormInfo = {
  email: string;
};
function useRegisterWebinarForm() {
  const webinarInfoValidationSchema: yup.SchemaOf<WebinarFormInfo> = yup.object().shape({
    email: yup.string().email().required(),
  });

  const { register, handleSubmit, errors } = useForm<WebinarFormInfo>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(webinarInfoValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
  };
}

export default function DigitalProductsView() {
  const [t] = useTranslation();
  // const getPeriodName = useServicePeriodTranslation();
  const { push } = useHistory();
  const {
    register: webinarRegistration,
    handleSubmit: handleWebinarSubmit,
    errors: webinarInputErrors,
  } = useRegisterWebinarForm();

  const {
    register: addWallet,
    handleSubmit: handleWalletSubmit,
    errors: walletInputErrors,
    reset: resetWallet,
  } = useWeb3WalletForm();

  const query = useDigitalProductsViewQuery({
    variables: { theViewProductCodes: TheViewProductCodes, paymentMethod: PAYMENT_METHODS_ORDER[0] },
  });
  const [paymentResId, setPaymentResId] = useState<string | null>(null);
  const [createTheViewForDagsCart] = useDigitalProductsCreateTheViewForDagsCartMutation();
  const [registerWebinar] = useRegisterWebinarMutation();
  const [updateUserWallet] = useUpdateUserWalletMutation();
  const [getAdventuresLink] = useGetAwenturesLoginLinkMutation();
  const [createProductsForDagsCart] = useDigitalProductsCreateProductsForDagsCartMutation();
  const handleTheViewForDagsPayment = useHandelPayment({
    onSuccess: () => push(`${Routes.CHECKOUT_RESULT}/CART_PURCHASE_OF_SERVICE_PRODUCTS/${paymentResId}`),
    onError: () => toast.error(t("Something went wrong")),
    onUnknownResponse: () => console.warn("payment status unknwon"),
  });

  const getPeriodName = useServicePeriodTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLHMetaverseModalOpen, setIsLHMetaverseModalOpen] = useState(false);
  const [isTravelViewModalOpen, setIsTravelViewModalOpen] = useState(false);
  const [isWeb3ModalOpen, setIsWeb3ModalOpen] = useState(false);
  const [isHunterSystemsModalOpen, setIsHunterSystemsModalOpen] = useState(false);
  const [isAirdropsModalOpen, setIsAirdropsModalOpen] = useState(false);
  const [isWebinarsModalOpen, setIsWebinarsModalOpen] = useState(false);
  const [isDealsModalOpen, setIsDealsModalOpen] = useState(false);
  const [isPlatformFeeModalOpen, setIsPlatformFeeModalOpen] = useState(false);
  const [isMarketingFeeModalOpen, setIsMarketingFeeModalOpen] = useState(false);

  const { data: me, refetch: meRefetch } = useViewerQuery({ fetchPolicy: "network-only" });
  // query.data?.businessFeePro,
  const services: NewServiceProduct[] = [
    query.data?.platformFee,
    query.data?.marketingFee,
    {
      __typename: "ServiceProduct",
      code: ServiceProductCode.BANK_BASIC,
      name: "DAGS Community",
      isActive: false,
      offers: [],
      isQuantifiable: false,
    } as ServiceProduct,
    query.data?.awentures,
    query.data?.bancus,
    {
      __typename: "ServiceProduct",
      code: ServiceProductCode.HUNTER_SYSTEMS_BASIC,
      name: "HunterSystems",
      isActive: false,
      offers: [],
      isQuantifiable: false,
    } as ServiceProduct,
    {
      __typename: "ServiceProduct",
      code: ServiceProductCode.BANK_FULL,
      name: "Xera",
      isActive: false,
      offers: [],
      isQuantifiable: false,
    } as ServiceProduct,
  ].filter(notUndefinedOrNull) as NewServiceProduct[];

  // SF Business Fee active subscription
  const activePlatofrmFeeSubscription = query.data?.me.activePlatofrmFeeSubscription;
  const activeMarketingFeeSubscription = query.data?.me.activeMarketingFeeSubscription;

  const isSfMarketingFeeExipring =
    new Date(activeMarketingFeeSubscription?.dateEnd || 0).getTime() <= daysToMilliseconds(14) + Date.now() &&
    new Date(activeMarketingFeeSubscription?.dateEnd || 0) > new Date();

  // check if SF Business fee subscription grace period has expired
  const isSfMarketingFeeInGracePeriod =
    new Date(activeMarketingFeeSubscription?.gracePeriodDateEnd || 0).getTime() <=
      daysToMilliseconds(30) + Date.now() &&
    new Date(activeMarketingFeeSubscription?.gracePeriodDateEnd || 0) > new Date();

  const isSfPlatformFeeExipring =
    new Date(activePlatofrmFeeSubscription?.dateEnd || 0).getTime() <= daysToMilliseconds(14) + Date.now() &&
    new Date(activePlatofrmFeeSubscription?.dateEnd || 0) > new Date();

  // check if SF Business fee subscription grace period has expired
  const isSfPlatformFeeInGracePeriod =
    new Date(activePlatofrmFeeSubscription?.gracePeriodDateEnd || 0).getTime() <= daysToMilliseconds(30) + Date.now() &&
    new Date(activePlatofrmFeeSubscription?.gracePeriodDateEnd || 0) > new Date();

  function getDetail(availability: keyof typeof ServiceOfferAvailability | keyof typeof ServiceSubscriptionStatus) {
    switch (availability) {
      case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      case "UNAVAILABLE_FOR_UPGRADE":
      case "ACTIVE":
      case "PAYMENT_PENDING":
      case "PAYMENT_FAILED":
      case "DISCONTINUED":
        //return "";
        return t("Active membership");

      case "AVAILABLE_FOR_PURCHASE":
      case "AVAILABLE_FOR_UPGRADE":
        return t("Purchase membership");

      case "UNAVAILABLE_ALREADY_IN_CART":
        return t("In cart");

      case "UNAVAILABLE_FOR_COUNTRY":
        return t("Unavailable");

      case "EXPIRED":
      case "CANCELLED":
        return t("Discontinued");

      default:
        return assertNever(availability);
    }
  }

  function getProductDetail(
    availability: keyof typeof ServiceOfferAvailability | keyof typeof ServiceSubscriptionStatus,
  ) {
    switch (availability) {
      case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      case "UNAVAILABLE_FOR_UPGRADE":
      case "ACTIVE":
      case "PAYMENT_PENDING":
      case "PAYMENT_FAILED":
      case "DISCONTINUED":
        //return "";
        return t("Active product");

      case "AVAILABLE_FOR_PURCHASE":
      case "AVAILABLE_FOR_UPGRADE":
        return t("Purchase product");

      case "UNAVAILABLE_ALREADY_IN_CART":
        return t("In cart");

      case "UNAVAILABLE_FOR_COUNTRY":
        return t("Unavailable");

      case "EXPIRED":
      case "CANCELLED":
        return t("Discontinued");

      default:
        return assertNever(availability);
    }
  }

  function hasAwentureAccess(
    availability: keyof typeof ServiceOfferAvailability | keyof typeof ServiceSubscriptionStatus,
  ) {
    switch (availability) {
      case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      case "UNAVAILABLE_FOR_UPGRADE":
      case "ACTIVE":
      case "DISCONTINUED":
        //return "";
        return true;

      default:
        return false;
    }
  }

  async function handleTheViewOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    const dagOffersIds = (query.data?.me.theViewForDags?.offers ?? [])
      .filter((off) => ids.includes(off.id))
      .map((off) => off.dagOffer.id);

    try {
      const cartRes = await createTheViewForDagsCart({ variables: { dagOffersIds } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createTheViewForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  async function handleSpecialOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    try {
      const cartRes = await createProductsForDagsCart({ variables: { offerIds: ids } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createProductsForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  const theViewSpecialOfferMemberships: TheViewExclusiveOfferMembership[] = (
    query.data?.me.theViewForDags?.offers ?? []
  ).map((offer) => ({
    id: offer.id,
    labelText: offer.eurOffer.labelText,
    datePurchased: offer.eurOfferPurchaseDate,
    eurPrice: offer.eurOffer.fullPrice,
    dagPrice: offer.dagOffer.fullPrice,
  }));

  const hasTheViewOffers = hasAvailableOffers(getServicesOffers(query.data?.theView ?? []));
  const hunterSystemOffers = getServicesOffers(query.data?.hunterSystems ?? []);
  const hasHunterSystemsOffers = hasAvailableOffers(hunterSystemOffers);
  const hasActiveHunterSystemsProduct = query.data?.hunterSystems?.some((product) => product.isActive);
  //   const activeProductCodes = query.data?.hunterSystems
  //     ?.filter((product) => product.isActive)
  //     ?.map((product) => product.code);
  const activeProductNames =
    query.data?.hunterSystems
      ?.filter((product) => product.isActive)
      ?.map((product) => translateProductName({ code: product.code }, t)) || [];
  const hasTheViewOffersOrAccess =
    hasAvailableOffers(getServicesOffers(query.data?.theView ?? [])) ||
    theViewSpecialOfferMemberships.length > 0 ||
    query.data?.me.theiaAccess;

  const availablePaymentMethods = query?.data?.me.paymentMethods.filter((m) => m.enabled).map((m) => m.method) ?? [];
  const dagsBalance = query?.data?.viewer?.coinAccountBalance ?? 0;
  const registerDate = query?.data?.viewer?.registerDate ?? null;
  const cutoffDate = new Date("2025-01-01T00:00:00Z");
  const displayXPro = registerDate && new Date(registerDate) < cutoffDate;

  const hasPlatformFee = query?.data?.me.activePlatofrmFeeSubscription;

  const xeraUser = (user: UserXeraInfo) => (
    <React.Fragment key={user.id}>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>
        {user.username}
        <span style={{ fontSize: "10px", color: "#666", fontWeight: 600 }}>{user.isIncomeCentre ? " (IC)" : null}</span>
      </div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraUsername}</div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraEmail}</div>
    </React.Fragment>
  );

  const handleRegisterWebinar = handleWebinarSubmit(async ({ email }) => {
    try {
      const result = await registerWebinar({
        variables: { email },
      });

      if (result.data) {
        toast.success(t("Registered Successfully!"));
        setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          // handle error?
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      console.error("Registering to Webinar failed", err);

      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message, { autoClose: false });
      }
    }
  });

  const handleWebWalletSubmit = handleWalletSubmit(async ({ address, walletType }) => {
    try {
      const result = await updateUserWallet({
        variables: { wallet: walletType as UserWalletTypesEnum, address },
      });
      if (result.data) {
        toast.success(t("Wallet added successfully!"));
        resetWallet();
        meRefetch();
        // setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          // handle error?
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      console.error("Wallet was not added", err);

      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message, { autoClose: false });
      }
    }
  });

  const handleGetAwenturesUrl = async () => {
    const url = await getAdventuresLink();

    if (url) {
      window.open(url.data?.getAwenturesLoginLink, "_blank");
    }
  };

  const handleOpenExternalUrl = async (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const renderXPro = (users: UserXeraInfo[]) => (
    <DigitalProductBase
      key={"XPRO"}
      title={t("XPRO Login Details")}
      logo={<AsyncImg className={styles.view} src="/images/XPRO_logo.png" />}
      publicUrl={undefined}
      price={undefined}
      exclusiveOffer={undefined}
      onExclusiveOfferClick={() => null}
    >
      <Button
        className={styles["access-product-link"]}
        borderRadius="SMALL"
        color={Color.DARK_BLUE}
        fontSize={18}
        onClick={() => setIsModalOpen(true)}
      >
        <span className={styles["link-label"]}>{t("Show Details")}</span>
      </Button>
      <Modal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        kind={ModalKind.PRIMARY}
        panelProps={{
          imagePath: "/images/XPRO_logo.png",
          width: PanelWidth.SMALL,
          title: t("XPRO Login Details"),
          caption: t("Here can you see your XPRO login details"),
          label: t("XPRO"),
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
          {<AsyncImg className={styles.view} style={{ width: "100px" }} src="/images/XPRO_logo.png" />}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "0",
          }}
        >
          <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>SF user</div>
          <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
            XPRO user
          </div>
          <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
            XPRO email
          </div>
          {users.map((user) => xeraUser(user))}
        </div>
        <div
          style={{
            maxWidth: "700px",
            border: "1px solid #ddd",
            backgroundColor: "#fafafa",
            padding: "7px 15px",
            marginTop: "15px",
          }}
        >
          <p style={{ marginTop: "15px", marginBottom: "0", fontWeight: 600 }}>
            In order to gain access to your accounts in XPRO:
          </p>
          <ol>
            <li>
              Please go to the single sign-on platform{" "}
              <a style={{ color: "#ffb100" }} href="https://homnifi.com/" target="_blank" rel="noopener noreferrer">
                homnifi.com
              </a>
            </li>
            <li>Click on Go to App and there Forget Password, choose Reset Anyway.</li>
            <li>Enter the e-mail address you have used to register your account in SF</li>
            <li>Open your mailbox and click on the link you received</li>
            <li>Set up a new password and go back to front page</li>
            <li>Log in using your username (seen in the e-mail you received) or e-mail address</li>
            <li>Choose either a personal or company account</li>
            <li>
              Set up your 2-FA (2 factor authentication) with Google Authenticator (download from app stores) for
              account security
            </li>
            <li>Choose the platforms to enroll to (recommended to choose all) and you`re done!</li>
          </ol>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            XPRO operates with unique user access, disallowing special characters in usernames and duplicate e-mails. In
            case you had multiple accounts/income centers with the same e-mail or used special characters in your
            username, then your username(s) have been modified, and/or numbers have been added to duplicate e-mails for
            differentiation.
          </p>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            For example, if you previously had a main account and two income centers (or family members) registered with
            the same e-mail address, for example with yourusername@gmail.com, then your e-mails for those accounts will
            be like this: Main account - yourusername@gmail.com IC1 or family member 1: - yourusername+1@gmail.com IC2
            or family member 2: - yourusername+2@gmail.com
          </p>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            Each income center is a separate account in XPRO and you should follow steps 1-9 for each one of those
            accounts, using the new adjusted e-mail addresses (XPRO e-mail, as shown above). All the +1, +2 etc e-mails
            are forwarded to your main e-mail (yourusername@gmail.com) so you get all your logins in one and the same
            mailbox.
          </p>
          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            In case you face difficulties/issues in logging in, please submit a support ticket (
            <a
              style={{ color: "#ffb100" }}
              href="https://support.homnifi.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.homnifi.com/support/home
            </a>
            ) and the support team will assist you
          </p>
        </div>
      </Modal>
    </DigitalProductBase>
  );

  const renderDagCommunity = () => (
    <DigitalProductBase
      key={"dag-community"}
      title={t("DAGS Community")}
      logo={<AsyncImg className={styles.view} src="/images/crowd-icon.svg" />}
      publicUrl={undefined}
      price={undefined}
      exclusiveOffer={undefined}
      onExclusiveOfferClick={() => null}
      bottomMessage=""
      isLight={true}
    >
      {hasPlatformFee && (
        <Link
          to="/account/dag-community"
          shape="RECT"
          height="MEDIUM"
          weight="MEDIUM"
          color="DARK_BLUE"
          borderRadius="SMALL"
          fontSize={18}
        >
          <span className={styles["link-label"]}>{t("Read more")}</span>
        </Link>
      )}
      {!hasPlatformFee && (
        <Button
          className={styles["access-product-link"]}
          borderRadius="SMALL"
          color={Color.DARK_BLUE}
          fontSize={18}
          disabled
          onClick={() => setIsModalOpen(true)}
        >
          <span className={styles["link-label"]}>{t("Read more")}</span>
        </Button>
      )}
    </DigitalProductBase>
  );

  const handleHuntersClick = () => {
    setIsHunterSystemsModalOpen(true);
  };

  const renderHunterSystems = () => (
    <HunterSystems
      publicUrl={hasTheViewOffers ? Routes.HUNTER_SYSTEMS_LANDING : undefined}
      className={styles.view}
      packages={query.data?.hunterSystems}
      detail={!hasActiveHunterSystemsProduct ? t("Purchase product") : activeProductNames?.join(", ") ?? ""}
      key="hunter-systems-service"
      activeProducts={activeProductNames}
      hasAccess={hasActiveHunterSystemsProduct ? handleHuntersClick : null}
      exclusiveOffer={undefined}
    />
  );

  const renderTheView = () => (
    <TheView
      publicUrl={hasTheViewOffers ? Routes.THE_VIEW_LANDING : undefined}
      className={styles.view}
      packages={query.data?.theView}
      detail={hasTheViewOffers ? t("Purchase a package") : undefined}
      key="theia-service"
      hasAccess={query.data?.me.theiaAccess ?? null}
      exclusiveOffer={
        query.data?.me.theViewForDags && theViewSpecialOfferMemberships.length > 0
          ? {
              dagsBalance,
              dateStart: new Date(query.data.me.theViewForDags.dateStart),
              dateEnd: new Date(query.data.me.theViewForDags.dateEnd),
              expiresInMS: Number(query.data.me.theViewForDags.expiresInSeconds) * 1000,
              memberships: theViewSpecialOfferMemberships,
              onSubmit: handleTheViewOfferSubmit,
              availablePaymentMethods,
            }
          : undefined
      }
    />
  );

  let hunterRendered = false;
  return (
    <>
      <Section gutter={100} center withSpace className={styles.wrap}>
        <Loader visibleOnFirstLoad={query} />
        {activeMarketingFeeSubscription && isSfMarketingFeeExipring && (
          <Badge
            className={classNames(styles.note, styles["note--expiration"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription for the {{product}} will renew in", {
                  product: ServiceInfo[activeMarketingFeeSubscription.productCodes[0]].title,
                })}
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={
                activeMarketingFeeSubscription && activeMarketingFeeSubscription.dateEnd
                  ? activeMarketingFeeSubscription?.dateEnd?.toString()
                  : ""
              }
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {activeMarketingFeeSubscription && isSfMarketingFeeInGracePeriod && (
          <Badge
            className={classNames(styles.note, styles["note--grace-period"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription grace period for the {{product}} will expire in", {
                  product: ServiceInfo[activeMarketingFeeSubscription.productCodes[0]].title,
                })}
                :
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={activeMarketingFeeSubscription?.gracePeriodDateEnd.toString()}
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {services.map((service) => {
          const offer = findBestServiceOffer(service.offers);
          const offerCampaign = getExclusiveOfferCampaignInfo(service.code, query.data?.me.productsForDags ?? []);
          const exclusiveOffer = offerCampaign?.offers[0];
          const activeSubscription = query.data?.me.serviceSubscriptions.find((s) =>
            s.productCodes.includes(service.code),
          );

          if (service.code === "BANK_FULL") {
            return null;
          }
          if (service.code === "BANK_BASIC") {
            return renderDagCommunity();
          }
          if (
            !hunterRendered &&
            [
              ServiceProductCode.HUNTER_SYSTEMS_BASIC,
              ServiceProductCode.HUNTER_SYSTEMS_STANDARD,
              ServiceProductCode.HUNTER_SYSTEMS_PRO,
              ServiceProductCode.HUNTER_SYSTEMS_PLUS,
            ].includes(service.code)
          ) {
            hunterRendered = true;
            return renderHunterSystems();
          }

          if (!offer && !activeSubscription) {
            return null;
          }
          if (service.code === "MEMBERSHIP_BASIC") {
            return null;
          }

          if (service.code === "AWENTURES") {
            const hasAwenturesAccess = hasAwentureAccess(
              (offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY",
            );
            const userPhone = me?.viewer?.phone;
            return (
              <DigitalProductBase
                attention={userPhone ? false : true}
                key={service.code}
                title={t("Awentures")}
                logo={<AsyncImg className={styles.view} src="/images/awentures-logo.png" />}
                publicUrl={Routes.AWENTURES_LANDING}
                price={
                  offer &&
                  userPhone && (
                    <Price
                      price={offer.fullPrice}
                      discountPrice={offer.discountedPrice}
                      currency={offer.currency.code}
                      isDigitalProduct
                      digitalProductPurchaseType={offer.period.code}
                    />
                  )
                }
                priceNext={
                  offer && isFirstPeriodDiscounted(offer)
                    ? t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                        price: offer.fullPriceNext,
                        currencySign: offer.currency.sign,
                        period: getPeriodName(offer.period.code, "SHORT"),
                      })
                    : undefined
                }
                producerName="awentures.com"
                exclusiveOffer={undefined}
                onExclusiveOfferClick={() => null}
                isLight={true}
                detail={
                  hasAwenturesAccess && userPhone
                    ? getDetail((offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY")
                    : "Phone number is required to access Awentures"
                }
              >
                {hasAwenturesAccess && userPhone && (
                  <Button
                    className={styles["access-product-link"]}
                    borderRadius="SMALL"
                    color={Color.DARK_BLUE}
                    fontSize={18}
                    onClick={() => handleGetAwenturesUrl()}
                  >
                    <span className={styles["link-label"]}>{t("Access Awentures")}</span>
                  </Button>
                )}
                {hasAwenturesAccess && !userPhone && (
                  <Link
                    to="/account/profile"
                    shape="RECT"
                    height="MEDIUM"
                    weight="MEDIUM"
                    color="DARK_BLUE"
                    borderRadius="SMALL"
                    fontSize={18}
                  >
                    <span className={styles["link-label"]}>{t("Go to Profile")}</span>
                  </Link>
                )}
                {!hasAwenturesAccess && (
                  <Link
                    to="/awentures"
                    shape="RECT"
                    height="MEDIUM"
                    weight="MEDIUM"
                    color="DARK_BLUE"
                    borderRadius="SMALL"
                    fontSize={18}
                  >
                    <span className={styles["link-label"]}>{t("Purchase")}</span>
                  </Link>
                )}
              </DigitalProductBase>
            );
          }

          if (service.code === "BANCUS") {
            return (
              <DigitalProductBase
                key={service.code}
                title={t("Bancus card")}
                logo={<AsyncImg className={styles.view} src="/images/bancus-small-logo.png" />}
                publicUrl={Routes.BANCUS_LANDING}
                price={
                  offer && (
                    <Price
                      price={parseFloat(offer.fullPrice) - 20}
                      discountPrice={offer.discountedPrice}
                      currency={offer.currency.code}
                      isDigitalProduct
                      digitalProductPurchaseType={offer.period.code}
                    />
                  )
                }
                priceNext={
                  offer && isFirstPeriodDiscounted(offer)
                    ? t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                        price: offer.fullPriceNext,
                        currencySign: offer.currency.sign,
                        period: getPeriodName(offer.period.code, "SHORT"),
                      })
                    : undefined
                }
                exclusiveOffer={undefined}
                onExclusiveOfferClick={() => null}
                isLight={true}
                detail={getProductDetail(
                  (offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY",
                )}
                producerName="bancus.io"
              >
                {hasAwentureAccess(
                  (offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY",
                ) && (
                  <Button
                    disabled
                    className={styles["access-product-link"]}
                    borderRadius="SMALL"
                    color={Color.DARK_BLUE}
                    fontSize={18}
                  >
                    <span className={styles["link-label"]}>{t("Purchased")}</span>
                  </Button>
                )}
                {!hasAwentureAccess(
                  (offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY",
                ) && (
                  <Link
                    to="/bancus"
                    shape="RECT"
                    height="MEDIUM"
                    weight="MEDIUM"
                    color="DARK_BLUE"
                    borderRadius="SMALL"
                    fontSize={18}
                  >
                    <span className={styles["link-label"]}>{t("Purchase")}</span>
                  </Link>
                )}
              </DigitalProductBase>
            );
          }
          let onModalOpen = undefined;

          if (service.code === "SF_MARKETING_FEE") {
            onModalOpen = (t: boolean) => {
              setIsMarketingFeeModalOpen(t);
            };
          }

          if (service.code === "PLATFORM_FEE") {
            onModalOpen = (t: boolean) => {
              setIsPlatformFeeModalOpen(t);
            };
          }

          return (
            <DigitalProduct
              key={service.code}
              logo={
                <ServiceIcon
                  productCode={service.code}
                  forexLogoClassName={styles.forex}
                  businessFeeLogoClassName={styles["business-fee"]}
                  bankLogoClassName={styles.bank}
                  width={80}
                />
              }
              title={service.name}
              detail={getDetail((offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY")}
              price={
                offer && (
                  <Price
                    price={offer.fullPrice}
                    discountPrice={offer.discountedPrice}
                    currency={offer.currency.code}
                    isDigitalProduct
                    digitalProductPurchaseType={offer.period.code}
                  />
                )
              }
              priceNext={
                undefined
                /*
                offer && isFirstPeriodDiscounted(offer)
                  ? t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                      price: offer.fullPriceNext,
                      currencySign: offer.currency.sign,
                      period: getPeriodName(offer.period.code, "SHORT"),
                    })
                  : undefined
				  */
              }
              productCode={service.code}
              periodCode={offer?.period.code || activeSubscription?.periodCode}
              availability={getOfferAvailability(offer, !!activeSubscription)}
              activeSubscriptionId={offer?.upgradableSubscriptions[0]?.id ?? activeSubscription?.id}
              producerName={renderProducerName(service.name)}
              externalUrl={getAccessUrl(service.code)}
              publicUrl={undefined}
              exclusiveOfferInfo={
                offerCampaign &&
                exclusiveOffer && {
                  dateStart: new Date(offerCampaign.dateStart),
                  dateEnd: new Date(offerCampaign.dateEnd),
                  expiresInMS: offerCampaign.expiresInSeconds * 1000,
                  dagsBalance,
                  id: exclusiveOffer.id,
                  periodCode: exclusiveOffer.period.code,
                  priceInDag: exclusiveOffer.fullPrice,
                  priceInEur: exclusiveOffer.fullPriceEur,
                  availablePaymentMethods,
                  onSubmit: handleSpecialOfferSubmit,
                }
              }
              gracePeriod={
                activeSubscription &&
                [ServiceSubscriptionStatus.PAYMENT_FAILED, ServiceSubscriptionStatus.PAYMENT_PENDING].includes(
                  activeSubscription.status,
                )
                  ? {
                      dateEnd: new Date(activeSubscription.gracePeriodDateEnd),
                      expiresInMS: parseFloat(activeSubscription.gracePeriodExpiresInSeconds) * 1000,
                      subscription: {
                        ...activeSubscription,
                        billingCurrency: query.data?.me.currency ?? activeSubscription.billingCurrency,
                      },
                    }
                  : undefined
              }
              onGracePeriodRenewalSuccess={() => {
                query.refetch();
              }}
              isLoading={query.loading}
              onPaymentMethodChange={(paymentMethod) => query.refetch({ paymentMethod })}
              serviceCode={service.code}
              comingSoon={service.code === ServiceProductCode.MEMBERSHIP_PRO}
              isPaymentInfoVisible={service.code !== ServiceProductCode.MEMBERSHIP_PRO}
              onModalOpen={onModalOpen}
            />
          );
        })}

        <DigitalProductBase
          key="deals_discounts"
          title={t("Deals & Discounts")}
          logo={<AsyncImg className={styles.view} src="/images/discount-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            disabled={!hasPlatformFee}
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsDealsModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isDealsModalOpen}
            close={() => setIsDealsModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/airdrop.png",
              width: PanelWidth.SMALL,
              title: t("Airdrops"),
              caption: t("Airdrops details"),
              label: t("Airdrops"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("Deals & Discounts")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <div className={styles["responsive-container"]}>
                <AsyncImg className={styles.logo} src="/images/fuel-logo.png" alt="" />
                <div className={styles.text}>
                  Fuel your growth with FUEL—an innovative micro-learning platform to boost skills, happiness, and
                  productivity in life and work. Start thriving today! Use code S40 at checkout to enjoy our 40%
                  community discount!
                </div>
                <Button
                  className={styles.button}
                  borderRadius="SMALL"
                  color={Color.DARK_BLUE}
                  onClick={() => {
                    window.open("https://www.myfuel.io/?refer=sfuel", "_blank");
                  }}
                >
                  <span>{t("Get access")}</span>
                </Button>
              </div>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"airdrops"}
          title={t("Airdrops")}
          logo={<AsyncImg className={styles.view} src="/images/airdrop.png" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            disabled={!hasPlatformFee}
            onClick={() => setIsAirdropsModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isAirdropsModalOpen}
            close={() => setIsAirdropsModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/airdrop.png",
              width: PanelWidth.SMALL,
              title: t("Airdrops"),
              caption: t("Airdrops details"),
              label: t("Airdrops"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("Airdrops")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                A token airdrop is a strategy used by blockchains and web3 projects to distribute free tokens to users’
                wallets in order to promote their project, reward loyal users or incentivize engagement with the
                platform. Airdrops can be a great way for our users to discover new projects in the market and earn free
                tokens. Looking at the size of our community we can be interesting for many projects. We will continue
                to look around to bring more and more projects that our community can benefit from.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                In order to benefit from free token airdrops, insert your wallet addresses where these airdrops can be
                sent to.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Make sure you submit an address that is not from an exchange platform, as the tokens not listed on that
                particular exchange will not reach your account. Submit the address from your wallet.
              </p>

              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                <strong>If same wallet type address is added more than once, only the last one will be saved</strong>
              </p>
              <form onSubmit={handleWebWalletSubmit}>
                <Section className={styles.form} gutter={20} withSpace centerSelf>
                  <Field
                    type="select"
                    options={[
                      { label: "BEP20", value: "BEP20" },
                      { label: "TRC20", value: "TRC20" },
                      { label: "ERC20", value: "ERC20" },
                      { label: "SOL", value: "SOL" },
                    ]}
                    className={styles.field}
                    label={t("Wallet type")}
                    name="walletType"
                    gutter={20}
                    defaultValue={""}
                    error={walletInputErrors.walletType}
                    internalRef={addWallet}
                  />
                  <Field
                    className={styles.field}
                    label={t("address")}
                    name="address"
                    gutter={20}
                    defaultValue={""}
                    error={walletInputErrors.address}
                    internalRef={addWallet}
                  />
                  <Button
                    className={styles["access-product-link"]}
                    borderRadius="SMALL"
                    color={Color.DARK_BLUE}
                    fontSize={18}
                    type="submit"
                  >
                    {t("Add Wallet")}
                  </Button>
                </Section>
                {me?.me?.webwalletAddresses && me?.me?.webwalletAddresses?.length > 0 && (
                  <>
                    <h3 className={styles["wallet-label"]}>{t("Added wallet addresses")}</h3>
                    <Section className={styles.form} gutter={20} withSpace centerSelf>
                      {me.me.webwalletAddresses.map((wallet, index) => (
                        <div key={index} className={styles["wallet-item"]}>
                          <span className={styles["wallet-address"]}>
                            <strong>Address:</strong> {wallet.address}
                          </span>
                          <span className={styles["wallet-type"]}>
                            <strong>Type:</strong> {wallet.walletType}
                          </span>
                        </div>
                      ))}
                    </Section>
                  </>
                )}
              </form>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"webinars"}
          title={t("Webinars")}
          logo={<AsyncImg className={styles.view} src="/images/video-conference-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
        >
          <Button
            disabled={!hasPlatformFee}
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsWebinarsModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Read more")}</span>
          </Button>
          <Modal
            isOpen={isWebinarsModalOpen}
            close={() => setIsWebinarsModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/video-conference-icon.svg",
              width: PanelWidth.SMALL,
              title: t("Webinars"),
              caption: t("Webinars details"),
              label: t("Webinars"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("Webinars")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Join us for a series of webinars designed to educate and empower you with essential knowledge on
                technology and finance. Learn how to navigate the digital world, make informed financial decisions, and
                stay ahead in today’s fast-evolving landscape. Whether you’re a beginner or looking to deepen your
                understanding, these sessions will provide practical insights and tools to help you thrive.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                You need to register for each of the webinars separately. The link will be sent via email couple of
                hours before the start.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px", fontWeight: "bold" }}>
                TBD, 8PM CET time: Crypto for Beginners
              </p>
              <form onSubmit={handleRegisterWebinar}>
                <Section className={styles.form} gutter={20} withSpace centerSelf>
                  <Field
                    className={styles.field}
                    label={t("Email")}
                    name="email"
                    gutter={20}
                    defaultValue={""}
                    error={webinarInputErrors.email}
                    internalRef={webinarRegistration}
                  />
                  <Button
                    className={styles["access-product-link"]}
                    borderRadius="SMALL"
                    color={Color.DARK_BLUE}
                    fontSize={18}
                    type="submit"
                  >
                    {t("Register")}
                  </Button>
                </Section>
              </form>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"web3-social-media"}
          title={t("WEB3 Social Media")}
          logo={<AsyncImg className={styles.view} src="/images/web-3-black-icon.webp" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Coming in Q1 2025"
        >
          <Button
            disabled={!hasTheViewOffersOrAccess && !hasPlatformFee}
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsWeb3ModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Show Details")}</span>
          </Button>
          <Modal
            isOpen={isWeb3ModalOpen}
            close={() => setIsWeb3ModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/web-3-black-icon.webp",
              width: PanelWidth.SMALL,
              title: t("WEB3 Social Media"),
              caption: t("WEB3 Social Media details"),
              label: t("WEB3 Social Media"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("WEB3 Social Media")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                This is a Web3 social media platform that puts users in control of their accounts and data. Let's name
                it WSM for now, as the branding team is working on the best name and the brand.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Harnessing the power of blockchain technology, this platform offers a decentralized, transparent, and
                secure environment where users can connect, create, and share content with full ownership and control
                over their digital assets. Whether you're a content creator, influencer, or everyday social media user,
                this new platform supports you in how you engage online.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                As traditional social media platforms face growing concerns over privacy, censorship, and centralized
                control, the demand for a new era of social networking has emerged—one that empowers users rather than
                exploiting them. This is where Web3 comes in by being built on decentralized networks that provide true
                ownership of data and content. Users no longer need to rely on large corporations to manage their
                personal information or dictate the rules of engagement. Instead, they become active participants in an
                ecosystem where transparency, security, and autonomy reign.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                On <strong>WSM</strong>, content creators have full control over their intellectual property where they
                can earn directly from their content through crypto payments. Whether you're creating original videos,
                writing blog posts, sharing photography, or hosting live streams, you decide who gets to see your
                content and if they need to pay for it or not.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                One of the standout features of <strong>WSM</strong> is that users, not corporations, benefit directly
                from viewing advertisements. In contrast to traditional social media platforms where ad revenue is
                funneled to the company that owns the platform, <strong>WSM</strong> shifts the dynamic by rewarding
                users for their time and attention. Through blockchain-powered smart contracts, users earn tokens for
                interacting with ads, putting real value back into their hands. This system ensures that instead of
                large corporations profiting from your data and engagement, you are compensated for participating in the
                ad ecosystem. It's a fairer, more transparent way of advertising that puts users first, creating a
                win-win situation for both viewers and advertisers.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Now, imagine inviting someone to the platform, and as they start earning income, you receive a recurring
                referral bonus based on their earnings—every single month. With this ongoing reward, you'd be eager to
                bring all your contacts and followers onboard. And just like you, everyone else would want to do the
                same!
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                <strong>WSM</strong> will also offer integration with popular Web3 wallets, allowing users to manage
                their tokens, NFTs, and digital assets all in one place. With the added security of blockchain
                technology, your data and assets remain safe, private, and entirely under your control.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                In this new world of Web3 social media, content creation becomes more than just a way to share
                moments—it's an opportunity to build wealth and community. By embracing blockchain, <strong>WSM</strong>{" "}
                introduces a new social economy empowering users to benefit from their online activities and giving you
                ownership over your content, your data, and your digital life.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                <strong>WSM</strong> is targeting its soft launch already in Q1 of 2025, then creating to develop
                further based on the feedback from the community. Our community will AGAIN have the amazing benefit of
                receiving free tokens from this project. The tokens will start generating income from the revenue going
                to the platform. The main revenue to the platform is a small percentage of all earnings generated by
                creators and users. The bigger the community on the platform, the bigger the revenue will be.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key={"travelview"}
          title={t("TravelView")}
          logo={<AsyncImg className={styles.view} src="/images/travelview.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Coming in 2025"
        >
          <Button
            disabled={!hasTheViewOffersOrAccess && !hasPlatformFee}
            className={styles["access-product-link"]}
            borderRadius="SMALL"
            color={Color.DARK_BLUE}
            fontSize={18}
            onClick={() => setIsTravelViewModalOpen(true)}
          >
            <span className={styles["link-label"]}>{t("Show Details")}</span>
          </Button>
          <Modal
            isOpen={isTravelViewModalOpen}
            close={() => setIsTravelViewModalOpen(false)}
            kind={ModalKind.PRIMARY}
            panelProps={{
              imagePath: "/images/travelview.svg",
              width: PanelWidth.SMALL,
              title: t("TravelView"),
              caption: t("TravelView details"),
              label: t("TravelView"),
              titleClassName: styles["modal-title"],
              captionClassName: styles["modal-caption"],
            }}
          >
            <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
              <h2>{t("TravelView")}</h2>
            </div>
            <div
              style={{
                maxWidth: "700px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
                padding: "7px 15px",
                marginTop: "15px",
                lineHeight: "1.5em",
              }}
            >
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                TravelView is a virtual reality (VR) application designed to bring the world’s most captivating
                destinations directly to you through immersive 360-degree videos.. An app, that allows you to virtually
                travel to the most iconic cities, hidden gems, and breathtaking landscapes across the globe—all from the
                comfort of your home. Our goal is to make you feel as if you're truly standing at the heart of each
                destination.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Start your journey in our vibrant home lobby, where you can easily explore a map of the world and select
                any country or city you wish to visit. Whether you're craving the sights of bustling city streets,
                serene nature reserves, or historic landmarks, TravelView offers numerous possibilities for discovery.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                To ensure that every experience in TravelView is as authentic and engaging as possible, we’ll be
                partnering with a wide array of companies that have already created incredible 360-degree content.
                Additionally, we’ll be creating an opportunity for independent creators and videographers to upload and
                share their own 360-degree travel experiences. This creates a unique opportunity for content creators to
                showcase destinations they are passionate about while offering users a continually growing library of
                global adventures. As the community grows, so too will the diversity of experiences—from popular
                landmarks to hidden gems, allowing for both a curated and crowd-sourced travel journey.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                So whether you’re a curious traveler, a seasoned globetrotter, or simply someone who wants to experience
                the world, TravelView offers an ever-expanding world of possibility.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                As the VR and travel industries continue to grow, there is an increasing demand for accessible, virtual
                travel experiences. People are craving ways to explore the world, whether due to budget constraints,
                time limitations, or the desire to experience new places without the commitment of physical travel.
                Thanks to this shift the virtual tourism is becoming a growing trend. As consumers continue to embrace
                virtual experiences, the demand for apps like TravelView is poised to increase.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                This app is currently at its initial stages. The concept is in place, next step is designs and then to
                development. Simultaneously with development we can work on the collaborations for the video content.
                Part of this application will be owned by our existing community through tokenized shareholding - this
                is another added value for being part of the community, as the ownership is gifted to the community
                members. Some bigger content collaborators (companies) might also become shareholders, plus there will
                be some external investors who we are looking for right now to help to develop and launch this
                application. The plan is to launch this app during the year of 2025, most probably it will be somewhere
                in the end of the year.
              </p>
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Financially, a VR travel experience app presents a significant opportunity. With travel becoming more
                expensive and unpredictable, many people are seeking affordable ways to satisfy their wanderlust.
                Monetization of the app will be either a one time payment or monthly subscription through the app store.
                There might be additional bundles that the customers can purchase, but this will be more clear once in
                the conversations with collaborating companies and content creators. From the subscription fees and
                purchases, profits are then distributed via crypto payments as the easiest, fastest and most cost
                effective way.
              </p>
            </div>
          </Modal>
        </DigitalProductBase>
        <DigitalProductBase
          key="games"
          title={t("Games")}
          logo={<AsyncImg className={styles.view} src="/images/games-icon.svg" />}
          publicUrl={undefined}
          price={undefined}
          exclusiveOffer={undefined}
          onExclusiveOfferClick={() => null}
          bottomMessage="Both Fun and Play-To-Earn"
          isLight={true}
        >
          {hasPlatformFee && (
            <Link
              to="/account/games"
              shape="RECT"
              height="MEDIUM"
              weight="MEDIUM"
              color="DARK_BLUE"
              borderRadius="SMALL"
              fontSize={18}
            >
              <span className={styles["link-label"]}>{t("Read more")}</span>
            </Link>
          )}
        </DigitalProductBase>
        {me?.me?.xera && me?.me?.xera?.length > 0 && displayXPro && renderXPro(me?.me?.xera ?? [])}
        {hasTheViewOffersOrAccess && (
          <DigitalProductBase
            key="lh-metaverse"
            title={t("LHMetaverse")}
            logo={<AsyncImg className={styles.view} src="/images/metaverse-icon.svg" />}
            publicUrl={undefined}
            price={undefined}
            exclusiveOffer={undefined}
            onExclusiveOfferClick={() => null}
          >
            <Button
              className={styles["access-product-link"]}
              borderRadius="SMALL"
              color={Color.DARK_BLUE}
              fontSize={18}
              onClick={() => setIsLHMetaverseModalOpen(true)}
            >
              <span className={styles["link-label"]}>{t("Show Details")}</span>
            </Button>
            <Modal
              isOpen={isLHMetaverseModalOpen}
              close={() => setIsLHMetaverseModalOpen(false)}
              kind={ModalKind.PRIMARY}
              panelProps={{
                imagePath: "/images/metaverse-icon.svg",
                width: PanelWidth.SMALL,
                title: t("LHMetaverse"),
                caption: t("LHMetaverse details"),
                label: t("LHMetaverse"),
                titleClassName: styles["modal-title"],
                captionClassName: styles["modal-caption"],
              }}
            >
              <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
                <h2>{t("LHMetaverse")}</h2>
              </div>
              <div
                style={{
                  maxWidth: "700px",
                  border: "1px solid #ddd",
                  backgroundColor: "#fafafa",
                  padding: "7px 15px",
                  marginTop: "15px",
                  lineHeight: "1.5em",
                }}
              >
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Visit the LHMV website at{" "}
                  <a href="https://www.lhmv.io" target="_blank" rel="noopener noreferrer">
                    www.lhmv.io
                  </a>
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  More information can be always found at the continuously evolving
                  <a href="https://wiki.lhmv.io/" target="_blank" rel="noopener noreferrer">
                    LHMV wiki
                  </a>
                </p>

                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  When you have your LHO in the wallet, make sure to participate in the LHMV DAO for voting and
                  governance
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Metaverse, in short, is a virtual world. In most cases so far they have been with a cartoonish
                  graphics, not appealing to most of the people. There are some companies that have started to create
                  metaverses with realistic, even life-like graphics, giving people a more appealing and relatable
                  experience. This is also the route we are on.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  The way we have our social media accounts and our personal/business website in the web2, similarly we
                  have our avatar and location in metaverse. The location can be a land plot with a private residence
                  (home), an apartment, commercial building or office, an island - imagination and sometimes
                  boundaries/concepts of the metaverse are the only limits in the digital world. Our presence in the
                  metaverse is like our presence in the web2 space, but in an enhanced visual representation, more
                  immersive, more interactive.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Any account owned in web2 belongs to the company running the platform, for example social media.
                  Shifting to web3 each user becomes the owner of their account, owner of their products, owner of their
                  digital location. This principle is followed in the metaverse project as well.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  If you remember, then Kris has been talking about these principles in depth at several conferences,
                  always explaining the differences of web2 and web3, emphasizing the benefits and rights web3 can bring
                  us. These principles were supposed to be implemented also into The View, but as the View management
                  team cut both Kris and Nils out of the project and went their own way, the decision to put together a
                  separate metaverse project with ownership to the SF community was a simple one to make. The plans,
                  vision and knowhow from Kris and Nils are now flowing into this metaverse, mixed with the experience
                  of partners who have been working in this space for years already.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  The concept is simple, yet beautiful - an aesthetic virtual world where users can own and create a
                  virtual real estate to their liking, their dream house or a unique showcase for their business. The
                  project is built on blockchain where users are in control of their persona and their possessions.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Our community members have been receiving tokens via airdrop. These tokens represent ownership of the
                  project. Any profit generated by the project is distributed to the token holders. The basic info about
                  the ownership tokens (LHO) has been already previously shared (revisit the news section).
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Once the project launches the first revenue is from plot/land sale. Preparations are ongoing to set up
                  also the first business model that users can start utilizing in the metaverse. This business model
                  brings revenue share to the project - majority of business income to the user, and a part to the
                  project which is then also distributed to the token holders.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  The extraordinary growth in the AI sector will impact the metaverse worlds and concept in the future.
                  Right now it is difficult to predict how this space will evolve, but it is definitely an interesting
                  moment to be part of such an industry as it is in its baby shoes. It is fair to expect that AI will
                  bring a lot of opportunities for users to create in the metaverse.
                </p>
              </div>
            </Modal>
          </DigitalProductBase>
        )}
        {hasTheViewOffersOrAccess && renderTheView()}
        <Modal
          isOpen={isMarketingFeeModalOpen}
          close={() => setIsMarketingFeeModalOpen(false)}
          kind={ModalKind.PRIMARY}
          panelProps={{
            hideImage: true,
            imagePath: "/images/web-3-black-icon.webp",
            width: PanelWidth.SMALL,
            title: t("SF Marketing Fee"),
            caption: t("SF Marketing Fee details"),
            label: t("SF Marketing Fee"),
            titleClassName: styles["modal-title"],
            captionClassName: styles["modal-caption"],
          }}
        >
          <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
            <h2>{t("SF Marketing Fee")}</h2>
          </div>
          <div
            style={{
              maxWidth: "700px",
              border: "1px solid #ddd",
              backgroundColor: "#fafafa",
              padding: "7px 15px",
              marginTop: "15px",
              lineHeight: "1.5em",
            }}
          >
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              The Marketing Fee is an annual payment required for users who wish to become affiliates and earn direct
              sales bonuses (retail commissions) by referring products and services to customers. This fee, together
              with the affiliate agreement, provides authorization to promote products listed on the platform and
              generate income through commission-based sales. Affiliates earn a percentage of each successful sale made
              through their unique referral links or personalized promotions. By paying this fee, affiliates gain access
              to the following benefits:
            </p>
            <ul style={{ marginLeft: "40px", maxWidth: "600px" }}>
              <li>Permission to promote listed products and services under the platform's affiliate program</li>
              <li>Access to the Web Office - a dedicated affiliate area to track sales and monitor commissions</li>
              <li>Marketing tools and referral links to optimize sales efforts</li>
              <li>
                Early access to new products and special promotions that offer higher commission rates or limited-time
                bonuses
              </li>
              <li>Priority support for affiliate-related inquiries, commission payouts, and promotional strategies</li>
            </ul>
            <p>
              The Marketing Fee is an annual fee that allows affiliates to build an income stream through product
              referrals.
            </p>
          </div>
        </Modal>
        <Modal
          isOpen={isPlatformFeeModalOpen}
          close={() => setIsPlatformFeeModalOpen(false)}
          kind={ModalKind.PRIMARY}
          panelProps={{
            hideImage: true,
            imagePath: "/images/web-3-black-icon.webp",
            width: PanelWidth.SMALL,
            title: t("Platform Fee"),
            caption: t("Platform Fee details"),
            label: t("Platform Fee"),
            titleClassName: styles["modal-title"],
            captionClassName: styles["modal-caption"],
          }}
        >
          <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
            <h2>{t("Platform Fee")}</h2>
          </div>
          <div
            style={{
              maxWidth: "700px",
              border: "1px solid #ddd",
              backgroundColor: "#fafafa",
              padding: "7px 15px",
              marginTop: "15px",
              lineHeight: "1.5em",
            }}
          >
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              The <strong>Platform Fee</strong> is an annual fee that provides users with access to the features,
              benefits and opportunities offered by the platform. Additionally, users get access to the marketplace
              where they can explore various product and service listings. This fee ensures that members benefit from a
              well-maintained platform with continuous improvements, platform updates and dedicated customer support.
              Upon payment, users can immediately start engaging with available products and services.
            </p>
          </div>
        </Modal>
        <Modal
          isOpen={isHunterSystemsModalOpen}
          close={() => setIsHunterSystemsModalOpen(false)}
          kind={ModalKind.PRIMARY}
          panelProps={{
            hideImage: true,
            imagePath: "/images/hunters-logo.png",
            width: PanelWidth.SMALL,
            title: t("HunterSystems"),
            caption: t("HunterSystems"),
            label: t("HunterSystems"),
            titleClassName: styles["modal-title"],
            captionClassName: styles["modal-caption"],
          }}
        >
          <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
            <h2>{t("HunterSystems")}</h2>
          </div>
          <div
            style={{
              maxWidth: "700px",
              border: "1px solid #ddd",
              backgroundColor: "#fafafa",
              padding: "7px 15px",
              marginTop: "15px",
              lineHeight: "1.5em",
            }}
          >
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              As a first step, open your account with the broker DooPrime
            </p>
            <Button
              className={styles["access-product-link"]}
              borderRadius="SMALL"
              color={Color.DARK_BLUE}
              fontSize={18}
              onClick={() => handleOpenExternalUrl(query?.data?.me.hunterSystemsAccess?.accessUrl ?? "")}
            >
              <span className={styles["link-label"]}>{t("Open account")}</span>
            </Button>
            <p
              style={{ marginTop: "10px", marginBottom: "10px" }}
              dangerouslySetInnerHTML={{ __html: query?.data?.me.hunterSystemsAccess?.description ?? "" }}
            />

            <p style={{ marginTop: "10px", marginBottom: "10px" }}>Subscribe your trading account to this strategy</p>
            <Button
              className={styles["access-product-link"]}
              borderRadius="SMALL"
              color={Color.DARK_BLUE}
              fontSize={18}
              onClick={() => handleOpenExternalUrl(query?.data?.me.hunterSystemsAccess?.url ?? "")}
            >
              <span className={styles["link-label"]}>{t("Subscribe")}</span>
            </Button>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              You can find the trading platform login and password in your email.
            </p>

            <p style={{ marginTop: "10px", marginBottom: "10px" }}>Activate the strategy and you are ready.</p>
          </div>
        </Modal>
      </Section>
    </>
  );

  // me?.me?.hunterSystemsAccess?.description ??

  // get external bank product url
  function getAccessUrl(code: ServiceProductCode) {
    // bank connect url
    const bankFullUrl = query.data?.me.codebreakerAccess?.bankFullUrl;
    // bank beyond url
    const bankBasicUrl = query.data?.me.codebreakerAccess?.bankBasicUrl;

    // forex url
    if (code === ServiceProductCode.FOREX || code === ServiceProductCode.FOREX_PRO) {
      return query.data?.me.forexInsidersAccess ?? undefined;
    }

    // return bank connect url
    if (code === ServiceProductCode.VIEW_BASIC && bankBasicUrl && !bankFullUrl) {
      return bankBasicUrl;
    }

    // return bank beyond url
    if ((code === ServiceProductCode.BANK_BASIC || code === ServiceProductCode.BANK_FULL) && bankFullUrl) {
      return bankFullUrl;
    }

    // membership url
    if (code === ServiceProductCode.MEMBERSHIP_BASIC || code === ServiceProductCode.MEMBERSHIP_PRO) {
      return query.data?.me.membershipAccess ?? undefined;
    }

    // no access link for sf fee
    if (code === ServiceProductCode.BUSINESS_FEE_BASIC || code === ServiceProductCode.BUSINESS_FEE_PRO) {
      return false;
    }
    if (code === ServiceProductCode.SF_MARKETING_FEE || code === ServiceProductCode.PLATFORM_FEE) {
      return false;
    }

    return undefined;
  }
}

// render digital product producer name
function renderProducerName(producerName: string) {
  switch (producerName) {
    case "Forex Insiders":
    case "Forex Insiders Pro":
      return "Forex Insiders BV";
    case "B.A.N.K. Connect":
    case "B.A.N.K. Beyond":
      return " Codebreaker Technologies Inc.";
  }
}

function getExclusiveOfferCampaignInfo<T extends { product: { code: ServiceProductCode } }>(
  code: ServiceProductCode,
  productsForDags: T[],
) {
  return productsForDags.find((p) => p.product.code === code);
}

function getOfferAvailability(
  offer?: { availability: ServiceOfferAvailability },
  hasActiveSubscription?: boolean,
): ServiceOfferAvailability | undefined {
  if (offer?.availability) {
    return offer.availability;
  }

  if (hasActiveSubscription) {
    return ServiceOfferAvailability.UNAVAILABLE_ALREADY_SUBSCRIBED;
  }

  return undefined;
}

function daysToMilliseconds(days: number) {
  return days * 24 * 60 * 60 * 1000;
}
