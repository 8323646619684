import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Link } from "../link/Link";
import { Layout } from "../layout/Layout";
import { formatLocaleDate, timeLeft } from "../../services/formatDate";
import { Button } from "../button/Button";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { Modal, ModalKind } from "../modal/Modal";
import { CurrencyCode } from "../price/Price";
import { PanelWidth } from "../panel/Panel";
import { PaymentEntityType, PaymentMethod, ServicePeriodCode, ServiceProductCode } from "../../graphql/schema";
import { Upgrade } from "../../views/account-view/subscriptions-view/edit-subscription/upgrade/Upgrade";

import styles from "./digital-product-base.module.scss";

export interface DigitalProductBaseProps {
  children?: ReactNode;
  isPaymentInfoVisible?: boolean;
  isProductActive?: boolean;
  title: string;
  detail?: string;
  publicUrl?: string;
  logo?: JSX.Element | null;
  subscribers?: JSX.Element;
  price?: string | JSX.Element;
  priceNext?: string | JSX.Element;
  exclusiveOffer?: JSX.Element | false;
  isLoading?: boolean;
  gracePeriod?: {
    dateEnd?: Date;
    expiresInMS?: number;
    subscription: {
      id: string;
      products: { code: ServiceProductCode }[];
      billingAmount?: string | null;
      billingCurrency: {
        code: CurrencyCode;
      };
      periodCode: ServicePeriodCode;
    };
  };
  producerName?: string;
  bottomMessage?: string;
  comingSoon?: boolean;
  isLight?: boolean;
  exclusiveOfferExpiryDate?: Date;
  exclusiveOfferTimeLeftMS?: number;
  onPaymentMethodChange?(paymentMethod: PaymentMethod): void;
  onExclusiveOfferClick?(): void;
  onGracePeriodRenewalSuccess?(): void;
  attention?: boolean;
}

export const DigitalProductBase: React.FC<DigitalProductBaseProps> = ({
  children,
  isPaymentInfoVisible = true,
  isProductActive,
  title,
  detail,
  publicUrl,
  logo,
  subscribers,
  price,
  priceNext,
  exclusiveOffer,
  gracePeriod,
  producerName,
  bottomMessage,
  isLoading,
  exclusiveOfferExpiryDate,
  exclusiveOfferTimeLeftMS,
  onPaymentMethodChange,
  onExclusiveOfferClick,
  onGracePeriodRenewalSuccess,
  comingSoon,
  isLight = false,
  attention = false,
}) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useWindowDimensionsContext();
  const [isEditSubscriptionOpen, setIsEditSubscriptionOpen] = useState(false);
  const [isRenewingSubscription, setIsRenewingSubscription] = useState(false);

  const offerTimeLeft = timeLeft(exclusiveOfferTimeLeftMS ?? 0, t);
  const offerExpiryDate = exclusiveOfferExpiryDate
    ? formatLocaleDate(exclusiveOfferExpiryDate, i18n.language, { month: "long" })
    : "";

  const gracePeriodTimeLeft = timeLeft(gracePeriod?.expiresInMS ?? 0, t);
  const gracePeriodExpiryDate = gracePeriod?.dateEnd
    ? formatLocaleDate(gracePeriod.dateEnd, i18n.language, { month: "long" })
    : "";

  return (
    <div
      className={classNames(styles.wrap, {
        [styles["wrap--attention"]]: attention,
      })}
    >
      <Layout className={styles.main} wrap="TABLET_PORTRAIT_MAX">
        <Layout className={styles["title-wrap"]} wrap="MOBILE">
          {/* logo */}
          <div className={styles.logo}>{logo}</div>

          <div className={styles["title-content"]}>
            {/* title */}
            <div className={styles.header}>{title}</div>

            {/* details */}
            {isPaymentInfoVisible && detail && (
              <div
                className={classNames(styles.status, {
                  [styles["status--active"]]: isProductActive,
                  [styles["status--inactive"]]: !isProductActive,
                })}
              >
                <div
                  className={classNames(styles["status-title"], {
                    [styles["status-title--attention"]]: attention,
                  })}
                >
                  {detail}
                </div>

                {/* TODO: condition missing when to show "Free access" attention */}
                {/* <div className={styles["status--free"]}>{t("Free access")}</div> */}
                {price && <div className={styles.price}>{price}</div>}
              </div>
            )}
            {priceNext && <div className={styles["price-disclaimer"]}>{priceNext}</div>}
          </div>
        </Layout>
        <Layout className={styles["button-wrap"]} wrap="MOBILE" stretch="MOBILE">
          {subscribers && <div className={styles["subscribers-wrap"]}>{subscribers}</div>}
          {/* TODO: comment in after logic is implemented */}
          {/* <Customers className={styles["customers-link"]} to="#" activeCustomers={4} /> */}
          {comingSoon && (
            <Link className={styles["landing-page-link"]} to={"#"} borderRadius="SMALL" weight="MEDIUM" fontSize={14}>
              {t("Coming soon")}
            </Link>
          )}
          {children}
        </Layout>

        {producerName && (
          <p className={classNames(styles.producer)}>
            {t("Powered by")} {producerName}
          </p>
        )}
        {bottomMessage && (
          <p className={classNames(styles["bottom-message"], { [styles.light]: isLight })}>{bottomMessage}</p>
        )}
      </Layout>

      {exclusiveOffer && (
        <Layout className={styles.section} wrap="TABLET_PORTRAIT_MAX">
          <div className={styles["section-content"]}>
            <div className={styles["exclusive-offer"]}>{exclusiveOffer}</div>
            {exclusiveOfferExpiryDate && (
              <p className={styles["expiration-date"]}>
                <strong>
                  {t("This offer expires in {{timeLeft}} - in {{expiryDate}}", {
                    timeLeft: offerTimeLeft,
                    expiryDate: offerExpiryDate,
                  })}
                </strong>
              </p>
            )}
          </div>
          <Button
            className={styles["exclusive-offer-button"]}
            color="DARK_BLUE"
            borderRadius={isMobile ? undefined : "SMALL"}
            weight="BOLD"
            fontSize={isMobile ? 12 : 14}
            width={160}
            height={isMobile ? "SMALL" : "MEDIUM"}
            onClick={onExclusiveOfferClick}
          >
            {t("Grab the offer")}
          </Button>
        </Layout>
      )}

      {gracePeriod && (
        <Layout className={styles.section} wrap="TABLET_PORTRAIT_MAX">
          <div className={styles["section-content"]}>
            <div className={styles["exclusive-offer"]}>
              <strong>
                <i>{t("Membership is expired")}</i>
              </strong>
              {" | "}
              <span>
                {t("Your {{productName}} membership is expired! Please renew your membership", {
                  productName: title,
                })}
              </span>
            </div>

            <p className={styles["expiration-date"]}>
              <strong>
                {t("Membership access will be revoked in {{timeLeft}} - in {{expiryDate}}", {
                  timeLeft: gracePeriodTimeLeft,
                  expiryDate: gracePeriodExpiryDate,
                })}
              </strong>
            </p>
          </div>
          <Button
            className={styles["exclusive-offer-button"]}
            color="ORANGE"
            borderRadius={isMobile ? undefined : "SMALL"}
            weight="BOLD"
            fontSize={isMobile ? 12 : 14}
            width={160}
            height={isMobile ? "SMALL" : "MEDIUM"}
            onClick={() => setIsEditSubscriptionOpen(true)}
          >
            {t("Renew")}
          </Button>
        </Layout>
      )}

      {gracePeriod && isEditSubscriptionOpen && (
        <Modal
          kind={ModalKind.SECONDARY}
          panelProps={{
            imagePath: "../../../images/illustrationSwitchPlan.png",
            width: PanelWidth.SMALL,
          }}
          isOpen
          close={() => setIsEditSubscriptionOpen(false)}
        >
          <Upgrade
            upgradeType={PaymentEntityType.PURCHASE_OF_SERVICE_SUBSCRIPTION_RENEWAL}
            onPaymentMethodChange={onPaymentMethodChange}
            isLoading={isLoading}
            upgradeOffer={{
              id: gracePeriod.subscription.id,
              currency: { code: gracePeriod.subscription.billingCurrency.code },
              differenceTotal: gracePeriod.subscription.billingAmount || "0",
              offer: {
                products: gracePeriod.subscription.products,
                period: { code: gracePeriod.subscription.periodCode },
                fullPrice: gracePeriod.subscription.billingAmount || "0",
              },
            }}
            onClose={() => !isRenewingSubscription && setIsEditSubscriptionOpen(false)}
            handleDisableModalClose={setIsRenewingSubscription}
            onUpgradeSuccess={() => {
              setIsEditSubscriptionOpen(false);
              if (onGracePeriodRenewalSuccess) {
                onGracePeriodRenewalSuccess();
                toast.success(t("Your membership was renewed successfully!"), { autoClose: 4000 });
              }
            }}
          />
        </Modal>
      )}
    </div>
  );
};
