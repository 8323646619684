import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";

import { useLoginViewLoginMutationMutation, useLoginViewQuery } from "../../graphql/schema";
import { handleFormErrors } from "../../services/handleFormErrors";
import { Button } from "../button/Button";
import Field, { FieldGutter } from "../field/Field";
import { Form } from "../form/Form";
import { Loader, LoaderSize } from "../loader/Loader";

import styles from "./login-form.module.scss";

interface Captcha {
  hasCaptcha: boolean;
  timestamp: number;
}

export enum LoginFormStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface LoginFormProps {
  kind?: keyof typeof LoginFormStyle;
  className?: string;
}

type LoginFormValues = {
  username: string;
  password: string;
  recaptcha?: string;
};

export const LoginForm: React.FC<LoginFormProps> = ({ kind = "PRIMARY", className }) => {
  const [t] = useTranslation();

  const loginSchema = yup.object().shape({
    username: yup.string().required(t("Username is missing")),
    password: yup.string().required(t("Password is missing")),
  });

  const { data } = useLoginViewQuery();

  // check if captcha should be shown
  const askReCaptcha = data?.me.askReCaptcha;

  const { register, handleSubmit, errors, setError, reset } = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
  });

  const [loginMutation, { loading: loginLoading }] = useLoginViewLoginMutationMutation();

  const [captcha, setCaptcha] = useState<Captcha>({ hasCaptcha: false, timestamp: new Date().getTime() });

  const handleLogin = handleSubmit(async ({ username, password, recaptcha }) => {
    try {
      await loginMutation({
        variables: { credentials: { password, username, recaptcha } },
        refetchQueries: "active",
        awaitRefetchQueries: true,
      });
      reset({ recaptcha: "", username, password });
      (window as any).fbq("trackCustom", t("Shop Login"));

      // // need to reload page if successful login to ensure internal google tag manager is initialized
      // window.location.reload();
    } catch (error) {
      if (error instanceof ApolloError) {
        handleFormErrors({ error, t, setError });
      }

      setCaptcha({ hasCaptcha: true, timestamp: new Date().getTime() });

      return;
    }
  });

  return (
    <Form className={className} onSubmit={handleLogin} stretch>
      <Field
        type="text"
        label={t("Username")}
        name="username"
        internalRef={register}
        isRequired
        error={errors.username}
        gutter={FieldGutter.MEDIUM}
      />
      <Field
        type="password"
        label={t("Password")}
        name="password"
        internalRef={register}
        isRequired
        error={errors.password}
        gutter={FieldGutter.MEDIUM}
      />

      {(captcha.hasCaptcha || askReCaptcha) && (
        <div>
          <img
            src={`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/captcha?${captcha.timestamp}`}
            alt="captcha"
          />
          <Field
            type="text"
            label={t("Recaptcha")}
            error={errors.recaptcha}
            name="recaptcha"
            internalRef={register}
            gutter={FieldGutter.MEDIUM}
          />
        </div>
      )}
      <Button
        type="submit"
        className={(styles as any)[`button--${kind}`]}
        shape={kind === "PRIMARY" ? "RECT" : "ROUND"}
        color={kind === "PRIMARY" ? "YELLOW" : "BLUE"}
        borderRadius={kind === "PRIMARY" ? "SMALL" : undefined}
        gutter="LARGE"
        stretch="MOBILE"
        center={kind === "PRIMARY" ? undefined : "BLOCK_AND_MARGIN"}
        weight={kind === "PRIMARY" ? "MEDIUM" : "BOLD"}
        fontSize="inherit"
        disabled={loginLoading}
        isLoading={loginLoading}
        width={230}
      >
        {t("Login")}
        {loginLoading && <Loader size={LoaderSize.SMALL} />}
      </Button>
    </Form>
  );
};
