import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { FieldError } from "react-hook-form";

import IconEye from "../icon/IconEye";
import IconEyeOff from "../icon/IconEyeOff";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./field.module.scss";

export interface Option {
  label: string | number;
  value: string | number;
}

export interface DatepickerProps extends Omit<ReactDatePickerProps, "onChange"> {
  selected: Date | null | undefined;
  maxDate?: Date | null | undefined;
  onChange?(date: Date | null, event: React.SyntheticEvent<unknown> | undefined): void;
  onClear?(): void;
}

export enum CheckboxStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  QUATERNARY = "QUATERNARY",
}

export enum FieldGutter {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  SEMI_LARGE = "SEMI_LARGE",
  LARGE = "LARGE",
}

export type InternalRefType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null;

export type FieldCustomInput = (props: {
  onFocus: () => void;
  onBlur: () => void;
  onChange: (value: any) => void;
}) => React.ReactNode;

export interface FieldProps extends Pick<React.InputHTMLAttributes<HTMLInputElement>, "name"> {
  baseInputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  datePickerProps?: DatepickerProps;
  gutter?: FieldGutter | number;
  checkboxStyle?: keyof typeof CheckboxStyle;
  alignRight?: boolean;
  label?: string | JSX.Element;
  selectFieldDirection?: "rtl" | "ltr";
  type?: "text" | "password" | "number" | "select" | "hidden" | "checkbox" | "textarea" | "datepicker" | "custom";
  options?: Option[];
  submitButton?: React.ReactNode;
  className?: string;
  inputWrapClassName?: string;
  disabled?: boolean;
  checked?: boolean;
  hideFocus?: boolean;
  uppercaseOptions?: boolean;
  onChange?: (value: string) => void;
  onClick?(): void;
  placeholder?: string;
  inputClassName?: string;
  labelClassName?: string;
  internalRef?: (ref: InternalRefType) => void;
  updateIsEmpty?: (isEmpty: boolean) => void;
  isRequired?: boolean;
  defaultValue?: string | number | null;
  value?: string | number | null;
  error?: FieldError;
  expand?: boolean;
  transparent?: boolean;
  children?: FieldCustomInput;
  labelHasHtml?: boolean;
}

type PasswordVisibility = "text" | "password";

export default function Field({
  children,
  baseInputProps,
  datePickerProps,
  gutter,
  checkboxStyle,
  internalRef,
  updateIsEmpty,
  label,
  alignRight,
  className,
  inputWrapClassName,
  isRequired,
  onChange,
  onClick,
  defaultValue,
  options,
  submitButton,
  selectFieldDirection = "ltr",
  type,
  checked,
  hideFocus,
  uppercaseOptions,
  disabled,
  error,
  placeholder,
  inputClassName,
  labelClassName,
  expand,
  transparent,
  labelHasHtml,
  value,
  ...rest
}: FieldProps) {
  const [isFocused, setFocused] = useState(false);
  const [isEmpty, setIsEmpty] = useState(!defaultValue);
  const [isPasswordVisible, setPasswordVisible] = useState<PasswordVisibility>("password");

  const customGutter = typeof gutter === "number" ? gutter : undefined;

  // handle sending out data if field is empty
  useEffect(() => {
    if (updateIsEmpty) {
      updateIsEmpty(isEmpty);
    }
  }, [isEmpty, updateIsEmpty]);

  return (
    <div
      style={{ marginBottom: customGutter }}
      className={classNames(
        {
          [styles["field-wrap"]]: type !== "checkbox",
          [styles["field-wrap--datepicker"]]: type === "datepicker",
          [styles.expand]: expand,
          [styles["field-wrap--gutter-small"]]: gutter === FieldGutter.SMALL,
          [styles["field-wrap--gutter-medium"]]: gutter === FieldGutter.MEDIUM,
          [styles["field-wrap--gutter-semi-large"]]: gutter === FieldGutter.SEMI_LARGE,
          [styles["field-wrap--gutter-large"]]: gutter === FieldGutter.LARGE,
        },
        className,
      )}
    >
      <div
        className={classNames(
          {
            [styles["input-wrap"]]: type !== "checkbox",
            [styles["input-wrap--textarea"]]: type === "textarea",
            [styles["input-wrap--has-focus"]]: (!isEmpty && !disabled) || isFocused,
            [styles.error]: error && !isFocused,
          },
          inputWrapClassName,
        )}
      >
        {getField()}
        {submitButton && submitButton}
        <label
          className={classNames(styles.label, {
            [styles.focus]: isFocused,
            [styles.raisedLabel]: !isEmpty || isFocused,
            [styles.error]: error && !isFocused,
            [styles["label--hidden"]]: type === "checkbox",
            [styles["label--textarea"]]: type === "textarea",
          })}
        >
          {isRequired && !isFocused && isEmpty ? `${label}*` : label}
        </label>
      </div>

      {error && <div className={styles["error-message"]}>{error.message}</div>}
    </div>
  );

  function getField() {
    switch (type) {
      case "select":
        return renderSelectField();
      case "checkbox":
        if (checkboxStyle === "QUATERNARY") {
          return renderMultiCheckboxField();
        }
        return renderCheckboxField();
      case "textarea":
        return renderTextareaField();
      case "custom":
        return renderCustomField(children);
      case "datepicker":
        return renderDatePicker();
      default:
        return renderBasicInputField();
    }
  }

  function renderDatePicker() {
    const handleMissingValue = () => {
      if (datePickerProps?.selected === null || datePickerProps?.selected === undefined) {
        setIsEmpty(false);
        setFocused(false);
      }
    };

    const handleChangingDateValue = (date: Date | null) => {
      if (onChange) {
        // if new date is empty, do not update date value as the true value remains the same as last value
        if (!date) {
          return;
        }
        onChange(date ? new Date(date).toString() : "");
      }

      handleMissingValue();
    };

    return (
      <ReactDatePicker
        {...datePickerProps}
        className={classNames(styles.datepicker, {
          [styles.error]: error && !isFocused,
          [styles["field--has-label"]]: label !== undefined,
        })}
        wrapperClassName={classNames(styles["datepicker-wrapper"], {
          [styles["datepicker-wrapper--has-error"]]: error,
        })}
        popperClassName={styles["datepicker-popper"]}
        dateFormat="dd/MM/yyyy"
        maxDate={datePickerProps?.maxDate}
        selected={datePickerProps?.selected}
        onFocus={() => setFocused(true)}
        onSelect={handleMissingValue}
        onClickOutside={() => setFocused(false)}
        onChange={handleChangingDateValue}
      />
    );
  }

  function renderTextareaField() {
    return (
      <textarea
        {...rest}
        className={classNames(styles.textarea, {
          [styles.error]: error && !isFocused,
          [styles["field--has-label"]]: label !== undefined,
        })}
        ref={internalRef}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onChange={(e) => setIsEmpty(!e.target.value)}
        onBlur={() => setFocused(false)}
      />
    );
  }

  function renderCustomField(customInput?: FieldCustomInput) {
    return (
      <div
        className={classNames(styles.custom, {
          [styles.error]: error && !isFocused,
          [styles["field--has-label"]]: label !== undefined,
        })}
        placeholder={placeholder}
      >
        {customInput &&
          customInput({
            onFocus: () => setFocused(true),
            onChange: (value) => setIsEmpty(!value),
            onBlur: () => setFocused(false),
          })}
      </div>
    );
  }

  function renderCheckboxField() {
    return (
      <label
        className={classNames(styles.checkboxLabel, {
          [styles["checkboxLabel--tertiary"]]: checkboxStyle === "TERTIARY",
          [styles["checkboxLabel--tertiary-checked"]]: checkboxStyle === "TERTIARY" && checked,
        })}
      >
        <input
          {...rest}
          type="checkbox"
          ref={internalRef}
          disabled={disabled}
          className={classNames(styles.checkbox, {
            [styles["checkbox--secondary"]]: checkboxStyle === "SECONDARY",
            [styles["checkbox--tertiary"]]: checkboxStyle === "TERTIARY",
          })}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
        <span
          className={classNames(styles.checkmark, {
            [styles["checkmark--secondary"]]: checkboxStyle === "SECONDARY",
          })}
        />
        {labelHasHtml && label ? (
          <span
            className={classNames({ [styles["checkbox-label--disabled"]]: disabled }, labelClassName)}
            dangerouslySetInnerHTML={{ __html: `${label}` }}
          />
        ) : (
          <span className={classNames({ [styles["checkbox-label--disabled"]]: disabled }, labelClassName)}>
            {label}
          </span>
        )}
      </label>
    );
  }

  function renderMultiCheckboxField() {
    // Copy of checkbox but the state is handled in parent because we have multi select
    return (
      <label
        className={classNames(styles.checkboxLabel, {
          [styles["checkboxLabel--tertiary"]]: checkboxStyle === "QUATERNARY",
          [styles["checkboxLabel--tertiary-checked"]]: checkboxStyle === "QUATERNARY" && checked,
        })}
        onClick={(e) => {
          e.preventDefault(); // prevents checkbox from re rendering twice
          return onClick ? onClick() : null;
        }}
      >
        <input
          {...rest}
          type="checkbox"
          ref={internalRef}
          disabled={disabled}
          className={classNames(styles.checkbox, {
            [styles["checkbox--secondary"]]: checkboxStyle === "SECONDARY",
            [styles["checkbox--tertiary"]]: checkboxStyle === "QUATERNARY",
          })}
          checked={checked}
          onChange={(e) => {
            // checked value is handled in parent component
            e.preventDefault();
          }}
        />
        <span
          className={classNames(styles.checkmark, {
            [styles["checkmark--secondary"]]: checkboxStyle === "SECONDARY",
          })}
        />
        <span className={classNames({ [styles["checkbox-label--disabled"]]: disabled }, labelClassName)}>{label}</span>
      </label>
    );
  }

  function renderSelectField() {
    return (
      <select
        {...rest}
        className={classNames(styles.select, {
          [styles["select--transparent"]]: transparent,
          [styles["select--uppercase-options"]]: uppercaseOptions,
          [styles.error]: error && !isFocused,
          [styles["field--has-label"]]: label !== undefined,
        })}
        dir={selectFieldDirection}
        ref={internalRef}
        onFocus={() => setFocused(true)}
        onChange={(e) => {
          setIsEmpty(!e.target.value);

          if (onChange) {
            onChange(e.target.value);
          }
        }}
        defaultValue={defaultValue || undefined}
        onBlur={() => {
          setFocused(false);
        }}
      >
        <option style={{ display: "none" }} />
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    );
  }

  function renderBasicInputField() {
    if (value) {
      return (
        <>
          <input
            {...rest}
            className={classNames(
              styles.input,
              {
                [styles["input--transparent"]]: transparent,
                [styles["input--align-right"]]: alignRight,
                [styles["input--hide-focus"]]: hideFocus,
                [styles["input--has-no-placeholder"]]: !placeholder,
                [styles["field--has-label"]]: label !== undefined,
                [styles["input--type-password"]]: type === "password",
              },
              inputClassName,
            )}
            type={type === "password" ? isPasswordVisible : type}
            ref={internalRef}
            disabled={disabled}
            placeholder={placeholder || "-"}
            onFocus={() => setFocused(true)}
            onChange={(e) => {
              setIsEmpty(!e.target.value);

              if (onChange) {
                onChange(e.target.value);
              }
            }}
            value={value}
            autoComplete="off"
            onBlur={() => {
              setFocused(false);
            }}
            onWheel={baseInputProps?.onWheel}
          />
          {type === "password" && (
            <>
              {isPasswordVisible === "password" ? (
                <span className={styles.passwordVisibility} onClick={() => setPasswordVisible("text")}>
                  <IconEye />
                </span>
              ) : (
                <span className={styles.passwordVisibility} onClick={() => setPasswordVisible("password")}>
                  <IconEyeOff />
                </span>
              )}
            </>
          )}
        </>
      );
    }
    return (
      <>
        <input
          {...rest}
          className={classNames(
            styles.input,
            {
              [styles["input--transparent"]]: transparent,
              [styles["input--align-right"]]: alignRight,
              [styles["input--hide-focus"]]: hideFocus,
              [styles["input--has-no-placeholder"]]: !placeholder,
              [styles["field--has-label"]]: label !== undefined,
              [styles["input--type-password"]]: type === "password",
            },
            inputClassName,
          )}
          type={type === "password" ? isPasswordVisible : type}
          ref={internalRef}
          disabled={disabled}
          placeholder={placeholder || "-"}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            setIsEmpty(!e.target.value);

            if (onChange) {
              onChange(e.target.value);
            }
          }}
          defaultValue={defaultValue || undefined}
          autoComplete="off"
          onBlur={() => {
            setFocused(false);
          }}
          onWheel={baseInputProps?.onWheel}
        />
        {type === "password" && (
          <>
            {isPasswordVisible === "password" ? (
              <span className={styles.passwordVisibility} onClick={() => setPasswordVisible("text")}>
                <IconEye />
              </span>
            ) : (
              <span className={styles.passwordVisibility} onClick={() => setPasswordVisible("password")}>
                <IconEyeOff />
              </span>
            )}
          </>
        )}
      </>
    );
  }
}
