export interface IconShoppingCartProps {
  hasTitle?: boolean;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

function IconShoppingCart({ hasTitle, className, fill, width = 29, height = 28 }: IconShoppingCartProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29 28">
      {hasTitle && <title>IconShoppingCart</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.164 6.01167C28.1926 6.06285 29 6.90132 29 7.91834V12.1254C29 14.1015 27.6816 15.8414 25.7618 16.3989L18.2009 18.5948C15.5809 19.3558 12.9592 19.8285 10.3366 20.0125C7.8757 20.1852 5.7308 18.3738 5.52187 15.9464C5.21214 12.3477 4.88947 9.62184 4.55546 7.77483C4.21014 5.86529 3.7314 4.09091 3.11967 2.4514C2.933 1.95106 2.45025 1.61867 1.91026 1.61867H0.644444C0.288528 1.61867 0 1.33376 0 0.982311C0 0.630857 0.288528 0.345947 0.644444 0.345947H1.91026C2.99024 0.345947 3.95573 1.01072 4.32909 2.0114C4.7348 3.09876 5.08385 4.24062 5.37636 5.43692C12.2815 5.44092 19.544 5.63252 27.164 6.01167ZM6.44444 25.1641C6.44444 26.9214 7.88708 28.3459 9.66667 28.3459C11.4463 28.3459 12.8889 26.9214 12.8889 25.1641C12.8889 23.4069 11.4463 21.9823 9.66667 21.9823C7.88708 21.9823 6.44444 23.4069 6.44444 25.1641ZM19.3333 25.1641C19.3333 26.9214 20.776 28.3459 22.5556 28.3459C24.3351 28.3459 25.7778 26.9214 25.7778 25.1641C25.7778 23.4069 24.3351 21.9823 22.5556 21.9823C20.776 21.9823 19.3333 23.4069 19.3333 25.1641ZM11.6 25.1641C11.6 26.2185 10.7344 27.0732 9.66667 27.0732C8.59892 27.0732 7.73333 26.2185 7.73333 25.1641C7.73333 24.1098 8.59892 23.255 9.66667 23.255C10.7344 23.255 11.6 24.1098 11.6 25.1641ZM22.5556 27.0732C23.6233 27.0732 24.4889 26.2185 24.4889 25.1641C24.4889 24.1098 23.6233 23.255 22.5556 23.255C21.4878 23.255 20.6222 24.1098 20.6222 25.1641C20.6222 26.2185 21.4878 27.0732 22.5556 27.0732ZM6.80613 15.8386C6.49334 12.2044 6.16658 9.44395 5.82428 7.55109C5.77307 7.26795 5.71901 6.98756 5.66209 6.70992C12.4596 6.71897 19.6053 6.90991 27.0991 7.28278C27.442 7.29984 27.7111 7.57933 27.7111 7.91834V12.1254C27.7111 13.5369 26.7694 14.7797 25.3981 15.1779L17.8372 17.3738C15.3051 18.1092 12.7747 18.5655 10.2452 18.743C8.48744 18.8663 6.95536 17.5725 6.80613 15.8386Z"
        fill={fill}
      />
    </svg>
  );
}

export default IconShoppingCart;
