import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useState } from "react";

import { Button } from "../../../components/button/Button";
import Field from "../../../components/field/Field";
import { Heading, HeadingGutter } from "../../../components/heading/Heading";
import { Loader } from "../../../components/loader/Loader";
import {
  useProfileViewChangePasswordMutation,
  useProfileViewChangeUserInfoMutation,
  useForgottenConfirmationPasswordMutation,
  useProfileViewQuery,
} from "../../../graphql/schema";
import { digitsRegexp, spaceRegexp, USStates } from "../../../services/constants";
import { Section, SectionGutter } from "../../../components/section/Section";
import { Modal } from "../../../components/modal/Modal";
import { showGraphqlValidationErrors } from "../../../services/showGraphqlValidationErrors";
import { Panel } from "../../../components/panel/Panel";
import { OrderConfirmationSection } from "../../special-registration/order-confirmation-section/OrderConfirmationSection";

import styles from "./profile.module.scss";

type ConfirmationPasswordSchema = {
  secretPassword: string;
};

export default function ProfileView() {
  const [t] = useTranslation();

  const [isConfirmationModalOpen, setIsconfirmationModalOpen] = useState(false);
  const [isProfileFormEdited, setIsProfileFormEdited] = useState(false);
  const [confirmationPasswordError, setConfirmationPasswordError] = useState<string | null>(null);
  const { data, error, loading, previousData } = useProfileViewQuery();
  const [changePassword] = useProfileViewChangePasswordMutation();
  const [forgottenConfirmationPassword] = useForgottenConfirmationPasswordMutation();

  const [changeProfileInfo] = useProfileViewChangeUserInfoMutation();
  const [submittedPersonalData, setSubmittedPersonalData] = useState<ChangeProfileInfo | null>(null);

  const {
    register: profileRegister,
    handleSubmit: handleProfileSubmit,
    errors: profileInputErrors,
  } = useUpdateProfileForm();

  const { register, handleSubmit, errors: userInputErrors } = useUpdatePasswordForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRequestPasswordChange = async () => {
    try {
      const response = await forgottenConfirmationPassword();
      if (response.data?.forgottenConfirmationPassword?.result.type === "SUCCESS") {
        toast.success("Password reset request sent successfully!");
        setIsModalOpen(false);
      } else {
        toast.error("Failed to request password reset.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const {
    register: secretPasswordRegister,
    handleSubmit: handleSecretPasswordSubmit,
    errors: secretPasswordInputErrors,
  } = useSecretPasswordForm();

  // show modal before submitting profile info
  const handleShownConfirmationPasswordModal = handleProfileSubmit(
    async ({ address, city, state, postcode, phone }) => {
      setIsconfirmationModalOpen(true);
      setSubmittedPersonalData({ address, city, state, postcode, phone });
    },
  );

  // handle change profile info on submit
  const handleChangeProfileInfo = handleSecretPasswordSubmit(async ({ secretPassword }) => {
    try {
      const result = await changeProfileInfo({
        variables: { secretPassword, ...submittedPersonalData },
        refetchQueries: "active",
        awaitRefetchQueries: true,
      });

      if (result.data?.user?.update?.success) {
        toast.success(t("Profile info changed!"));
        setIsconfirmationModalOpen(false);
        setIsProfileFormEdited(false);
        setSubmittedPersonalData(null);
      } else {
        const errorMessage = (result?.data?.user?.update?.result.errors || [])[0]?.message;
        if (errorMessage) {
          setConfirmationPasswordError(errorMessage);
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      console.error("Profile change failed", err);

      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message, { autoClose: false });
      }
    }
  });

  const handleChangePassword = handleSubmit(async ({ currentPassword, newPassword, repeatNewPassword }) => {
    try {
      const res = await changePassword({ variables: { data: { currentPassword, newPassword, repeatNewPassword } } });
      if (res.data?.user?.changePassword?.success) {
        toast.success(t("Password changed successfully!"));
      } else {
        throw new Error(t("Password changing failed, please ensure details are right"));
      }
    } catch (e) {
      toast.error(t("Password changing failed, please ensure details are right"));
    }
  });

  const user = data?.viewer;
  const isUsCountry = user?.countryCode === "US";

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Section gutter={SectionGutter.LARGE} center>
      <Loader visibleOnFirstLoad={{ loading, previousData }} />

      {/* change address form */}
      {user && (
        <form
          className={styles.form}
          onSubmit={handleShownConfirmationPasswordModal}
          onChange={() => setIsProfileFormEdited(true)}
        >
          <Section className={styles.form} gutter={20} withSpace centerSelf>
            <Heading level={5} gutter={HeadingGutter.MEDIUM} center="MOBILE">
              {t("Personal Details")}
            </Heading>
            <Field
              className={styles.field}
              label={t("First name")}
              name="firstName"
              disabled
              defaultValue={user.firstName}
            />
            <Field
              className={styles.field}
              label={t("Last name")}
              name="lastName"
              disabled
              defaultValue={user.lastName}
            />
            <Field className={styles.field} label={t("Email")} name="email" disabled defaultValue={user.email} />
            <Field
              className={styles.field}
              label={t("Phone")}
              name="phone"
              internalRef={profileRegister}
              defaultValue={user.phone}
            />
          </Section>
          <Section gutter={20} withSpace centerSelf>
            <Heading level={5} gutter={HeadingGutter.MEDIUM} center="MOBILE">
              {t("Address")}
            </Heading>
            <Field
              className={styles.field}
              label={t("House number, Street name")}
              name="address"
              internalRef={profileRegister}
              error={profileInputErrors.address}
              isRequired={isUsCountry}
              defaultValue={user.address}
            />
            <Field
              className={styles.field}
              label={t("City")}
              name="city"
              internalRef={profileRegister}
              error={profileInputErrors.city}
              isRequired={isUsCountry}
              defaultValue={user.city}
            />
            {isUsCountry ? (
              <Field
                className={styles.field}
                label={t("Choose state")}
                type="select"
                name="state"
                error={profileInputErrors.state}
                isRequired
                internalRef={profileRegister}
                options={USStates.map((state) => ({ label: state, value: state }))}
                defaultValue={user.state}
              />
            ) : (
              <Field
                className={styles.field}
                label={t("Choose state")}
                name="state"
                internalRef={profileRegister}
                error={profileInputErrors.state}
                defaultValue={user.state}
              />
            )}
            <Field
              className={styles.field}
              label={isUsCountry ? t("Zip Code") : t("Postal code")}
              name="postcode"
              internalRef={profileRegister}
              error={profileInputErrors.postcode}
              defaultValue={user.postcode}
            />
            <Field
              className={styles.field}
              label={t("Country")}
              name="country"
              internalRef={profileRegister}
              defaultValue={user.country}
              disabled
            />
          </Section>
          <Section gutter={SectionGutter.EXTRA_LARGE} center withSpace>
            <Button
              shape="ROUND"
              color="BLUE"
              height="LARGE"
              fontSize={16}
              weight="BOLD"
              width={230}
              disabled={!isProfileFormEdited}
              type="submit"
            >
              {t("Save changes")}
            </Button>
          </Section>
        </form>
      )}

      {/* change password form */}
      <form className={styles.form} onSubmit={handleChangePassword}>
        <Section gutter={SectionGutter.LARGE} withSpace centerSelf>
          <Heading level={5} gutter={HeadingGutter.MEDIUM} center="MOBILE">
            {t("Change password")}
          </Heading>
          <Field
            label={t("Current password")}
            name="currentPassword"
            type="password"
            internalRef={register}
            error={userInputErrors.currentPassword}
            isRequired
            className={styles.field}
          />
          <Field
            label={t("New password")}
            name="newPassword"
            type="password"
            internalRef={register}
            error={userInputErrors.newPassword}
            isRequired
            className={styles.field}
          />
          <Field
            label={t("Repeat new password")}
            name="repeatNewPassword"
            type="password"
            internalRef={register}
            error={userInputErrors.repeatNewPassword}
            isRequired
            className={styles.field}
          />
        </Section>
        <Section gutter={0} center withSpace>
          <Button shape="ROUND" color="BLUE" height="LARGE" fontSize={16} weight="BOLD">
            {t("Change password")}
          </Button>
        </Section>
      </form>

      {/* secret password modal */}
      <Modal isOpen={isConfirmationModalOpen} close={() => setIsconfirmationModalOpen(false)}>
        <Panel contentClassName={styles["modal-content"]}>
          <p className={styles["modal-description"]}>{t("Enter secret password to change profile information")}</p>
          <form className={styles.form} onSubmit={handleChangeProfileInfo}>
            <Field
              className={styles["password-field"]}
              label={t("Secret password")}
              type="password"
              name="secretPassword"
              internalRef={secretPasswordRegister}
              isRequired
              onChange={() => setConfirmationPasswordError(null)}
              error={
                secretPasswordInputErrors.secretPassword ||
                (confirmationPasswordError ? { message: confirmationPasswordError, type: "required" } : undefined)
              }
            />
            <Button
              type="submit"
              shape="ROUND"
              color="BLUE"
              height="LARGE"
              fontSize={16}
              weight="BOLD"
              width={230}
              center="BLOCK_AND_MARGIN"
            >
              {t("Submit")}
            </Button>
          </form>
        </Panel>
      </Modal>
      <Section gutter={SectionGutter.LARGE} withSpace centerSelf>
        <Heading level={5} gutter={HeadingGutter.MEDIUM} center="MOBILE" style={{ marginTop: "20px" }}>
          {t("Request Confirmation Password Reset")}
        </Heading>
        <Section gutter={0} center withSpace>
          <Button
            shape="ROUND"
            color="BLUE"
            height="LARGE"
            fontSize={16}
            weight="BOLD"
            onClick={() => setIsModalOpen(true)}
            style={{ marginTop: "10px" }}
          >
            Request Confirmation Password Reset
          </Button>
        </Section>
      </Section>

      <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
        <div className={styles["modal-content"]} style={{ padding: "10px" }}>
          <p style={{ marginBottom: "15px" }}>Are you sure that you want to reset your confirmation password?</p>
          <div className={styles["modal-buttons"]}>
            <Button
              shape="ROUND"
              color="BLUE"
              height="MEDIUM"
              fontSize={14}
              weight="BOLD"
              onClick={handleRequestPasswordChange}
            >
              Request Password Change
            </Button>
            <Button
              shape="ROUND"
              color="GREY"
              height="MEDIUM"
              fontSize={14}
              weight="BOLD"
              onClick={() => setIsModalOpen(false)}
              style={{ marginLeft: "20px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Section>
  );
}

type ChangeProfileInfo = {
  address?: string;
  city?: string;
  state?: string;
  postcode?: string;
  phone?: string;
};

function useUpdateProfileForm() {
  const profileInfoValidationSchema: yup.SchemaOf<ChangeProfileInfo> = yup.object().shape({
    address: yup.string(),
    city: yup.string(),
    state: yup.string(),
    postcode: yup.string(),
    phone: yup.string(),
  });

  const { register, handleSubmit, errors } = useForm<ChangeProfileInfo>({
    resolver: yupResolver(profileInfoValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
  };
}

function useSecretPasswordForm() {
  const [t] = useTranslation();

  const secretPasswordSchema = yup.object().shape({
    secretPassword: yup.string().required(t("Secret password is missing")),
  });

  const { register, handleSubmit, errors } = useForm<ConfirmationPasswordSchema>({
    resolver: yupResolver(secretPasswordSchema),
  });

  return { register, handleSubmit, errors };
}

type ChangePasswordFormData = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

function useUpdatePasswordForm() {
  const [t] = useTranslation();

  const changePasswordValidationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .min(4, t("Password should be at least 4 char long"))
      .max(60, t("Password should be not longer than 60 chars"))
      .required(t("Please set current password")),
    newPassword: yup
      .string()
      .oneOf([yup.ref("repeatNewPassword"), null], t("Passwords do not match"))
      .min(8, t("Password should be at least 8 char long"))
      .max(60, t("Password should be not longer than 60 chars"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("Password is missing")),
    repeatNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], t("Passwords do not match"))
      .min(8, t("Password should be at least 8 char long"))
      .max(60, t("Password should be not longer than 60 chars"))
      .test("has-uppercase", t("Password should have uppercase letters"), (value) => value?.toLowerCase() !== value)
      .test("has-digits", t("Password should have at least one digit"), (value) => digitsRegexp.test(value || ""))
      .test("has-space", t("Password should have no space"), (value) => !spaceRegexp.test(value || ""))
      .required(t("Repeat your password")),
  });

  const { register, handleSubmit, errors } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(changePasswordValidationSchema),
  });

  return { register, handleSubmit, errors };
}
