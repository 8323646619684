import classNames from "classnames";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "../heading/Heading";

import styles from "./panel.module.scss";

export enum PanelStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
}

export enum PanelWidth {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  INLINE = "INLINE",
}

export interface PanelProps {
  panelStyle?: keyof typeof PanelStyle;
  width?: PanelWidth | number;
  caption?: string | React.ReactNode;
  hideImage?: boolean;
  fullWidth?: boolean;
  imagePath?: string;
  title?: string;
  label?: string;
  className?: string;
  captionClassName?: string;
  contentClassName?: string;
  titleClassName?: string;
  children?: ReactNode;
}

export const Panel: React.FC<PanelProps> = ({
  children,
  panelStyle,
  width,
  caption,
  hideImage,
  imagePath,
  title,
  label,
  className,
  captionClassName,
  contentClassName,
  titleClassName,
  fullWidth,
}) => {
  const [t] = useTranslation();

  //   if (panelStyle === "SECONDARY" && !imagePath) {
  //     throw new Error("Panel with secondary prop requires imagePath and vice versa");
  //   }

  const customWidth = typeof width === "number" ? width : undefined;

  // panel width classNames
  const widthClassNames = classNames({
    [styles["panel--small"]]: width === PanelWidth.SMALL,
    [styles["panel--medium"]]: width === PanelWidth.MEDIUM,
    [styles["panel--large"]]: width === PanelWidth.LARGE,
    [styles["panel--inline"]]: width === PanelWidth.INLINE,
  });

  const styleClassNames = classNames({
    [styles["panel--tertiary"]]: panelStyle === "TERTIARY",
  });

  return (
    <div
      style={{
        width: customWidth === 0 ? "auto" : customWidth,
        maxWidth: customWidth ? "100%" : undefined,
      }}
      className={classNames(
        styles.panel,
        widthClassNames,
        styleClassNames,
        {
          [styles["panel--secondary"]]: panelStyle === "SECONDARY",
        },
        className,
      )}
    >
      {imagePath && !hideImage && (
        <div
          className={classNames(styles["image-wrap"], {
            [styles["image-wrap--secondary"]]: panelStyle === "SECONDARY",
          })}
        >
          <img src={imagePath} alt={t("illustration")} className={styles.image} />
        </div>
      )}
      <div
        className={classNames(
          styles["panel-content"],
          {
            [styles["panel-content--secondary"]]: panelStyle === "SECONDARY",
            [styles["panel-content--with-image"]]: imagePath,
            [styles["panel-content--full-width"]]: fullWidth,
          },
          contentClassName,
        )}
        style={{ width: customWidth === 0 ? "100%" : undefined }}
      >
        {label && (
          <div className={styles["label-wrap"]}>
            <div className={styles.label}>{label}</div>
          </div>
        )}
        {title && (
          <Heading
            level={2}
            center={panelStyle === "SECONDARY" || "MOBILE"}
            className={classNames(styles.title, titleClassName)}
          >
            {title}
          </Heading>
        )}
        {caption && <p className={classNames(styles["title-caption"], captionClassName)}>{caption}</p>}
        {children}
      </div>
    </div>
  );
};
