import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Benefits } from "../../components/benefits/Benefits";
import { Container } from "../../components/container/Container";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import RegistrationForm from "../../components/registration-form/RegistrationForm";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";

export default function RegisterUserView() {
  const [t] = useTranslation();
  const { isMobile } = useWindowDimensionsContext();

  return (
    <Container secondary>
      <Panel panelStyle={"PRIMARY"} title={t("Welcome!")} width={PanelWidth.MEDIUM} fullWidth={!isMobile}>
        <RegistrationForm />
      </Panel>
      <Helmet>
        <title>SF Suite is the best mlm company to join</title>
        <meta property="og:title" content="SF Suite is the best mlm company to join" />
        <meta
          property="og:description"
          content="Join SF Suite community and grow towards your goals. We have hundreds of thousands of members in our community. Join today!"
        />
      </Helmet>
    </Container>
  );
}
