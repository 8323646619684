import { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { hide } from "@popperjs/core";

import { IconLogoSuccessFactoryNoText } from "../icon/IconLogoSuccessFactoryNoText";
import Price, { CurrencyCode } from "../price/Price";

import styles from "./gift-card.module.scss";

export interface GiftCardProps {
  className?: string;
  width?: number;
  value?: string;
  currencyCode: keyof typeof CurrencyCode;
  hideSign?: boolean;
  isBlack?: boolean;
}

export const GiftCard: FC<GiftCardProps> = ({
  className,
  width = 350,
  value,
  currencyCode,
  isBlack = false,
  hideSign = false,
}) => {
  // access translation keys
  const { t } = useTranslation();

  const heightRatio = 0.63;

  return (
    <div
      style={{ height: `calc(${width}px * ${heightRatio})`, width: `${width}px` }}
      className={classNames(styles.wrap, { [styles["wrap-black"]]: isBlack }, className)}
    >
      <div className={styles["corner-text"]}>{isBlack ? "USDS" : "SCR"}</div>
      <IconLogoSuccessFactoryNoText className={styles["background-logo"]} fill={isBlack ? "#666" : "#ff871c"} />
      <IconLogoSuccessFactoryNoText
        className={styles.logo}
        width={28}
        height={35}
        fill={isBlack ? "#ff782e" : "#fff"}
      />

      <div className={styles.price}>
        {value && !Number.isNaN(parseFloat(value)) ? (
          <Price price={value} currency={currencyCode} hideSign={hideSign} />
        ) : (
          ""
        )}
      </div>
      <div className={styles.label}>{isBlack ? t("USDS Gift Card") : t("SCR Gift Card")}</div>
    </div>
  );
};
