import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useWindowDimensionsContext } from "../../../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Heading } from "../../../../components/heading/Heading";
import { Section } from "../../../../components/section/Section";
import { Separator } from "../../../../components/separator/Separator";
import { AsyncImg } from "../../../../components/async-img/AsyncImg";

import styles from "./games-view.module.scss";

export interface GamesViewProps {
  className?: string;
}

export const GamesView: FC<GamesViewProps> = () => {
  // access translation keys
  const { t } = useTranslation();

  // calculate viewport dimensions
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  return (
    <Section gutter={100} withSpace className={styles.wrap}>
      <Heading className={styles["main-title"]} gutter={8} level={4}>
        {t("Games")}
      </Heading>
      <div className={styles["main-content"]}>
        {/* <div className={styles["src-content-container"]}>
          <div className={styles["text-container"]}>
            <p>
              Capybara Nation is a Web3-powered Telegram game where you build and manage your own nation, making
              strategic decisions to maximize profits and growth. Earn Capy Coins through daily quests, upgrade Quest
              Cards, and collaborate with friends for bigger rewards. With a mix of passive income, strategic depth, and
              community-driven gameplay, every choice shapes your nation’s success! Launch the game in Telegram!
            </p>
          </div>
        </div> */}
        {/* <Separator type="PRIMARY" /> */}
        <h3 className={styles.name}>
          <strong>{t("Capybara Nation")}</strong>{" "}
        </h3>
        {/* <Separator type="PRIMARY" /> */}
        <div className={styles["game-items"]}>
          <div className={styles["banner-wrap"]}>
            <AsyncImg className={styles.banner} src="/images/games/capybara_nation_small.png" />
          </div>
          <p>
            Capybara Nation is a Web3-powered Telegram game where you build and manage your own nation, making strategic
            decisions to maximize profits and growth. Earn Capy Coins through daily quests, upgrade Quest Cards, and
            collaborate with friends for bigger rewards. With a mix of passive income, strategic depth, and
            community-driven gameplay, every choice shapes your nation’s success!
            <br />
            <br />
            <a href="https://t.me/capybara_nation_bot/start?startapp=ref_7220969927" target="_blank" rel="noreferrer">
              Launch the game in Telegram!
            </a>
          </p>
        </div>
        <Separator type="PRIMARY" />
        <h3 className={styles.name}>
          <strong>{t("Nakamoto Games")}</strong>{" "}
        </h3>
        <div className={styles["game-items"]}>
          <div className={styles["banner-wrap"]}>
            <AsyncImg className={styles.banner} src="/images/games/nakamoto_games_small.png" />
          </div>
          <p>
            Nakamoto Games is a Web3 Gaming Ecosystem that provides a platform for games that are based on skill,
            strategy, and in-depth game knowledge rather than luck. All of their Play-to-Earn games require strategic
            thinking and proficiency, allowing users to utilize their skills and gaming knowledge to earn rewards. All
            games are designed to be hyper-casual, fun, and easy to understand. Nakamoto Games intends to become the
            largest play-to-earn ecosystem in GameFi.
            <br />
            <br />
            <a
              href="https://nakamoto.games/register?referral=67c1a1ad2cd42ea06b761793"
              target="_blank"
              rel="noreferrer"
            >
              Register your account and browse the games.
            </a>
          </p>
        </div>
      </div>
    </Section>
  );
};
