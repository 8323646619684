import { useTranslation } from "react-i18next";

import { CurrencyCode, DigitalProductPurchaseType, ServicePeriodCode, SubscriptionType } from "../../graphql/schema";
export { CurrencyCode } from "../../graphql/schema";

export interface PriceProps {
  price?: string | number;
  maxPrice?: string | number;
  discountPrice?: string | number | null;
  maxDiscountPrice?: string | number | null;
  isSubscription?: boolean | null;
  isNegative?: boolean;
  subscriptionType?: SubscriptionType | null;
  isDigitalProduct?: boolean | null;
  digitalProductPurchaseType?: DigitalProductPurchaseType | keyof typeof ServicePeriodCode | null;
  currency: keyof typeof CurrencyCode | undefined;
  hideSubUnits?: boolean;
  hideSign?: boolean;
  comingSoon?: boolean;
  period?: string | null;
}

export const CurrencySign: { [K in CurrencyCode]: string } = {
  // EUR: "€",
  EUR: "$",
  USD: "$",
  DAG: "DAG",
};

const SUB_UNITS: { [K in CurrencyCode]: number } = {
  EUR: 2,
  USD: 2,
  DAG: 6,
};

export default function Price({
  price,
  maxPrice,
  discountPrice,
  maxDiscountPrice,
  isSubscription,
  isNegative,
  subscriptionType,
  isDigitalProduct,
  digitalProductPurchaseType,
  currency = "EUR",
  hideSubUnits,
  period,
  hideSign,
  comingSoon,
}: PriceProps) {
  const [t] = useTranslation();

  const getByPeriod = () => {
    switch (period) {
      case SubscriptionType.ANNUAL:
        return `/ ${t("annually")}`;
      case SubscriptionType.SEMIANNUAL:
        return `/ ${t("7 mo")}`;
      case SubscriptionType.MONTHLY:
        return `/ ${t("mo")}`;
      case null:
        return "";
    }
  };

  const getSubscriptionType = () => {
    switch (subscriptionType) {
      case SubscriptionType.ANNUAL:
        return t("annually");
      case SubscriptionType.SEMIANNUAL:
        return t("7 mo");
      case SubscriptionType.MONTHLY:
        return t("mo");
      case null:
        return "";
    }
  };

  const getDigitalProductPurchaseType = () => {
    switch (digitalProductPurchaseType) {
      case DigitalProductPurchaseType.ANNUAL:
      case "TWELVE_MONTHS":
        return `/ ${t("annually")}`;

      case "SIX_MONTHS":
        return t("6 mo");

      case DigitalProductPurchaseType.MONTHLY:
      case "ONE_MONTH":
        return `/ ${t("mo")}`;

      case DigitalProductPurchaseType.ONE_TIME:
        return "";
      default:
        return "";
    }
  };
  const fixedPrice = formatPrice(price, currency, hideSubUnits);
  const fixedDiscountPrice = formatPrice(discountPrice, currency, hideSubUnits);
  const prefix = isNegative ? "-" : "";

  const sign = hideSign ? "" : CurrencySign[currency];

  const getPrice = () => {
    if (fixedDiscountPrice) {
      return `${fixedDiscountPrice}${maxDiscountPrice ? `-${maxDiscountPrice}` : ""}${sign}`;
    }

    return `${fixedPrice}${maxPrice ? `-${maxPrice}` : ""}${sign}`;
  };

  const getSubscriptionPrice = () => {
    if (fixedDiscountPrice) {
      return `${fixedDiscountPrice}${maxDiscountPrice ? `-${maxDiscountPrice}` : ""}${sign} / ${getSubscriptionType()}`;
    }

    return `${fixedPrice}${maxPrice ? `-${maxPrice}` : ""}${sign} / ${getSubscriptionType()}`;
  };

  const getDigitalProductPrice = () => {
    if (digitalProductPurchaseType === DigitalProductPurchaseType.FREE) {
      return "";
    }

    if (fixedDiscountPrice) {
      return `${fixedDiscountPrice}${
        maxDiscountPrice ? `-${maxDiscountPrice}` : ""
      }${sign} ${getDigitalProductPurchaseType()}`;
    }

    return `${fixedPrice}${maxPrice ? `-${maxPrice}` : ""}${sign} ${getDigitalProductPurchaseType()}`;
  };

  const getDigitalByPeriod = () => {
    if (digitalProductPurchaseType === DigitalProductPurchaseType.FREE) {
      return "";
    }

    if (fixedDiscountPrice) {
      return `${fixedDiscountPrice}${
        maxDiscountPrice ? `-${maxDiscountPrice}` : ""
      }${sign} ${getDigitalProductPurchaseType()}`;
    }

    return `${fixedPrice}${maxPrice ? `-${maxPrice}` : ""}${sign} ${getByPeriod()}`;
  };

  if (comingSoon) {
    return (
      <span className="notranslate" style={{ fontSize: "x-small", color: "#fec173" }}>
        {t("Coming soon")}
      </span>
    );
  }

  if (period) {
    return (
      <span className="notranslate">
        {prefix}
        {getDigitalByPeriod()}
      </span>
    );
  }

  if (isDigitalProduct) {
    return (
      <span className="notranslate">
        {prefix}
        {getDigitalProductPrice()}
      </span>
    );
  }

  if (isSubscription) {
    return (
      <span className="notranslate">
        {prefix}
        {getSubscriptionPrice()}
      </span>
    );
  }

  return (
    <span className="notranslate">
      {prefix}
      {getPrice()}
    </span>
  );
}

export function formatPrice(
  price: string | number | undefined | null,
  currencyCode: keyof typeof CurrencyCode,
  hideSubUnits?: boolean,
) {
  const fractionDigits = hideSubUnits && canHideSubUnits(price) ? 0 : SUB_UNITS[currencyCode];

  return price === undefined || price === null ? "" : parseFloat(price.toString()).toFixed(fractionDigits);
}

function canHideSubUnits(num: string | number | undefined | null) {
  return num !== undefined && num !== null && Number(num) % 1 === 0; // check if number has decimal places
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat("fr-FR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    .format(number)
    .replace(",", ".");
}
