import classNames from "classnames";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { Routes, HiddenHeaderPathnames } from "../../services/constants";
import { AccountMenuButton, AccountMenuItem } from "../account-menu-button/AccountMenuButton";
import { Cart } from "../cart/Cart";
import IconMenuBurger from "../icon/IconMenuBurger";
import IconLogoSuccessFactory from "../icon/IconLogoSuccessFactory";
import IconUser from "../icon/IconUser";
import LanguageSelect from "../language-select/LanguageSelect";
import { Menu } from "../menu/Menu";
import { MobileMenu } from "../mobile-menu/MobileMenu";
import { ProductSelectorProduct, ProductsSelector } from "../products-selector/ProductsSelector";
import { IconSignIn } from "../icon/IconSignIn";
import { IconAddUser } from "../icon/IconAddUser";

import styles from "./header.module.scss";

export enum HeaderGutter {
  MEDIUM = "MEDIUM",
}

export interface HeaderProps {
  isAuthenticated: boolean;
  isViewerActive: boolean;
  hasAccessToWebOffice: boolean;
  gutter?: keyof typeof HeaderGutter | number;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  navigateToUrl?: string | false;
  products: ProductSelectorProduct[];
  accountMenuItems: AccountMenuItem[];
  className?: string;
  affiliateId?: number;
}

export const Header: React.FC<HeaderProps> = ({
  isAuthenticated,
  isViewerActive,
  hasAccessToWebOffice,
  gutter,
  secondary,
  tertiary,
  primary = !secondary && !tertiary,
  navigateToUrl,
  products,
  accountMenuItems = [],
  className,
  affiliateId,
}) => {
  const { pathname } = useLocation();

  // check if header should be hidden
  const hiddenHeader = Object.values(HiddenHeaderPathnames).find((url) => pathname.startsWith(url));

  // control mobile menu open state
  const [openMenu, setOpenMenu] = useState(false);

  const [t] = useTranslation();

  if (hiddenHeader) {
    return null;
  }

  const primaryContent = () => (
    <>
      <div className={styles["header-wrap"]}>
        <div className={styles["nav-link-wrap"]}>
          <NavLink exact to="/" className={styles["header-link"]} activeClassName={styles["active-link"]}>
            {t("Home")}
          </NavLink>
          <NavLink exact to="/awentures" className={styles["header-link"]} activeClassName={styles["active-link"]}>
            {t("Products")}
          </NavLink>
          {/* <NavLink to={Routes.ABOUT} className={styles["header-link"]} activeClassName={styles["active-link"]}>
          {t("About")}
        </NavLink> */}
          {/* {products.length > 0 && <ProductsSelector triggerClassName={styles["header-link"]} products={products} />} */}
        </div>
        <Link to="/">
          <IconLogoSuccessFactory className={styles.logo} />
        </Link>
        <div className={styles["header-icons"]}>
          {/* {!isAuthenticated && (
            <Link
              to={affiliateId ? `/registration/${affiliateId}` : Routes.REQUEST_INVITE}
              className={styles["sign-up"]}
            >
              {t("Sign up")}
              <IconAddUser className={styles["sign-up-icon"]} />
            </Link>
          )} */}

          {isAuthenticated ? (
            <AccountMenuButton
              hasAccessToWebOffice={hasAccessToWebOffice}
              isActive={isViewerActive}
              accountMenuItems={accountMenuItems}
            />
          ) : (
            <Link to={Routes.LOGIN} className={styles.login}>
              {t("Login").toUpperCase()}
              <IconSignIn className={styles["login-icon"]} />
            </Link>
          )}
          {/* <LanguageSelect /> */}
          <Cart />
        </div>
        <div className={styles["burger-wrap"]}>
          <div onClick={() => setOpenMenu(!openMenu)}>
            <IconMenuBurger />
          </div>
          <Menu open={openMenu} setOpen={setOpenMenu}>
            <MobileMenu
              hasAccessToWebOffice={hasAccessToWebOffice}
              setOpen={setOpenMenu}
              isAuthenticated={isAuthenticated}
              products={products}
              accountMenuItems={accountMenuItems}
            />
          </Menu>
        </div>
      </div>
    </>
  );

  const secondaryContent = () => (
    <>
      <IconLogoSuccessFactory logoFill="#92929d" textFill="#92929d" className={styles["logo--secondary"]} />
      {navigateToUrl && (
        <Link to={navigateToUrl} className={styles["logout-link"]}>
          {t("Logout")}
        </Link>
      )}
    </>
  );

  const tertiaryContent = () => (
    <Link to="/">
      <IconLogoSuccessFactory className={styles["logo--secondary"]} />
    </Link>
  );

  // heading types
  const headerTypes = () =>
    classNames({
      [styles["header--primary"]]: primary,
      [styles["header--tertiary"]]: tertiary,
    });

  // heading gutters
  const gutters = () =>
    classNames({
      [styles["header--gutter-medium"]]: gutter === "MEDIUM",
    });

  // check if gutter value is a number
  const customGutter = typeof gutter === "number" ? gutter : undefined;

  return (
    <div
      style={{ marginBottom: customGutter }}
      className={classNames(
        styles.header,
        headerTypes(),
        gutters(),
        {
          [styles["header--has-navigation"]]: navigateToUrl,
        },
        className,
      )}
    >
      {primary && primaryContent()}
      {secondary && secondaryContent()}
      {tertiary && tertiaryContent()}

      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href={`${process.env.REACT_APP_HOST}${pathname}`} />
        <meta property="og:url" content={pathname} />
      </Helmet>
    </div>
  );
};
