import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useWindowDimensionsContext } from "../../../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Heading } from "../../../../components/heading/Heading";
import { Button } from "../../../../components/button/Button";
import {
  useConvertCommunityDagsToScrMutation,
  useConvertDagsToD2TMutation,
  useConvertDagsToScrMutation,
  useDagCommunityViewQuery,
  useViewerQuery,
} from "../../../../graphql/schema";
import { Color } from "../../../../services/buttonLinkConstants";
import { Section } from "../../../../components/section/Section";
import { Modal, ModalKind } from "../../../../components/modal/Modal";
import { Panel, PanelWidth } from "../../../../components/panel/Panel";
import { showGraphqlValidationErrors } from "../../../../services/showGraphqlValidationErrors";
import Field from "../../../../components/field/Field";
import { Loader } from "../../../../components/loader/Loader";
import DateTime from "../../../../components/date-time/DateTime";
import { Layout } from "../../../../components/layout/Layout";
import { DataFraction } from "../../../../components/data-fraction/DataFraction";
import { Separator } from "../../../../components/separator/Separator";
import { IconLogoSuccessFactoryNoText } from "../../../../components/icon/IconLogoSuccessFactoryNoText";
import Price, { formatNumber } from "../../../../components/price/Price";

import styles from "./dag-community-view.module.scss";

export interface DagCommunityViewProps {
  className?: string;
}
interface BurnDagsFormInfo {
  totalAmount: string;
  confirm: boolean;
  final: boolean;
}

interface BurnDagsD2TFormInfo {
  totalAmount: string;
  name: string;
  email: string;
  fconfirm: boolean;
  accountOpened: boolean;
  acknowledgement: boolean;
  acknowledgementTrading: boolean;
}

const burnDagsValidationSchema: yup.SchemaOf<BurnDagsFormInfo> = yup.object().shape({
  totalAmount: yup.string().required("Amount is required"),
  confirm: yup
    .boolean()
    .oneOf([true], "Please check that you understand the terms")
    .required("Please check that you understand the terms"),
  final: yup
    .boolean()
    .oneOf([true], "Please check that you understand that burn is final and cannot be reversed.")
    .required("Please check that you understand that burn is final and cannot be reversed."),
});

const burnDagsD2TValidationSchema: yup.SchemaOf<BurnDagsD2TFormInfo> = yup.object().shape({
  totalAmount: yup.string().required("Amount is required"),
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  fconfirm: yup
    .boolean()
    .oneOf([true], "Please check that you understand the terms")
    .required("Please check that you understand the terms"),
  accountOpened: yup
    .boolean()
    .oneOf([true], "Please check that you have opened TAG Markets account")
    .required("Please check that you have opened TAG Markets account"),
  acknowledgement: yup
    .boolean()
    .oneOf([true], "Please check that you understand that the burn and send is final and can not be reversed.")
    .required("Please check that you understand that the burn and send is final and can not be reversed."),
  acknowledgementTrading: yup
    .boolean()
    .oneOf(
      [true],
      "Please check that you understand that D2T balance can only be used for trading and is non-withdrawable.",
    )
    .required(
      "Please check that you understand that D2T balance can only be used for trading and is non-withdrawable.",
    ),
});

function useBurnDagsForm() {
  const { register, handleSubmit, errors, reset, setError } = useForm<BurnDagsFormInfo>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(burnDagsValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
  };
}

function useBurnDagsD2TForm() {
  const { register, handleSubmit, errors, reset, setError } = useForm<BurnDagsD2TFormInfo>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(burnDagsD2TValidationSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
  };
}

export const DagCommunityView: FC<DagCommunityViewProps> = () => {
  const {
    register: burnDags,
    handleSubmit: handleBurnDagsSubmit,
    errors: burnDagsInputErrors,
    reset: resetBurnDags,
    setError: setBurnDagsError,
  } = useBurnDagsForm();
  const {
    register: burnDagsD2T,
    handleSubmit: handleBurnDagsD2TSubmit,
    errors: burnDagsD2TInputErrors,
    reset: resetBurnD2TDags,
    setError: setBurnDagsD2TError,
  } = useBurnDagsD2TForm();
  const {
    register: communityBurnDags,
    handleSubmit: handleCommunityBurnDagsSubmit,
    errors: communityBurnDagsInputErrors,
    reset: resetCommunityBurnDags,
    setError: setCommunityBurnDagsError,
  } = useBurnDagsD2TForm();

  // access translation keys
  const { t } = useTranslation();
  const { data: me, refetch: meRefetch, loading: meLoading } = useViewerQuery({ fetchPolicy: "network-only" });
  const { data: totalDagsQuery } = useDagCommunityViewQuery({ fetchPolicy: "network-only" });
  const [convertDags] = useConvertDagsToScrMutation();
  const [convertCommunityDags] = useConvertCommunityDagsToScrMutation();
  const [convertDagsD2T] = useConvertDagsToD2TMutation();
  const [isBurnMode, setIsBurnMode] = useState(false);
  const [isCommunityBurnMode, setIsCommunityBurnMode] = useState(false);
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);
  const [isBurnD2TMode, setIsBurnD2TMode] = useState(false);
  const [amount, setAmount] = useState("");
  const [communityAmount, setCommunityAmount] = useState("");
  const [amountD2T, setAmountD2T] = useState("");

  function getDagsAmount() {
    return parseFloat(me?.me.dagsInfo?.totalDags ?? "0") - parseFloat(me?.me.dagsInfo?.usedDags ?? "0");
  }

  function getScrAmount() {
    return (getDagsAmount() / parseFloat(me?.me.dagsInfo?.ratio ?? "0")).toFixed(2);
  }

  function getDagsD2TAmount() {
    return parseFloat(me?.me.meD2TInfo?.totalDags ?? "0") - parseFloat(me?.me.meD2TInfo?.usedDags ?? "0");
  }

  function getScrD2TAmount() {
    return (getDagsD2TAmount() / parseFloat(me?.me.meD2TInfo?.ratio ?? "0")).toFixed(2);
  }

  const totalDags = getDagsAmount();
  const totalDagsD2T = getDagsD2TAmount();
  const totalCommunityDags = me?.viewer?.coinAccountBalance ?? 0;

  const handlePercentage = (percent: string) => {
    setAmount(((totalDags * parseFloat(percent)) / 100).toString());
  };

  const handlePercentageD2T = (percent: string) => {
    setAmountD2T(((totalDagsD2T * parseFloat(percent)) / 100).toString());
  };
  const handleCommunityPercentageD2T = (percent: string) => {
    setCommunityAmount(((totalCommunityDags * parseFloat(percent)) / 100).toString());
  };

  const handleBurnDagsFormSubmit = handleBurnDagsSubmit(async ({ totalAmount }) => {
    try {
      const result = await convertDags({
        variables: { totalAmount },
      });
      if (result.data) {
        toast.success(t("DAGS burned to SCR successfully!"));
        resetBurnDags();
        setAmount("");
        meRefetch();
        // setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          const myCustomFieldError: FieldError = {
            type: "required",
            message: errorMessage,
            ref: undefined,
          };
          burnDagsInputErrors.totalAmount = myCustomFieldError;

          setBurnDagsError("totalAmount", {
            type: "manual",
            message: errorMessage || t("An unexpected error occurred"),
          });
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      setBurnDagsError("totalAmount", {
        type: "manual",
        message: err?.message || t("An unexpected error occurred"),
      });
      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message);
      }
    }
  });
  const handleCommunityBurnDagsFormSubmit = handleCommunityBurnDagsSubmit(async ({ totalAmount, name, email }) => {
    try {
      const result = await convertCommunityDags({
        variables: { totalAmount, name, email },
      });
      if (result.data) {
        toast.success(t("DAGS burned to D2T successfully!"));
        resetCommunityBurnDags();
        setCommunityAmount("");
        meRefetch();
        // setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          const myCustomFieldError: FieldError = {
            type: "required",
            message: errorMessage,
            ref: undefined,
          };
          communityBurnDagsInputErrors.totalAmount = myCustomFieldError;

          setCommunityBurnDagsError("totalAmount", {
            type: "manual",
            message: errorMessage || t("An unexpected error occurred"),
          });
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      setCommunityBurnDagsError("totalAmount", {
        type: "manual",
        message: err?.message || t("An unexpected error occurred"),
      });
      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message);
      }
    }
  });

  const handleBurnDagsFormD2TSubmit = handleBurnDagsD2TSubmit(async ({ totalAmount, name, email }) => {
    const test = (parseFloat(totalAmount) / parseFloat(me?.me.meD2TInfo?.ratio ?? "0")).toFixed(2);
    if (parseFloat(test) < 25) {
      //   const myCustomFieldError: FieldError = {
      //     type: "required",
      //     message: t("Submitted D2T amount must be at least 25"),
      //     ref: undefined,
      //   };
      //   burnDagsD2TInputErrors.totalAmount = myCustomFieldError;
      setBurnDagsD2TError("totalAmount", {
        type: "manual",
        message: t("Submitted D2T amount must be at least 25"),
      });
      return;
    }
    try {
      const result = await convertDagsD2T({
        variables: { totalAmount, name, email },
      });
      if (result.data) {
        toast.success(t("DAGS burned to D2T successfully!"));
        resetBurnD2TDags();
        setAmountD2T("");
        meRefetch();
        // setIsWebinarsModalOpen(false);
      } else {
        const errorMessage = result?.errors?.[0]?.message;
        if (errorMessage) {
          const myCustomFieldError: FieldError = {
            type: "required",
            message: errorMessage,
            ref: undefined,
          };
          burnDagsD2TInputErrors.totalAmount = myCustomFieldError;

          setBurnDagsD2TError("totalAmount", {
            type: "manual",
            message: errorMessage || t("An unexpected error occurred"),
          });
        } else {
          toast.error(t("Something went wrong"));
        }
      }
    } catch (err: any) {
      setBurnDagsD2TError("totalAmount", {
        type: "manual",
        message: err?.message || t("An unexpected error occurred"),
      });
      if (!showGraphqlValidationErrors(t, err) && err instanceof Error) {
        toast.error(err?.message);
      }
    }
  });

  // calculate viewport dimensions
  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();
  return (
    <Section gutter={100} withSpace className={styles.wrap}>
      <Loader cover visibleOnFirstLoad={{ loading: meLoading }} />
      <Heading className={styles["main-title"]} gutter={8} level={4}>
        {t("DAGS Community")}
      </Heading>
      <div className={styles["main-content"]}>
        <div className={styles["src-content-container"]}>
          <div className={styles["text-container"]}>
            <p>
              An area for community members where the features and benefits they unlocked with their membership packages
              are being brought together. This includes both existing and upcoming.
            </p>
            <p>
              Additionally, who has not transferred their dags to their personal wallet will be able to access their
              balances in this section. There is no more need to migrate to Grow, as the holdings will be accessible
              from here. For ease of use, separate website for Grow will not be functional any longer. The link to
              crypto card can also be found here shortly.
            </p>
            <p>
              In case you feel you did not get enough value out of the membership and courses, you will have the
              opportunity to convert your received bonus coins to store credit for the amount of your membership and opt
              for another product or gift card.
            </p>
          </div>
          <Panel className={styles["scr-wrap"]} contentClassName={styles["wrap-content"]}>
            <Panel
              className={styles["scr-panel"]}
              contentClassName={styles["scr-content"]}
              panelStyle="TERTIARY"
              width={0}
            >
              <IconLogoSuccessFactoryNoText className={styles["scr-logo"]} width={41} height={50} />
              <span className={styles["scr-credit"]}>
                {me?.viewer ? (
                  <Price price={me.viewer.scrCreditBalance} currency={"EUR"} hideSign />
                ) : (
                  // empty placeholder to prevent content 'jumping' when data hasn't loaded
                  <div style={{ height: "37px" }} />
                )}
              </span>
              <p className={styles["scr-label"]}>{t("SCR")}</p>
              <div className={styles["scr-button"]} />
            </Panel>
          </Panel>
        </div>
        <Separator type="PRIMARY" />
        <h3 className={styles.name}>
          <strong>{t("Total DAGS burned by community")}:</strong>{" "}
          {formatNumber(parseFloat(totalDagsQuery?.getTotalBurned?.totalDags ?? "0"))} DAGS
        </h3>
        <Separator type="PRIMARY" />
        <p className="balance">
          <strong>Your DAGS Balance:</strong> {me?.viewer?.coinAccountBalance} DAGS
        </p>
        <div className={styles["grid-container"]}>
          <div className={styles["action"]}>
            <Button
              className={styles["access-product-link"]}
              borderRadius="MEDIUM"
              disabled
              color={Color.DARK_BLUE}
              fontSize={14}
            >
              <span className={styles["link-label"]}>{t("Deposit DAGS")}</span>
            </Button>
            <p>
              <span className={styles["action-text"]}>Functionality in progress..</span>
            </p>
          </div>
          <div className={styles["separator"]}>
            <div className={styles["separator-line"]} />
          </div>
          <div className={styles["action-right"]}>
            <Button
              className={styles["access-product-link"]}
              borderRadius="MEDIUM"
              disabled
              color={Color.DARK_BLUE}
              fontSize={14}
            >
              <span className={styles["link-label"]}>{t("Withdraw DAGS")}</span>
            </Button>
            <p>
              <span className={styles["action-text"]}>Functionality in progress..</span>
            </p>
          </div>
        </div>
        <Separator type="PRIMARY" />
        <h2>Early Adopters Burn Incentive</h2>
        <ul>
          <li className={styles["dag-wrap"]}>
            <div className={styles["dag-section"]}>
              <div>
                <h3 className={styles.name}>Burn DAGS and receive Shop Credits (SCR)</h3>
              </div>
            </div>

            <Layout className={styles["dag-data"]}>
              <DataFraction
                className={styles["data-fraction"]}
                icon={undefined}
                label={t("DAGS You can burn")}
                value={`${getDagsAmount()} ${t("DAGS")}`}
              />

              <DataFraction
                className={styles["data-fraction"]}
                icon={undefined}
                label={t("You receive")}
                value={`${getScrAmount()} ${t("SCR")}`}
              />

              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
                onClick={() => {
                  setIsBurnMode(true);
                }}
              >
                <span className={styles["link-label"]}>{t("Go to Burn")}</span>
              </Button>
            </Layout>
          </li>
        </ul>

        {me?.me?.dagsInfo?.burned &&
          me?.me?.dagsInfo?.burned?.map((burned, index) => (
            <div key={`${index}-term`} className={styles["term-grid"]}>
              <div>
                <strong>Burned DAGS:</strong> {burned.dags}
              </div>
              <div>
                <strong>SCR: </strong>
                {parseFloat(burned.scr).toFixed(2)}
              </div>
              <div>
                <DateTime value={burned.createdAt} />
              </div>
            </div>
          ))}
        <Separator type="PRIMARY" />
        {/* <h2>Load your broker account from DAGS bonus</h2> */}
        <h2>DAGS x TAG Markets Collaboration – Amplify Your Trading Power!</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              t(
                `We’re excited to introduce a powerful new opportunity with TAG Markets! Their Amplify 12x account lets traders maximize capital — boosting deposits 12X with no restrictions except a 10% drawdown limit — a perfect alternative to prop firms without the extra costs.<br/><br/><strong>Here's the Deal</strong>:<br/>Now, you can <strong>cover 50% of your deposit with DAGS</strong>! Simply burn bonus DAGS from your membership, and you’ll receive up to your full membership value in <strong>D2T (DAGS to TAG) promotional balance</strong> to use as part of your deposit — <strong>50% from DAGS, 50% in fiat/crypto</strong>.<br/><br/><p>1 D2T gives 1 USD deposit in TAG Markets.</p><br/><br/>Get started today and take your trading to the next level! <strong><a href="{{url}}" rel="noopener noreferrer" target="_blank">Open your account</a></strong>`,
              ),
              { url: "https://portal.tagmarkets.com/live_signup?brd=1&sidc=65B0216E-2894-48E9-A2A2-8011702982FD" },
            ),
          }}
        />
        <p>
          <Button
            onClick={() => setIsReadMoreOpen(true)}
            className={styles["access-product-link"]}
            borderRadius="MEDIUM"
            color={Color.DARK_BLUE}
            fontSize={16}
            width={300}
          >
            <span className={styles["link-label"]}>{t("How it works - see the steps!")}</span>
          </Button>
        </p>
        <ul>
          <li className={styles["dag-wrap"]}>
            <div className={styles["dag-section"]}>
              <div>
                <h3 className={styles.name}>Burn DAGS and receive D2T</h3>
                <span style={{ color: "#d87333", fontSize: "12px" }}>Membership Burn</span>
                <br />
                <br />
                <span style={{ color: "#d87333", fontSize: "12px" }}>
                  *Special burn rate limited to your membership quantity
                </span>
              </div>
            </div>

            <Layout className={styles["dag-data"]}>
              <DataFraction
                className={styles["data-fraction"]}
                icon={undefined}
                label={t("DAGS You can burn")}
                value={`${getDagsD2TAmount()} ${t("DAGS")}`}
              />

              <DataFraction
                className={styles["data-fraction"]}
                icon={undefined}
                label={t("You receive")}
                value={`${getScrD2TAmount()} ${t("D2T")}`}
              />

              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
                onClick={() => {
                  setIsBurnD2TMode(true);
                }}
              >
                <span className={styles["link-label"]}>{t("Go to Burn")}</span>
              </Button>
            </Layout>
          </li>
          <li className={styles["dag-wrap"]}>
            <div className={styles["dag-section"]}>
              <div>
                <h3 className={styles.name}>Burn DAGS and receive D2T</h3>
                <span style={{ color: "#d87333", fontSize: "12px" }}>Community Burn</span>
                <br />
                <br />
                <span style={{ color: "#d87333", fontSize: "12px" }}>
                  *Preferred burn rate for unlimited quantity. Deposit and burn as many as you want
                </span>
              </div>
            </div>

            <Layout className={styles["dag-data"]}>
              <DataFraction
                className={styles["data-fraction"]}
                icon={undefined}
                label={t("DAGS You can burn")}
                value={`${t("... DAGS")}`}
              />
              {totalCommunityDags && totalCommunityDags > 0 ? (
                <DataFraction
                  className={styles["data-fraction"]}
                  icon={undefined}
                  label={t("You receive")}
                  value={`${t("... D2T")}`}
                />
              ) : (
                <DataFraction
                  className={styles["data-fraction"]}
                  icon={undefined}
                  label={t("You receive")}
                  value={`${t("... D2T")}`}
                />
              )}

              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
                onClick={() => {
                  setIsCommunityBurnMode(true);
                }}
              >
                <span className={styles["link-label"]}>{t("Go to Burn")}</span>
              </Button>
            </Layout>
          </li>
        </ul>
        {me?.me?.meD2TInfo?.burned &&
          me?.me?.meD2TInfo?.burned?.map((burned, index) => (
            <div key={`${index}-term`} className={styles["term-grid"]}>
              <div>
                <strong>Burned DAGS:</strong> {burned.dags}
                <br />
                {burned.name}
                {burned.email && ` (${burned.email})`}
              </div>
              <div>
                <strong>D2T: </strong>
                {parseFloat(burned.scr).toFixed(2)}
              </div>
              <div>
                <DateTime value={burned.createdAt} />
              </div>
            </div>
          ))}
      </div>
      <Modal
        isOpen={isBurnMode}
        close={() => {
          setIsBurnMode(false); // Reset modal content on close
        }}
        kind={ModalKind.PRIMARY}
        panelProps={{
          imagePath: "/images/crowd-icon.svg",
          width: PanelWidth.SMALL,
          title: t("Burn DAGS and receive SCR"),
          caption: t("Burn DAGS and receive SCR"),
          label: t("Burn DAGS and receive SCR"),
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <div>
          {/* Burn DAGS Content */}
          <h2 style={{ textAlign: "center", marginTop: "30px" }}>{t("Burn DAGS and receive SCR")}</h2>
          <form onSubmit={handleBurnDagsFormSubmit}>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="number"
                className={styles.field}
                label={t("Total DAGS to Burn")}
                name="totalAmount"
                gutter={20}
                error={burnDagsInputErrors.totalAmount}
                internalRef={burnDags}
                value={amount}
                onChange={(e) => {
                  setAmount(e);
                }}
              />
            </div>
            <p>
              <strong>You receive SCR:</strong>{" "}
              {parseFloat(amount ?? "0") > 0
                ? (parseFloat(amount ?? "0") / parseFloat(me?.me?.dagsInfo?.ratio ?? "0")).toFixed(2)
                : "0"}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 0" }}>
              {["0", "25", "50", "75", "100"].map((percent) => (
                <button
                  type={"button"}
                  key={percent}
                  onClick={() => handlePercentage(percent)}
                  style={{
                    flex: 1,
                    margin: "0 5px",
                    padding: "10px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {percent}%
                </button>
              ))}
            </div>
            <div style={{ margin: "20px 0" }}>
              <input
                type="range"
                min="0"
                max={totalDags}
                value={amount}
                onChange={(e) => {
                  if (parseFloat(e.target.value) > totalDags) {
                    setAmount(totalDags.toString());
                  } else {
                    setAmount(parseFloat(e.target.value).toString());
                  }
                }}
                style={{ width: "100%" }}
              />
            </div>
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I acknowledge that the burn is final and can not be reversed.")}
              name="final"
              gutter={20}
              error={burnDagsInputErrors.final}
              internalRef={burnDags}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I confirm that I have reviewed all details, and I understand the terms of the DAGS burn.")}
              name="confirm"
              gutter={20}
              error={burnDagsInputErrors.confirm}
              internalRef={burnDags}
            />
            <div style={{ textAlign: "center", width: "100%", alignItems: "center" }}>
              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
              >
                <span className={styles["link-label"]}>{t("Confirm Burn")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isBurnD2TMode}
        close={() => {
          setIsBurnD2TMode(false);
        }}
        kind={ModalKind.PRIMARY}
        panelProps={{
          imagePath: "/images/crowd-icon.svg",
          width: PanelWidth.SMALL,
          title: t("DAGS Community"),
          caption: t("Burn DAGS and receive D2T"),
          label: t("DAGS Community"),
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <div>
          {/* Burn DAGS Content */}
          <h2 style={{ textAlign: "center", marginTop: "30px" }}>
            {t("Burn DAGS and send D2T balance to TAG Markets")}
          </h2>
          <form onSubmit={handleBurnDagsFormD2TSubmit}>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="text"
                className={styles.field}
                label={t("Full name registered in TAG Markets")}
                name="name"
                gutter={20}
                error={burnDagsD2TInputErrors.name}
                internalRef={burnDagsD2T}
                value={""}
                onChange={(e) => {
                  // setAmount(e);
                }}
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="text"
                className={styles.field}
                label={t("E-mail registered in TAG Markets")}
                name="email"
                gutter={20}
                error={burnDagsD2TInputErrors.email}
                internalRef={burnDagsD2T}
                value={""}
                onChange={(e) => {
                  // setAmount(e);
                }}
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="number"
                className={styles.field}
                label={t("Total DAGS to burn")}
                name="totalAmount"
                gutter={20}
                error={burnDagsD2TInputErrors.totalAmount}
                internalRef={burnDagsD2T}
                value={amountD2T}
                onChange={(e) => {
                  setAmountD2T(e);
                }}
              />
            </div>
            <p>
              <strong>You receive D2T (USD):</strong>{" "}
              {parseFloat(amountD2T ?? "0") > 0
                ? (parseFloat(amountD2T ?? "0") / parseFloat(me?.me?.meD2TInfo?.ratio ?? "0")).toFixed(2)
                : "0"}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 0" }}>
              {["0", "25", "50", "75", "100"].map((percent) => (
                <button
                  type={"button"}
                  key={percent}
                  onClick={() => handlePercentageD2T(percent)}
                  style={{
                    flex: 1,
                    margin: "0 5px",
                    padding: "10px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {percent}%
                </button>
              ))}
            </div>
            <div style={{ margin: "20px 0" }}>
              <input
                type="range"
                min="0"
                max={totalDagsD2T}
                value={amountD2T}
                onChange={(e) => {
                  if (parseFloat(e.target.value) > totalDagsD2T) {
                    setAmountD2T(totalDagsD2T.toString());
                  } else {
                    setAmountD2T(parseFloat(e.target.value).toString());
                  }
                }}
                style={{ width: "100%" }}
              />
            </div>
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I have already opened TAG Markets account.")}
              name="accountOpened"
              gutter={20}
              error={burnDagsD2TInputErrors.accountOpened}
              internalRef={burnDagsD2T}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I acknowledge that the burn and send is final and can not be reversed.")}
              name="acknowledgement"
              gutter={20}
              error={burnDagsD2TInputErrors.acknowledgement}
              internalRef={burnDagsD2T}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I acknowledge that the D2T balance can only be used for trading and is non-withdrawable.")}
              name="acknowledgementTrading"
              gutter={20}
              error={burnDagsD2TInputErrors.acknowledgementTrading}
              internalRef={burnDagsD2T}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t(
                "I confirm that I have reviewed all details, and I understand the terms of the DAGS burn and D2T balance conversion.",
              )}
              name="fconfirm"
              gutter={20}
              error={burnDagsD2TInputErrors.fconfirm}
              internalRef={burnDagsD2T}
            />
            <div style={{ textAlign: "center", width: "100%", alignItems: "center" }}>
              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
                width={200}
              >
                <span className={styles["link-label"]}>{t("Burn DAGS and Send D2T Balance")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isCommunityBurnMode}
        close={() => {
          setIsCommunityBurnMode(false);
        }}
        kind={ModalKind.PRIMARY}
        panelProps={{
          imagePath: "/images/crowd-icon.svg",
          width: PanelWidth.SMALL,
          title: t("DAGS Community"),
          caption: t("Burn DAGS and receive D2T"),
          label: t("DAGS Community"),
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <div>
          {/* Burn DAGS Content */}
          <h2 style={{ textAlign: "center", marginTop: "30px" }}>
            {t("Burn DAGS and send D2T balance to TAG Markets")}
          </h2>
          <form onSubmit={handleCommunityBurnDagsFormSubmit}>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="text"
                className={styles.field}
                label={t("Full name registered in TAG Markets")}
                name="name"
                gutter={20}
                error={communityBurnDagsInputErrors.name}
                internalRef={communityBurnDags}
                value={""}
                onChange={(e) => {
                  // setAmount(e);
                }}
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="text"
                className={styles.field}
                label={t("E-mail registered in TAG Markets")}
                name="email"
                gutter={20}
                error={communityBurnDagsInputErrors.email}
                internalRef={communityBurnDags}
                value={""}
                onChange={(e) => {
                  // setAmount(e);
                }}
              />
            </div>
            <div style={{ margin: "20px 0" }}>
              <Field
                type="number"
                className={styles.field}
                label={t("Total DAGS to burn")}
                name="totalAmount"
                gutter={20}
                error={communityBurnDagsInputErrors.totalAmount}
                internalRef={communityBurnDags}
                value={communityAmount}
                onChange={(e) => {
                  if (parseFloat(e) > totalCommunityDags) {
                    setCommunityAmount(totalCommunityDags.toString());
                  } else {
                    setCommunityAmount(e);
                  }
                }}
              />
            </div>
            <p>
              <strong>You receive D2T (USD):</strong>{" "}
              {parseFloat(communityAmount ?? "0") > 0
                ? (parseFloat(communityAmount ?? "0") / (totalDagsQuery?.getTotalBurned?.rate ?? 66.6667)).toFixed(2)
                : "0"}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 0" }}>
              {["0", "25", "50", "75", "100"].map((percent) => (
                <button
                  type={"button"}
                  key={percent}
                  onClick={() => handleCommunityPercentageD2T(percent)}
                  style={{
                    flex: 1,
                    margin: "0 5px",
                    padding: "10px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {percent}%
                </button>
              ))}
            </div>
            <div style={{ margin: "20px 0" }}>
              <input
                type="range"
                min="0"
                max={totalCommunityDags}
                value={communityAmount}
                onChange={(e) => {
                  if (parseFloat(e.target.value) > totalCommunityDags) {
                    setCommunityAmount(totalCommunityDags.toString());
                  } else {
                    setCommunityAmount(parseFloat(e.target.value).toString());
                  }
                }}
                style={{ width: "100%" }}
              />
            </div>
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I have already opened TAG Markets account.")}
              name="accountOpened"
              gutter={20}
              error={communityBurnDagsInputErrors.accountOpened}
              internalRef={communityBurnDags}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I acknowledge that the burn and send is final and can not be reversed.")}
              name="acknowledgement"
              gutter={20}
              error={communityBurnDagsInputErrors.acknowledgement}
              internalRef={communityBurnDags}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t("I acknowledge that the D2T balance can only be used for trading and is non-withdrawable.")}
              name="acknowledgementTrading"
              gutter={20}
              error={communityBurnDagsInputErrors.acknowledgementTrading}
              internalRef={communityBurnDags}
            />
            <Field
              type="checkbox"
              className={styles.field}
              label={t(
                "I confirm that I have reviewed all details, and I understand the terms of the DAGS burn and D2T balance conversion.",
              )}
              name="fconfirm"
              gutter={20}
              error={communityBurnDagsInputErrors.fconfirm}
              internalRef={communityBurnDags}
            />
            <div style={{ textAlign: "center", width: "100%", alignItems: "center" }}>
              <Button
                className={styles["access-product-link"]}
                borderRadius="MEDIUM"
                color={Color.DARK_BLUE}
                fontSize={14}
                width={200}
              >
                <span className={styles["link-label"]}>{t("Burn DAGS and Send D2T Balance")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={isReadMoreOpen}
        close={() => {
          setIsReadMoreOpen(false);
        }}
        kind={ModalKind.PRIMARY}
        panelProps={{
          imagePath: "/images/crowd-icon.svg",
          width: PanelWidth.SMALL,
          title: t("DAGS Community"),
          caption: t("Burn DAGS and receive D2T"),
          label: t("DAGS Community"),
          titleClassName: styles["modal-title"],
          captionClassName: styles["modal-caption"],
        }}
      >
        <div style={{ padding: "20px" }}>
          <h2 style={{ textAlign: "center", marginTop: "30px", marginBottom: "30px" }}>{t("What is TAG Markets?")}</h2>
          <p style={{ marginBottom: "20px" }}>
            TAG Markets is a regulated and transparent brokerage offering zero deposit and withdrawal fees, fast
            withdrawals, and a rapidly growing user base.
          </p>
          <h3>{t("How to Open Your Account and Make a Deposit:")}</h3>
          <ol>
            <li>
              {t("Open your Amplify 12X account with TAG Markets using this link:")}{" "}
              <a
                href="https://portal.tagmarkets.com/live_signup?brd=1&sidc=65B0216E-2894-48E9-A2A2-8011702982FD"
                target="_blank"
                rel="noreferrer"
              >
                {t("Sign up here")}
              </a>
            </li>
            <li style={{ marginTop: "20px" }}>
              {t(
                "Burn your DAGS and receive D2T promotional balance. Each D2T = $1 in deposit value for your brokerage account.",
              )}
              <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                Before you burn please check in the 12x Funded section in TAG Markets for the different account options
                (which total deposit you want to do) so you know how much USD deposit and how much D2T deposit you need
                for the account you wish to open.
              </p>
            </li>
            <li>
              {t("Go to Burn:")}
              <ul style={{ listStyleType: "none" }}>
                <li>{t("Enter your full name and email used for TAG Markets registration.")}</li>
                <li>{t("Choose the amount of DAGS to burn.")}</li>
                <li>
                  {t(
                    "Send the received D2T balance to TAG Markets. This balance will cover max 50% of your total deposit.",
                  )}
                </li>
              </ul>
            </li>
            <li>
              {t("Complete your deposit:")}
              <br />
              {t("Make the remaining 50% deposit to your TAG Markets account via card, crypto, or wire transfer.")}
            </li>
            <li style={{ marginTop: "20px" }}>
              {t("Request for your 12x account:")}
              <br />
              {t(
                "Once both deposits are done, contact TAG Markets support and request for the opening of a 12x account. Mention that you have made 50% deposit in Dags and request that to be credited to the 12x account as well. For example:",
              )}
              <br />
              <br />
              {t(`
                "Hello, I have made 50% deposit in Dags and 50% in fiat/USDT. I would like to open 12x account and have both of the deposits credited to that account. Can you help me with these steps, please?"
              `)}
              <br />
              <br />
              <p>
                You can contact their support also via Whatsapp. They will ask you for some details to open the 12x
                account for you. Provide them with:
              </p>{" "}
              <ol>
                <li>Your full name (your legal name, not any username)</li>
                <li>Email address you used to register in TAG Markets</li>
                <li>USD amount you deposited in TAG Markets (via crypto or card or wire)</li>
                <li>
                  D2T Balance you sent to TAG Markets (as a result of DAGS burn you automatically send D2T Balance
                  amount to TAG Markets)
                </li>
                <li>
                  Mention the total deposit amount you wish to open a 12x account with (USD deposit + D2T amounts
                  combined)
                </li>
              </ol>
              <br />
            </li>
            <li>
              {t("Start trading!")}
              <br />
              {t(
                "Once your 12x account is opened and fully funded, you are free to trade on your own, follow some strategies or even copy other experts - exactly how you wish.",
              )}
            </li>
          </ol>
          <div style={{ marginLeft: "40px" }}>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              PS: In case you a looking for an automatic solution, there is a good strategy that has joined TAG Market’s
              social trading area. No extra charge, just subscribe, activate and system copies the trades automatically.
            </p>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              <a
                href="https://social.tagmarkets.com/portal/registration/subscription/83338/IndexTrader"
                rel="noopener noreferrer"
                target="_blank"
              >
                The link to copy the strategy
              </a>
            </p>
            <p style={{ marginTop: "10px", marginBottom: "10px" }}>
              <a
                href="https://intercom.help/tag-markets/en/articles/10397021-how-to-link-your-account-to-a-private-copy"
                rel="noopener noreferrer"
                target="_blank"
              >
                An explanation how to link to a copy
              </a>
            </p>
            <p style={{ marginTop: "10px", marginBottom: "25px" }}>
              Trading account ID and password can be found in the email you received about your 12x account opening, or
              in the Account Summary section
            </p>
          </div>
          <h3>{t("Key rules for the Amplify 12X account:")}</h3>
          <ul style={{ marginLeft: "40px" }}>
            <li>{t("Minimum deposit is 50 USD - can be 25 from D2T balance and 25 from USD")}</li>
            <li>{t("Balance from D2T can be used for trading only")}</li>
            <li>{t("Initial deposit (via card/crypto/wire) can be withdrawn after 30 days")}</li>
            <li>{t("Withdrawing of initial deposit will result in an equal deduction from the D2T deposit")}</li>
            <li>{t("Trading profits can be withdrawn immediately")}</li>
            <li>{t("For full details, please review the terms of TAG Markets")}</li>
          </ul>
        </div>
      </Modal>
    </Section>
  );
};
