import { useEffect, useState } from "react";

import { PaymentFlow } from "../components/payment-result/PaymentResult";
import { useStripeConfirmCardPayment } from "../components/stripe-credit-card-form/StripeCreditCardForm";
import { useStripeIdealPayment } from "../components/stripe-ideal-form/StripeIdealForm";
import {
  PaymentEntityType,
  PaymentMethod,
  useCreatePaymentMutation,
  PaymentStatusQuery,
  usePaymentStatusQuery,
  PaymentStatus,
  CurrencyCode,
  PaymentUnit,
} from "../graphql/schema";
import { useRegisterPaymentSource } from "./registerStripePaymentSource";

export interface SelectedPaymentSource {
  paymentMethod: PaymentMethod;
  id: string | null;
}

export type PaymentStatusResponse = NonNullable<PaymentStatusQuery["payments"]["fetch"]>;
export interface HandlePaymentsProps {
  totalPrice: string | undefined;
  paymentVariables: {
    entityType: PaymentEntityType;
    currencyCode: keyof typeof CurrencyCode;
  };
  creditCardHolderName?: string | undefined;
  selectedPaymentSource: SelectedPaymentSource | null;
  errorMessage: string;
  entityId?: string;
  paymentFlow?: PaymentFlow;
  setPaymentId?: (id: string) => void;
  isIDealBankSelected?: boolean;
}

export interface HandlePaymentStatusHandlers {
  selectedPaymentMethod?: { id: string | null; type: PaymentMethod };
  onSuccess?: () => void;
  onError?: () => void;
  onUnknownResponse?: () => void;
  useCardForLaterUse?: boolean;
}

export function useHandelPayment({
  onError,
  onSuccess,
  onUnknownResponse,
  selectedPaymentMethod,
  useCardForLaterUse,
}: HandlePaymentStatusHandlers) {
  const [createPayment] = useCreatePaymentMutation();
  const confirmCardPayment = useStripeConfirmCardPayment();
  const confirmIdealPayment = useStripeIdealPayment();
  const [registerPaymentSource] = useRegisterPaymentSource();
  const [transactionId, setTransactionId] = useState("");
  const [pollingDisabled, setPollingDisabled] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [paymentSignature, setPaymentSignature] = useState<string>();

  const { data, startPolling, stopPolling } = usePaymentStatusQuery({
    variables: { transactionId, paymentId },
    skip: !transactionId && !paymentId,
    fetchPolicy: "network-only",
  });

  const paymentResult = data?.payments.fetch;
  const paymentStatus = paymentResult?.status ?? undefined;

  // stop polling on un-mount
  useEffect(() => {
    return stopPolling;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // listen payment status changes
  useEffect(() => {
    if (paymentResult === undefined || paymentStatus === undefined || pollingDisabled) {
      return;
    }

    // return payment result response
    if (paymentResult === null && onUnknownResponse) {
      setPollingDisabled(true);
      onUnknownResponse();
      stopPolling();
    }
    if (paymentResult?.status === PaymentStatus.SUCCESS && onSuccess) {
      setPollingDisabled(true);
      stopPolling();
      // If saving card is available
      if (
        paymentSignature &&
        selectedPaymentMethod &&
        selectedPaymentMethod.type === PaymentMethod.BANK_CARD &&
        useCardForLaterUse
      ) {
        registerPaymentSource({ signature: paymentSignature }).then(() => onSuccess());
      } else {
        onSuccess();
      }
    } else if (paymentResult?.status === PaymentStatus.INVALID && onError) {
      setPollingDisabled(true);
      stopPolling();

      onError();
    }
  }, [
    paymentResult,
    paymentStatus,
    stopPolling,
    onSuccess,
    onError,
    paymentSignature,
    registerPaymentSource,
    onUnknownResponse,
    selectedPaymentMethod,
    pollingDisabled,
    useCardForLaterUse,
  ]);

  return async (props: HandlePaymentsProps) => {
    // Reset PaymentSignature
    setPaymentSignature(undefined);

    const method = props.selectedPaymentSource?.paymentMethod;

    if (!props.totalPrice || !method) {
      throw new Error(props.errorMessage);
    }

    // Create Payment, entityId can be null, if so card was used that is not in our db
    const paymentRes = await createPayment({
      variables: {
        entityType: props.paymentVariables.entityType,
        selectedSourceId: props.selectedPaymentSource?.id || null,
        totalAmount: parseFloat(props.totalPrice),
        unit: PaymentUnit[props.paymentVariables.currencyCode],
        entityId: props.entityId ? parseFloat(props.entityId) : undefined,
        method,
        returnUrl: `${process.env.REACT_APP_STRIPE_IDEAL_RETURN_URL}/${props.paymentFlow}/{ID}`,
      },
    });

    if (!paymentRes.data?.payments.create.id) {
      throw new Error(props.errorMessage);
    }

    setPaymentId(paymentRes.data.payments.create.id);
    if (props.setPaymentId) {
      props.setPaymentId(paymentRes.data.payments.create.id);
    }

    if (paymentRes.data.payments.create.signature) {
      setPaymentSignature(paymentRes.data.payments.create.signature);
    }
    if (!props.selectedPaymentSource?.paymentMethod) {
      console.error("props.selectedPaymentSource.paymentMethod is missing", props.selectedPaymentSource);
      throw new Error("Something went wrong");
    }

    // Add handler to handle all different paymentMethods.
    switch (props.selectedPaymentSource.paymentMethod) {
      case PaymentMethod.COIN_ACCOUNT:
      case PaymentMethod.SHOP_CREDIT:
      case PaymentMethod.SCR:
        // no further actions needed
        break;

      case PaymentMethod.BANK_CARD:
        // Handle Stripe card payment
        if (!paymentRes.data?.payments.create.signature) {
          throw new Error(props.errorMessage);
        }

        try {
          const stripeRes = await confirmCardPayment({
            creditCardHolderName: props.creditCardHolderName,
            paymentIntentClientSecret: paymentRes.data.payments.create.signature,
          });

          if (!stripeRes?.id) {
            throw new Error(props.errorMessage);
          }

          setTransactionId(stripeRes?.id);
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(err.message);
          }
        }

        break;

      case PaymentMethod.IDEAL:
        // Handle IDEAL payment method
        if (!paymentRes.data?.payments.create.signature || !props.paymentFlow) {
          console.error("Payment signature or payment flow is missing");
          throw new Error(props.errorMessage);
        }

        try {
          await confirmIdealPayment({
            paymentIntentClientSecret: paymentRes.data.payments.create.signature,
            paymentId: paymentRes.data.payments.create.id,
            paymentSourceHolderName: props.creditCardHolderName,
            paymentFlow: props.paymentFlow,
            isIdealBankCardSet: props.isIDealBankSelected || false,
          });
        } catch (err) {
          if (err instanceof Error) {
            throw new Error(err.message);
          }
        }
        break;

      case PaymentMethod.ADV_CASH:
      case PaymentMethod.PERFECT_MONEY:
        const url = paymentRes.data.payments.create.initiationUrl;
        const data = paymentRes.data.payments.create.initiationForm;

        if (!url || !data || data.length === 0) {
          console.error(`${props.selectedPaymentSource.paymentMethod} initiation url or form data is missing`);
          throw new Error("something went wrong");
        }

        postFormData(url, data);

        break;

      default:
        // case PaymentMethod.PAYPAL:
        // case PaymentMethod.DAGPAY:
        // case PaymentMethod.PAYCEK:
        if (!paymentRes.data.payments.create.initiationUrl) {
          console.error(`${props.selectedPaymentSource.paymentMethod} initiation url is missing`);
          throw new Error("something went wrong");
        }

        window.location.href = paymentRes.data.payments.create.initiationUrl;
        break;
    }

    //start polling payment status.
    setPollingDisabled(false);
    startPolling(500);

    return paymentRes;
  };
}

function postFormData(url: string, data: Array<{ key: string; value: string }>) {
  // create form element
  const formElem = document.createElement("form");
  formElem.method = "POST";
  formElem.style.display = "none";

  formElem.action = url;

  // add input data
  data.forEach(({ key, value }) => {
    const inputElem = document.createElement("input");
    inputElem.type = "hidden";
    inputElem.name = key;
    inputElem.value = value;

    formElem.appendChild(inputElem);
  });

  // add form to dom...
  document.body.appendChild(formElem);

  // ... and submit it
  formElem.submit();
}
