import { FC, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Button, ButtonIconGutter } from "../button/Button";
import { IconCloud } from "../icon/IconCloud";
import { IconGiftCard } from "../icon/IconGiftCard";
import { Layout } from "../layout/Layout";
import { PaymentMethodPanel } from "../payment-method-panel/PaymentMethodPanel";
import { RedemptionForm } from "./redemption-form/RedemptionForm";
import { Modal, ModalKind } from "../modal/Modal";
import { RedemptionConfirmation } from "./redemption-confirmation/RedemptionConfirmation";
import { PanelWidth } from "../panel/Panel";

import styles from "./gift-card-history-panel.module.scss";

export interface GiftCard {
  id: string;
  active: boolean;
  amount: string;
  code: string;
  currency: { sign: string };
  usedBy?: string | null;
  type: string;
}

export enum GiftCardHistoryPanelKind {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export interface GiftCardHistoryPanelProps {
  className?: string;
  card: GiftCard;
  redeemGiftCard: (code: string) => void;
}

export const GiftCardHistoryPanel: FC<GiftCardHistoryPanelProps> = ({ card, redeemGiftCard }) => {
  // access translation keys
  const { t } = useTranslation();

  const kind = card.active ? "PRIMARY" : "SECONDARY";

  const [isModalVisible, setIsModalVisible] = useState(false);

  // handle gift card redemption progress
  const [redemptionStatus, setRedemptionStatus] = useState(0);

  const value = `${card.amount}`; //${card.currency.sign}

  // reset redemption status when modal is closed
  const closeModal = () => {
    setRedemptionStatus(0);
    setIsModalVisible(false);
  };

  const handleRedeemGiftCard = async () => {
    closeModal();
    await redeemGiftCard(card.code);
  };

  const redemptionSteps = [
    {
      id: 0,
      title: t(`${value} Gift card`),
    },
    {
      id: 1,
      title: t(`Redeem ${value} gift card?`),
    },
  ];

  return (
    <>
      <PaymentMethodPanel className={styles.wrap}>
        <Layout>
          <IconGiftCard
            ribbonClassName={classNames({ [styles.ribbon]: kind !== "SECONDARY" })}
            disabled={kind === "SECONDARY"}
          />
          <span className={classNames(styles.title, (styles as any)[`title--${kind}`])}>{`${t(card.type)} ${t(
            "Gift card",
          )}`}</span>
          <span>| {value}</span>
        </Layout>
        <Layout>
          <Button
            kind="TEXT"
            height="SMALL"
            weight="BOLD"
            color="YELLOW"
            iconLeft={<IconCloud width={20} height={15} fill="#FFC542" />}
            iconGutter={ButtonIconGutter.SMALL}
            onClick={() => window.open(`${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/pdf/gift-card/${card.id}`)}
          >
            {t("Save as pdf")}
          </Button>
          <Button
            className={styles["details-button"]}
            shape="ROUND"
            kind="SOLID"
            height="SMALL"
            color="YELLOW"
            onClick={() => setIsModalVisible(true)}
          >
            {t("Details")}
          </Button>
        </Layout>
      </PaymentMethodPanel>

      <Modal
        kind={ModalKind.SECONDARY}
        panelProps={{
          imagePath: redemptionStatus === 0 ? "/images/topUpModalIllustration.png" : "/images/topUpIllustration.png",
          width: PanelWidth.SMALL,
          title: redemptionSteps[redemptionStatus].title,
          caption:
            redemptionStatus === 1 && t("Are you sure you want to redeem this gift card to your Shop Credit account?"),
          captionClassName: styles["panel-caption"],
        }}
        isOpen={isModalVisible}
        close={() => closeModal()}
      >
        {redemptionStatus === 0 && (
          <RedemptionForm
            id={card.id}
            status={card.active ? "ACTIVE" : "INACTIVE"}
            code={card.code}
            cardUser={card.usedBy}
            onUse={() => setRedemptionStatus(1)}
            onClose={() => closeModal()}
          />
        )}
        {redemptionStatus === 1 && (
          <RedemptionConfirmation onClose={() => setRedemptionStatus(0)} redeemGiftCard={handleRedeemGiftCard} />
        )}
      </Modal>
    </>
  );
};
