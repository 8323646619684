import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { stringify } from "querystring";

import { NewsItem, useNewsListViewLazyQuery, useNewsListViewQuery } from "../../../graphql/schema";
import { Section } from "../../../components/section/Section";
import { Loader } from "../../../components/loader/Loader";
import NotFound from "../../../components/not-found/NotFound";
import DateTime from "../../../components/date-time/DateTime";

import styles from "./news-list-view.module.scss";

const ITEMS_PER_PAGE = 20;

export const NewsListView: React.FC = () => {
  // accestt translation keys
  const [t] = useTranslation();
  const [showNews, setShowNews] = React.useState<{ [key: number]: boolean }>({ 0: false });

  // number of invoices loaded on start

  // fetch invoice query
  const [loadNews, { loading, error, data, previousData, fetchMore }] = useNewsListViewLazyQuery({
    variables: { offset: 0, limit: ITEMS_PER_PAGE, filter: stringify({ category: "SHOP" }) },
  });

  useEffect(() => {
    loadNews();
  }, []);

  const newsList: Array<NewsItem> | [] = data?.memberAreaNews?.data || [];
  const hasNews = loading || newsList.length > 0;
  const totalRows = parseInt(data?.memberAreaNews?.totalRows || "0");
  const totalNews = newsList.length;

  if (error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  if (!hasNews) {
    return <NotFound>{t("No news added yet")}</NotFound>;
  }

  return (
    <Section gutter={100} withSpace className={styles.wrap}>
      <Loader cover visibleOnFirstLoad={{ loading, previousData, data }} />

      <InfiniteScroll
        dataLength={newsList.length}
        next={() => {
          fetchMore({
            variables: { offset: newsList.length },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                memberAreaNews: {
                  data: [...(prev?.memberAreaNews?.data ?? []), ...(fetchMoreResult?.memberAreaNews?.data ?? [])],
                  totalRows: prev?.memberAreaNews?.totalRows,
                },
              });
            },
          });
        }}
        hasMore={!!data && totalNews < totalRows}
        loader={<div>Loading...</div>}
      >
        {newsList.map((news) => (
          <div key={news.id} className={styles["term-grid"]}>
            <div onClick={() => setShowNews({ ...showNews, [news.id]: !showNews[news.id] })}>
              <h2 className={styles.title}>{news.title}</h2>
            </div>

            <div>
              <DateTime value={news.publishedDate} />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  news?.content?.replace(
                    /\<img([^>]*)\ssrc=('|")([^>]*)\2\s([^>]*)\/\>/gi,
                    "<img$1 src=$2https://office.sfsuite.com$3$2 $4/>",
                  ) ?? "",
              }}
              style={{ display: `${showNews[news.id] === true ? "block" : "none"}` }}
            />
          </div>
        ))}
      </InfiniteScroll>
    </Section>
  );
};
