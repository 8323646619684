import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";

import { BankCardOption } from "../bank-card-option/BankCardOption";
import { getBankCardNumber } from "../../services/getBankCardNumber";
import {
  PaymentMethod,
  usePaymentMethodsListQuery,
  PaymentSourceCreditCard,
  PaymentSourceShopCredit,
  CurrencyCode,
  PaymentSourceScrCredit,
} from "../../graphql/schema";
import { StripeCreditCardForm } from "../stripe-credit-card-form/StripeCreditCardForm";
import { IDealForm } from "../stripe-ideal-form/StripeIdealForm";
import { TopUpModal } from "../top-up-modal/TopUpModal";
import usePrevious from "../../hooks/usePrevious";
import { Radio } from "../radio/Radio";
import { Layout } from "../layout/Layout";
import { Button } from "../button/Button";
import { IconArrow } from "../icon/IconArrow";
import { Link } from "../link/Link";
import { Gutters } from "../../services/constants";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { Checkbox } from "../checkbox/Checkbox";
import { CurrencySign } from "../price/Price";

import styles from "./payment-methods-list.module.scss";

export const PAYMENT_METHODS_ORDER: PaymentMethod[] = [
  PaymentMethod.BANK_CARD,
  PaymentMethod.SHOP_CREDIT,
  PaymentMethod.SCR,
  PaymentMethod.PAYPAL,
  PaymentMethod.IDEAL,
  PaymentMethod.PERFECT_MONEY,
  PaymentMethod.ADV_CASH,
];

export const ADD_NEW_BANK_CARD_ID = "bank-card:-1";

export interface PaymentMethodsListProps<T = PaymentMethod> {
  selectedPaymentMethod: T;
  selectedPaymentMethodSourceId: string | null;
  onPaymentMethodChange: (method: T) => void;
  onPaymentMethodSourceIdChange: (id: string | null) => void;
  onOwnerNameChange: (name: string) => void;
  amount: number | undefined;
  minAmount?: number | undefined;
  maxAmount?: number | undefined;
  currencyCode: keyof typeof CurrencyCode;
  gutter?: keyof typeof Gutters;
  onErrorStateChange?: (data: { hasLimitError: boolean; min: number; max: number; sign: string }) => void;
  topUpPath?: string;
  disabled?: boolean;
  className?: string;
  disabledPaymentMethods?: PaymentMethod[];
  getSaveForLaterUseStatus?: (status: boolean) => void;
  handleIsIdealBankSelected?: (e: boolean) => void;
  hideSCR?: boolean;
  hideShopCredit?: boolean;
  defaultSCR?: boolean;
}

export const PaymentMethodsList: FC<PaymentMethodsListProps> = ({
  onOwnerNameChange,
  selectedPaymentMethod,
  selectedPaymentMethodSourceId,
  onPaymentMethodChange,
  onPaymentMethodSourceIdChange,
  topUpPath,
  gutter,
  onErrorStateChange,
  amount,
  minAmount = 0,
  maxAmount = Infinity,
  currencyCode,
  className,
  disabled,
  disabledPaymentMethods = [],
  getSaveForLaterUseStatus,
  handleIsIdealBankSelected,
  hideSCR = false,
  hideShopCredit = false,
  defaultSCR = false,
}) => {
  const [saveForLaterUse, setSaveForLaterUse] = useState(false);
  const { t } = useTranslation();
  const { data, loading, refetch } = usePaymentMethodsListQuery();
  const previousSelectedPaymentMethodId = usePrevious(selectedPaymentMethod);

  const { isMobile } = useWindowDimensionsContext();

  const isDataLoaded = !loading && data;
  const paymentMethods = (data?.me.paymentMethods ?? [])
    // still allow shop credit if payment methods are disabled
    .filter((m) => (disabled ? m.method === PaymentMethod.SHOP_CREDIT : filterIsEnabled(m)))
    .filter((m) => (hideSCR ? m.method !== PaymentMethod.SCR : filterIsEnabled(m)))
    .filter((m) => (hideShopCredit ? m.method !== PaymentMethod.SHOP_CREDIT : filterIsEnabled(m)))
    .sort(sortByPaymentMethodsOrder);

  const bankCardsList = (paymentMethods.find((c) => c.method === PaymentMethod.BANK_CARD)?.sources ?? []).map(
    ({ source }) => source,
  ) as { id: string; info?: PaymentSourceCreditCard }[];
  const selectedMethodLimits = paymentMethods.find(({ method }) => method === selectedPaymentMethod)?.limits;

  // change selected method if not in payment methods list
  useEffect(() => {
    if (!paymentMethods.map((m) => m.method).includes(selectedPaymentMethod)) {
      const newMethod = paymentMethods[0];

      if (newMethod && newMethod.method !== selectedPaymentMethod) {
        onPaymentMethodChange(newMethod.method);
      }
    }
  }, [disabled, onPaymentMethodChange, paymentMethods, selectedPaymentMethod]);

  // update paymentMethodSourceId if method changes
  useEffect(() => {
    // ignore if current method has source selected
    if (selectedPaymentMethod === previousSelectedPaymentMethodId && selectedPaymentMethodSourceId) {
      return;
    }

    const sources = paymentMethods.find(({ method }) => method === selectedPaymentMethod)?.sources ?? [];
    const firstRelatedSourceId = sources[0]?.source.id ?? null;

    // if no previous bank cards are stored, make "add new bank card" form active
    if (isDataLoaded && !firstRelatedSourceId && selectedPaymentMethod === PaymentMethod.BANK_CARD) {
      onPaymentMethodSourceIdChange(ADD_NEW_BANK_CARD_ID);

      return;
    }

    // activate first source under active payment method, or reset source id if nothing is available for this method
    onPaymentMethodSourceIdChange(firstRelatedSourceId);
  }, [
    isDataLoaded,
    onPaymentMethodSourceIdChange,
    paymentMethods,
    previousSelectedPaymentMethodId,
    selectedPaymentMethod,
    selectedPaymentMethodSourceId,
  ]);

  useEffect(() => {
    if (getSaveForLaterUseStatus) {
      getSaveForLaterUseStatus(saveForLaterUse);
    }
  }, [getSaveForLaterUseStatus, saveForLaterUse]);

  // run payment limits validator if source or amount changes
  useEffect(() => {
    if (!onErrorStateChange) {
      return;
    }

    const min = Math.max(minAmount, parseFloat(selectedMethodLimits?.minimum.amount ?? "0"));
    const max = Math.min(maxAmount, parseFloat(selectedMethodLimits?.maximum.amount ?? "Infinity"));
    const sign = selectedMethodLimits?.maximum.currency.sign ?? CurrencySign[currencyCode];
    const hasLimitError = amount !== undefined && (amount < min || amount > max);

    amount !== undefined && onErrorStateChange({ hasLimitError, min, max, sign });
  }, [amount, currencyCode, maxAmount, minAmount, onErrorStateChange, selectedMethodLimits]);

  // update payment method if disabled payment methods change and one of the disabled methods is selected
  useEffect(() => {
    if (disabledPaymentMethods.includes(selectedPaymentMethod)) {
      const newMethod = paymentMethods.filter((m) => !disabledPaymentMethods.includes(m.method))[0];

      if (!newMethod) {
        return;
      }

      onPaymentMethodChange(newMethod.method);
    }
  }, [disabledPaymentMethods, selectedPaymentMethod, onPaymentMethodChange, paymentMethods]);

  return disabled ? null : (
    <div className={classNames(styles.wrap, (styles as any)[`gutter--${gutter}`], className)}>
      {paymentMethods.map(({ method, name, sources }) => {
        switch (method) {
          case PaymentMethod.BANK_CARD: {
            if (isPaymentMethodDisabled(PaymentMethod.BANK_CARD, disabledPaymentMethods)) {
              return null;
            }

            const selectedBankCard = bankCardsList.find(({ id }) => id === selectedPaymentMethodSourceId)?.info;

            return (
              <Method
                key={method}
                title={name}
                caption={
                  selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID
                    ? t("Use new card")
                    : getBankCardNumber(selectedBankCard?.last4)
                }
                value={method}
                onSelect={onPaymentMethodChange}
                isActive={method === selectedPaymentMethod}
                autoOpenOnActive={selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID}
              >
                {sources?.map(({ source }) => {
                  const info = source.info as PaymentSourceCreditCard;

                  return (
                    <BankCardOption
                      key={source.id}
                      id={source.id}
                      hasExpired={source.hasExpired}
                      isActive={source.id === selectedPaymentMethodSourceId}
                      onSelect={onPaymentMethodSourceIdChange}
                      {...info}
                    />
                  );
                })}

                <BankCardOption
                  key={ADD_NEW_BANK_CARD_ID}
                  id={ADD_NEW_BANK_CARD_ID}
                  isActive={ADD_NEW_BANK_CARD_ID === selectedPaymentMethodSourceId}
                  onSelect={onPaymentMethodSourceIdChange}
                  type={t("New Credit/Debit card")}
                  brand={t("new")}
                >
                  <StripeCreditCardForm
                    withSpace={false}
                    onCreditCardHolderNameChange={onOwnerNameChange}
                    fieldColumnWidth={isMobile ? 240 : 120}
                    // onSubmitButtonClicked={onCreditCardEditClicked}
                  />
                  <Checkbox
                    className={styles["save-new-card"]}
                    checkboxStyle={"SECONDARY"}
                    onChange={() => {
                      setSaveForLaterUse(!saveForLaterUse);
                    }}
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("<strong>Recommended:</strong> Save my card details for future use"),
                        }}
                      />
                    }
                  />
                </BankCardOption>
              </Method>
            );
          }

          case PaymentMethod.SHOP_CREDIT: {
            if (isPaymentMethodDisabled(PaymentMethod.SHOP_CREDIT, disabledPaymentMethods)) {
              return null;
            }

            const shopCreditSource = sources ? sources[0]?.source : undefined;
            const info = (shopCreditSource?.info as PaymentSourceShopCredit) ?? undefined;
            return (
              <Method
                key={method}
                title={name}
                caption={
                  info && info.available
                    ? t("Available {{amount}}", {
                        amount: info.available,
                        sign: info.currency.sign,
                      })
                    : undefined
                }
                value={method}
                onSelect={(value) => {
                  onPaymentMethodChange(value);

                  if (shopCreditSource?.id) {
                    onPaymentMethodSourceIdChange(shopCreditSource.id);
                  }
                }}
                isActive={method === selectedPaymentMethod}
                secondaryLink={topUpPath ? { title: t("Top Up"), path: topUpPath } : undefined}
              />
            );
          }

          case PaymentMethod.SCR: {
            if (isPaymentMethodDisabled(PaymentMethod.SCR, disabledPaymentMethods)) {
              return null;
            }

            const scrCreditSource = sources ? sources[0]?.source : undefined;
            const info = (scrCreditSource?.info as PaymentSourceScrCredit) ?? undefined;

            // if (defaultSCR) {
            //   onPaymentMethodChange(method);

            //   if (scrCreditSource?.id) {
            //     onPaymentMethodSourceIdChange(scrCreditSource.id);
            //   }
            // }
            return (
              <Method
                key={method}
                title={name}
                caption={
                  info && info.available
                    ? t("Available {{amount}}", {
                        amount: info.available,
                        sign: info.currency.sign,
                      })
                    : undefined
                }
                value={method}
                onSelect={(value) => {
                  onPaymentMethodChange(value);

                  if (scrCreditSource?.id) {
                    onPaymentMethodSourceIdChange(scrCreditSource.id);
                  }
                }}
                isActive={method === selectedPaymentMethod}
                secondaryLink={undefined}
              />
            );
          }

          case PaymentMethod.IDEAL: {
            if (isPaymentMethodDisabled(PaymentMethod.IDEAL, disabledPaymentMethods)) {
              return null;
            }

            return (
              <Method
                key={method}
                title={name}
                value={method}
                onSelect={onPaymentMethodChange}
                isActive={method === selectedPaymentMethod}
                autoOpenOnActive
              >
                <IDealForm
                  className={styles.form}
                  onPaymentSourceHolderNameChange={onOwnerNameChange}
                  handleIsIdealBankSelected={handleIsIdealBankSelected}
                />
              </Method>
            );
          }

          default:
            return (
              <Method
                key={method}
                title={name}
                value={method}
                onSelect={onPaymentMethodChange}
                isActive={method === selectedPaymentMethod}
              />
            );
        }
      })}

      <Switch>
        {topUpPath && paymentMethods.findIndex(({ method }) => method === PaymentMethod.SHOP_CREDIT) !== -1 && (
          <Route exact path={topUpPath}>
            <TopUpModal
              refetchPaymentSources={refetch}
              paymentFlow={"CART_PURCHASE_OF_SERVICE_PRODUCTS"}
              currencyCode={currencyCode}
            />
          </Route>
        )}
      </Switch>
    </div>
  );
};

interface DefaultMethodProps<T> {
  value: T;
  isActive: boolean;
  title: ReactNode;
  onSelect: (value: T) => void;
  autoOpenOnActive?: boolean;
  caption?: string;
  secondaryLink?: { title: string; path: string };
  children?: ReactNode;
}

export const Method: FC<DefaultMethodProps<PaymentMethod>> = ({
  children,
  value,
  isActive,
  title,
  onSelect,
  autoOpenOnActive,
  caption,
  secondaryLink,
}) => {
  const [isOpened, setIsOpened] = useState(false);

  const { isTabletLandscapeOrBigger } = useWindowDimensionsContext();

  function handleToggleOpen() {
    if (!isOpened) {
      onSelect(value);
    }

    setIsOpened(!isOpened);
  }

  useEffect(() => {
    // close body if not active
    if (!isActive) {
      setIsOpened(false);
    }

    // open body if autoOpenOnActive prop is set
    if (isActive && autoOpenOnActive) {
      setIsOpened(true);
    }
  }, [isActive, autoOpenOnActive]);

  return (
    <div className={styles["method-wrap"]}>
      <Layout className={styles.content}>
        <Radio
          kind="TERTIARY"
          gap={isTabletLandscapeOrBigger ? "LARGE" : "MEDIUM"}
          value={value}
          checked={isActive}
          onChange={() => onSelect(value)}
          label={
            <Layout className={styles["label-wrap"]} wrap="TABLET_PORTRAIT_MAX">
              {title}
              {caption && <span className={styles.caption}>{caption}</span>}
            </Layout>
          }
        />
        {children && (
          <Button
            type="button"
            className={styles["toggle-button"]}
            kind="TEXT"
            height="AUTO"
            onClick={handleToggleOpen}
          >
            <IconArrow
              width={8}
              height={16}
              fill="#7789AD"
              className={classNames(styles["arrow-icon"], { [styles["arrow-icon--rotated"]]: isOpened })}
            />
          </Button>
        )}
        {secondaryLink && (
          <Link
            className={styles.link}
            to={secondaryLink.path}
            color="WHITE"
            shape="ROUND"
            height="EXTRA_SMALL"
            fontSize={12}
            weight="MEDIUM"
            width={90}
            onClick={handleToggleOpen}
          >
            {secondaryLink.title}
          </Link>
        )}
      </Layout>

      {isOpened && children}
    </div>
  );
};

function filterIsEnabled(item: { enabled: boolean; limits?: { maximum: { amount: string } } | null }) {
  return item.enabled && (!item.limits || parseFloat(item.limits.maximum.amount) > 0);
}

function sortByPaymentMethodsOrder(a: { method: PaymentMethod }, b: { method: PaymentMethod }) {
  const aIndex = PAYMENT_METHODS_ORDER.indexOf(a.method);
  const bIndex = PAYMENT_METHODS_ORDER.indexOf(b.method);

  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  }

  if (aIndex !== -1) {
    return -1;
  }

  if (bIndex !== -1) {
    return 1;
  }

  return 0;
}

export function isAmountWithinPaymentMethodLimits(amount: number, limits: any) {
  return;
}

function isPaymentMethodDisabled(paymentMethod: PaymentMethod, disabledPaymentMethods: PaymentMethod[]) {
  return disabledPaymentMethods.indexOf(paymentMethod) === -1 ? false : true;
}
