import { FC, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Heading } from "../../../components/heading/Heading";
import { Section } from "../../../components/section/Section";
import { Button } from "../../../components/button/Button";
import { IconShoppingCart2 } from "../../../components/icon/IconShoppingCart2";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import gifts from "../assets/gifts.jpg";
import Field, { FieldGutter } from "../../../components/field/Field";
import {
  ADD_NEW_BANK_CARD_ID,
  PaymentMethodsList,
  PAYMENT_METHODS_ORDER,
} from "../../../components/payment-methods-list/PaymentMethodsList";
import { PaymentMethod, PaymentEntityType, CurrencyCode } from "../../../graphql/schema";
import { useHandelPayment } from "../../../hooks/useHandlePayment";
import { useMount } from "../../../hooks/useMount";
import Price from "../../../components/price/Price";
import { Alert } from "../../../components/alert/Alert";

import styles from "./gift-card-price-section.module.scss";
interface GiftCardPriceSelectionProps {
  giftCardPrices: string[];
  currencyCode: keyof typeof CurrencyCode;
  value: string;
  minAmount?: number;
  maxAmount?: number;
  setValue: (value: string) => void;
  disabled?: boolean;
  isSCR?: boolean;
}

export const GiftCardPriceSection: FC<GiftCardPriceSelectionProps> = ({
  value,
  setValue,
  minAmount,
  maxAmount,
  currencyCode,
  giftCardPrices,
  disabled,
  isSCR = false,
}) => {
  // access translation keys
  const { t } = useTranslation();

  // check witch price is currently selected
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const [creditCardHolderName, setCreditCardHolderName] = useState("");
  const [isIdealBankSelected, setIsIdealBankSelected] = useState(false);
  const [paymentLimitError, setPaymentLimitError] = useState<string | undefined>(undefined);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(
    isSCR ? PAYMENT_METHODS_ORDER[2] : PAYMENT_METHODS_ORDER[0],
  );
  const [selectedPaymentMethodSourceId, setSelectedPaymentMethodSourceId] = useState<string | null>(null);
  const [saveCardForLaterUse, setSaveCardForLaterUse] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtherPrice, setIsOtherPrice] = useState(false);

  const handlePayment = useHandelPayment({
    onError: handleOnPaymentFailed,
    onSuccess: handleOnPaymentSuccess,
    onUnknownResponse: handleOnPaymentFailed,
    useCardForLaterUse: saveCardForLaterUse,
    selectedPaymentMethod: { id: selectedPaymentMethodSourceId, type: selectedPaymentMethod },
  });

  function handleOnPaymentSuccess() {
    setIsSubmitting(false);
    push("/account/payment-methods");
    toast.success(t("Gift card purchase successful."));
  }

  // OnUnknownResponse and Fail use same function
  function handleOnPaymentFailed() {
    setIsSubmitting(false);

    toast.error(t("Something went wrong"));
  }

  const handlePurchase = async () => {
    if (!value || value === "0") {
      setPaymentLimitError(t("Amount should not be empty"));
      return;
    }

    try {
      setIsSubmitting(true);

      await handlePayment({
        creditCardHolderName,
        paymentVariables: {
          entityType: isSCR ? PaymentEntityType.PURCHASE_OF_GIFT_CARD_SCR : PaymentEntityType.PURCHASE_OF_GIFT_CARD,
          currencyCode,
        },
        selectedPaymentSource: {
          id: selectedPaymentMethodSourceId === ADD_NEW_BANK_CARD_ID ? null : selectedPaymentMethodSourceId,
          paymentMethod: selectedPaymentMethod,
        },
        totalPrice: value,
        isIDealBankSelected: isIdealBankSelected,
        errorMessage: t("Something went wrong"),
        paymentFlow: "PAYMENT_METHODS_PURCHASE_OF_GIFT_CARD",
      });
    } catch (e) {
      setIsSubmitting(false);

      if (e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  };

  // custom price label
  const otherPrice = t("Other");

  const handlePriceChange = (price: string) => {
    if (isSubmitting) {
      return;
    }

    setValue(price !== otherPrice ? price : "0");
  };

  useMount(() => {
    setIsOtherPrice(false);
    setValue(giftCardPrices[0]);
  });

  return (
    <Section gutter={0} center withSpace>
      <Heading className={styles.title} level={3}>
        {t("Select the preferred gift card value")}
      </Heading>
      <div className={styles["price-wrap"]}>
        {giftCardPrices.map((price, index) => (
          <button
            key={index}
            className={classNames(styles.price, { [styles["price--selected"]]: price === value })}
            onClick={() => {
              setIsOtherPrice(false);
              handlePriceChange(`${price}`);
            }}
            disabled={disabled}
          >
            <Price price={price} currency={currencyCode} hideSign />
          </button>
        ))}
        <button
          className={classNames(styles.price, { [styles["price--selected"]]: isOtherPrice })}
          onClick={() => {
            if (!isOtherPrice) {
              setIsOtherPrice(true);
              handlePriceChange("0");
            }
          }}
          disabled={disabled}
        >
          {otherPrice}
        </button>
      </div>
      {isOtherPrice && (
        <Field
          className={styles.field}
          label={t("Amount")}
          type="number"
          gutter={FieldGutter.LARGE}
          onChange={(e) => {
            setValue(e ? e : "0");
          }}
          error={paymentLimitError ? { type: "", message: paymentLimitError } : undefined}
        />
      )}

      <PaymentMethodsList
        disabled={disabled}
        gutter="MEDIUM"
        selectedPaymentMethod={selectedPaymentMethod}
        selectedPaymentMethodSourceId={selectedPaymentMethodSourceId}
        onPaymentMethodChange={setSelectedPaymentMethod}
        onPaymentMethodSourceIdChange={setSelectedPaymentMethodSourceId}
        onOwnerNameChange={setCreditCardHolderName}
        amount={Number.parseFloat(value) || undefined}
        minAmount={minAmount}
        maxAmount={maxAmount}
        currencyCode={currencyCode}
        //topUpPath={`${path}/top-up`}
        handleIsIdealBankSelected={setIsIdealBankSelected}
        getSaveForLaterUseStatus={setSaveCardForLaterUse}
        onErrorStateChange={({ hasLimitError, max, sign }) => {
          if (hasLimitError && value !== "0") {
            setPaymentLimitError(
              t(
                "Total amount should not be larger than {{limit}} for selected payment method. Please split your {{purchaseType}} into smaller payments.",
                {
                  limit: max,
                  sign,
                  purchaseType: t("purchase"),
                },
              ),
            );
          }
          if (!hasLimitError && value !== "0") {
            setPaymentLimitError(undefined);
          }
        }}
        hideSCR={!isSCR}
        defaultSCR={isSCR}
        hideShopCredit={isSCR}
      />

      <Button
        className={styles["purchase-button"]}
        shape="RECT"
        kind="SOLID"
        height="MEDIUM"
        color="ORANGE"
        iconRight={<IconShoppingCart2 width={21} height={21} />}
        fontSize={18}
        weight="MEDIUM"
        borderRadius="SMALL"
        isLoading={isSubmitting}
        disabled={isSubmitting || !!paymentLimitError || disabled}
        onClick={handlePurchase}
      >
        {t("Purchase card")}
      </Button>
      <AsyncImg className={styles.image} src={gifts} />
    </Section>
  );
};
