export interface IconLogoSuccessFactoryProps {
  hasTitle?: boolean;
  className?: string;
  logoFill?: string;
  textFill?: string;
  width?: number;
  height?: number;
}

function IconLogoSuccessFactory({
  hasTitle,
  className,
  logoFill = "#FF871C",
  textFill = "#1B1F3B",
  width = 88,
  height = 37,
}: IconLogoSuccessFactoryProps) {
  return (
    <svg
      id="Layer_1"
      className={className}
      width={width}
      height={height}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 126 37"
    >
      {hasTitle && <title>IconLogoSuccessFactory</title>}
      <defs></defs>
      <g clipPath="url(#clip0_33_2288)">
        <path
          d="M13.118 36.5261C12.9371 36.4984 12.8839 36.5 12.1825 36.3549C10.7605 36.0809 9.32857 35.3104 8.37726 34.2887C8.12823 34.0166 7.89726 33.729 7.68578 33.4277C5.10579 29.7634 5.3955 24.0004 9.4921 21.5119C11.7384 20.1478 14.4811 19.3006 16.2517 17.2915C17.5292 15.8418 18.0207 13.867 17.9858 11.9851C17.9776 11.0617 17.8379 10.1439 17.5708 9.25852C17.2238 8.16592 16.5273 7.16628 16.0591 6.11282C15.5909 5.05937 15.3452 4.2758 15.4257 3.13673C15.4905 2.22025 16.1421 0.575651 16.9133 0C16.9133 0 16.8876 1.44157 17.1308 2.1485C17.3798 2.87499 17.7949 3.53463 18.2863 4.1274C19.0243 5.02431 24.9255 11.2472 25.6212 14.1173C26.6829 18.4958 24.7911 23.3073 20.526 25.2683C18.9371 26.0021 17.1366 26.206 15.6075 27.0727C14.3045 27.8304 13.3755 29.0807 13.0383 30.5307C12.6407 32.2845 13.1778 34.6891 14.8837 35.6292C16.1039 36.3027 17.4645 36.4217 18.8334 36.1763C16.7556 36.7267 15.0904 36.8253 13.118 36.5261Z"
          fill="#FF871C"
        />
        <path
          d="M6.64816 13.9403C9.75526 14.0471 11.9111 12.0748 12.874 9.34165C13.2708 8.22133 13.0242 6.6917 13.0159 5.90405C13.0159 5.94156 13.2691 6.13236 13.2982 6.16089C13.5068 6.37011 13.6991 6.59442 13.8734 6.83194C15.327 8.77904 15.6673 11.4045 14.9592 13.6941C13.4086 18.707 6.75192 18.9483 4.67664 23.5796C4.41649 24.1652 4.23806 24.7826 4.1462 25.415C3.80834 27.7103 4.22921 30.1825 5.36563 32.2193C5.78069 32.9572 6.81169 34.0791 7.48657 34.5839C7.26327 34.4428 6.36675 33.806 6.18495 33.6788C5.57897 33.2548 5.10581 32.7623 4.6177 32.2543C3.66529 31.2484 2.86416 30.1139 2.2386 28.8852C-0.208569 24.1748 0.417336 18.2349 3.71952 14.0928C4.54963 13.0459 5.92264 11.7788 6.88972 11.1803C6.7735 11.2977 4.73641 13.8743 6.64816 13.9403Z"
          fill="#FF871C"
        />
        <path
          d="M23.7194 33.5867C28.4394 30.6628 31.8653 21.8968 26.5866 14.5071C28.3058 17.6357 27.679 21.8283 25.9084 24.3103C24.2548 26.6284 23.1043 27.5783 19.011 28.4996C17.5417 28.8258 15.5187 29.6265 15.4233 32.1076C15.3403 34.2276 17.0835 35.6953 18.8018 35.6463C18.9853 35.6423 20.3699 35.6618 23.7194 33.5867Z"
          fill="#FF871C"
        />
        <path
          d="M50.1263 29.1841C48.5207 29.1841 47.3064 28.7325 46.4835 27.8294C45.6606 26.9061 45.2491 25.5915 45.2491 23.8855V22.6812H48.3802V24.1263C48.3802 25.4911 48.9522 26.1735 50.0962 26.1735C50.6582 26.1735 51.0797 26.013 51.3607 25.6918C51.6617 25.3506 51.8122 24.8087 51.8122 24.0661C51.8122 23.183 51.6115 22.4103 51.2101 21.7479C50.8087 21.0655 50.0661 20.2527 48.9823 19.3094C47.6175 18.1051 46.6641 17.0213 46.1222 16.0579C45.5803 15.0745 45.3094 13.9706 45.3094 12.7463C45.3094 11.0804 45.7308 9.79587 46.5738 8.89269C47.4168 7.96944 48.6411 7.50781 50.2467 7.50781C51.8323 7.50781 53.0265 7.96944 53.8293 8.89269C54.6522 9.79587 55.0637 11.1005 55.0637 12.8065V13.6795H51.9327V12.5957C51.9327 11.8732 51.7922 11.3513 51.5112 11.0302C51.2302 10.689 50.8188 10.5184 50.2768 10.5184C49.173 10.5184 48.621 11.1908 48.621 12.5355C48.621 13.2982 48.8217 14.0107 49.2231 14.673C49.6446 15.3354 50.3973 16.1382 51.4811 17.0815C52.866 18.2858 53.8193 19.3796 54.3412 20.3631C54.863 21.3465 55.1239 22.5006 55.1239 23.8253C55.1239 25.5513 54.6924 26.876 53.8293 27.7992C52.9864 28.7225 51.752 29.1841 50.1263 29.1841Z"
          fill="#1B1F3B"
        />
        <path
          d="M57.5872 7.80887H66.348V10.8195H60.8988V16.6901H65.1739V19.7007H60.8988V28.8831H57.5872V7.80887Z"
          fill="#1B1F3B"
        />
        <path
          d="M78.1919 29.1841C76.5863 29.1841 75.372 28.7325 74.5491 27.8294C73.7262 26.9061 73.3147 25.5915 73.3147 23.8855V22.6812H76.4458V24.1263C76.4458 25.4911 77.0178 26.1735 78.1618 26.1735C78.7238 26.1735 79.1453 26.013 79.4263 25.6918C79.7273 25.3506 79.8778 24.8087 79.8778 24.0661C79.8778 23.183 79.6771 22.4103 79.2757 21.7479C78.8743 21.0655 78.1317 20.2527 77.0479 19.3094C75.6831 18.1051 74.7297 17.0213 74.1878 16.0579C73.6459 15.0745 73.375 13.9706 73.375 12.7463C73.375 11.0804 73.7964 9.79587 74.6394 8.89269C75.4824 7.96944 76.7067 7.50781 78.3123 7.50781C79.8979 7.50781 81.0921 7.96944 81.8949 8.89269C82.7178 9.79587 83.1293 11.1005 83.1293 12.8065V13.6795H79.9983V12.5957C79.9983 11.8732 79.8578 11.3513 79.5768 11.0302C79.2958 10.689 78.8843 10.5184 78.3424 10.5184C77.2386 10.5184 76.6866 11.1908 76.6866 12.5355C76.6866 13.2982 76.8873 14.0107 77.2887 14.673C77.7102 15.3354 78.4629 16.1382 79.5467 17.0815C80.9316 18.2858 81.8849 19.3796 82.4067 20.3631C82.9286 21.3465 83.1895 22.5006 83.1895 23.8253C83.1895 25.5513 82.758 26.876 81.8949 27.7992C81.052 28.7225 79.8176 29.1841 78.1919 29.1841Z"
          fill="#1B1F3B"
        />
        <path
          d="M90.4697 29.1841C88.8641 29.1841 87.6398 28.7325 86.7968 27.8294C85.9538 26.9061 85.5324 25.5915 85.5324 23.8855V7.80887H88.844V24.1263C88.844 24.8489 88.9845 25.3707 89.2655 25.6918C89.5666 26.013 89.988 26.1735 90.5299 26.1735C91.0719 26.1735 91.4833 26.013 91.7643 25.6918C92.0654 25.3707 92.2159 24.8489 92.2159 24.1263V7.80887H95.4071V23.8855C95.4071 25.5915 94.9856 26.9061 94.1427 27.8294C93.2997 28.7325 92.0754 29.1841 90.4697 29.1841Z"
          fill="#1B1F3B"
        />
        <path d="M98.3302 7.80887H101.642V28.8831H98.3302V7.80887Z" fill="#1B1F3B" />
        <path d="M107.276 10.8195H103.814V7.80887H114.05V10.8195H110.587V28.8831H107.276V10.8195Z" fill="#1B1F3B" />
        <path
          d="M116.217 7.80887H125.249V10.8195H119.529V16.3891H124.075V19.3997H119.529V25.8725H125.249V28.8831H116.217V7.80887Z"
          fill="#1B1F3B"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_2288">
          <rect width="124.498" height="36.6921" fill="white" transform="translate(0.750977)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconLogoSuccessFactory;

<svg width="126" height="37" viewBox="0 0 126 37" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
