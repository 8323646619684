import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethod } from "../../../../graphql/schema";
import { AsyncImg } from "../../../../components/async-img/AsyncImg";
import { DigitalProductBase } from "../../../../components/digital-product-base/DigitalProductBase";
import {
  AddToCartSelector,
  AddToCartSelectorProductPackage,
  AddToCartSelectorProps,
} from "../../../../components/add-to-cart-selector/AddToCartSelector";
import { DigitalProductExclusiveOfferModal } from "../../../../components/digital-product-exclusive-offer/DigitalProductExclusiveOfferModal";
import { formatLocaleDate, timeLeft } from "../../../../services/formatDate";
import { OfferSubmitMethod } from "../../../../components/digital-product-exclusive-offer/offers/Offers";
import { Button } from "../../../../components/button/Button";
import { Color } from "../../../../services/buttonLinkConstants";
import { Link } from "../../../../components/link/Link";

import styles from "./hunter-systems.module.scss";

export interface HunterSystemsExclusiveOfferMembership {
  id: string;
  labelText?: string | null;
  datePurchased: string;
  eurPrice: string;
  dagPrice: string;
}

export interface HunterSystemsProps {
  className?: string;
  publicUrl?: string;
  packages?: AddToCartSelectorProductPackage[] | null;
  detail?: string;
  hasAccess: (() => void) | null;
  activeProducts: (string | null)[];
  exclusiveOffer?: {
    dateStart: Date;
    dateEnd: Date;
    expiresInMS: number;
    memberships: HunterSystemsExclusiveOfferMembership[];
    dagsBalance: number;
    availablePaymentMethods: PaymentMethod[];
    onSubmit(method: OfferSubmitMethod, membershipIds: string[], totalPrice: string): Promise<void>;
  };
}

export const HunterSystems: React.FC<HunterSystemsProps> = ({
  className,
  publicUrl,
  packages,
  detail,
  exclusiveOffer,
  activeProducts,
  ...props
}) => {
  const [t, i18n] = useTranslation();
  const [isExclusiveOfferOpen, setIsExclusiveOfferOpen] = useState(false);
  const [selectedMembershipIds, setSelectedMembershipIds] = useState<string[]>([]);

  function isMembershipChecked(row: { id: string }) {
    return selectedMembershipIds.includes(row.id);
  }

  function handleMembershipChecked(membershipId: string) {
    setSelectedMembershipIds(
      selectedMembershipIds.includes(membershipId)
        ? selectedMembershipIds.filter((id) => id !== membershipId)
        : [...selectedMembershipIds, membershipId],
    );
  }

  const handleHuntersSystemsClick = async (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const membershipTotalInEur = (exclusiveOffer?.memberships ?? [])
    .filter(isMembershipChecked)
    .reduce((total, row) => total + Number(row.eurPrice), 0);
  const membershipTotalInDag = (exclusiveOffer?.memberships ?? [])
    .filter(isMembershipChecked)
    .reduce((total, row) => total + Number(row.dagPrice), 0);
  // Purchase product
  return (
    <DigitalProductBase
      title={t("HunterSystems")}
      logo={<AsyncImg className={className} src="/images/hunters-logo.png" />}
      publicUrl={publicUrl}
      detail={detail ?? ""}
      price={undefined}
      exclusiveOffer={undefined}
      onExclusiveOfferClick={() => setIsExclusiveOfferOpen(true)}
      exclusiveOfferExpiryDate={exclusiveOffer?.dateEnd}
      exclusiveOfferTimeLeftMS={exclusiveOffer?.expiresInMS}
      producerName="HunterSystems"
    >
      {props.hasAccess == null && (
        <Link
          to="/hunter-systems"
          shape="RECT"
          height="MEDIUM"
          weight="MEDIUM"
          color="DARK_BLUE"
          borderRadius="SMALL"
          fontSize={18}
        >
          <span className={styles["link-label"]}>{t("Purchase")}</span>
        </Link>
        // <AddToCartSelector
        //   readionly
        //   className={styles["access-product-link"]}
        //   labelClassName={styles["link-label"]}
        //   packages={packages || []}
        //   modalPanelProps={{
        //     title: t("Select the packages"),
        //     caption: t(
        //       "Hunter Systems has {{count}} different packages, select the one(s) that you want and click “Add to cart”. You can adjust the quantity of the packages inside the cart, on the next page.",
        //       { count: packages?.length },
        //     ),
        //     label: "Hunter Systems",
        //   }}
        // />
      )}
      {props.hasAccess != null && (
        <Button
          className={styles["access-product-link"]}
          borderRadius="SMALL"
          color={Color.DARK_BLUE}
          fontSize={18}
          onClick={() => (props.hasAccess != null ? props.hasAccess() : null)}
        >
          <span className={styles["link-label"]}>{t("Access")}</span>
        </Button>
      )}

      {exclusiveOffer && isExclusiveOfferOpen && (
        <DigitalProductExclusiveOfferModal
          label={t("Hunter Systems")}
          description={t(
            "Below you can find Hunter Systems memberships you are eligible to purchase again by using dagcoin payments. This exclusive offer is <strong>ending in {{timeLeft}}</strong>, in {{date}}.",
            {
              date: formatLocaleDate(exclusiveOffer.dateEnd, i18n.language),
              timeLeft: timeLeft(exclusiveOffer.expiresInMS, t),
            },
          )}
          close={() => setIsExclusiveOfferOpen(false)}
          onMembershipChecked={handleMembershipChecked}
          memberships={exclusiveOffer.memberships.map((row) => ({ ...row, isChecked: isMembershipChecked(row) }))}
          totalPriceInEur={membershipTotalInEur}
          totalPriceInDags={membershipTotalInDag}
          dagsBalance={exclusiveOffer.dagsBalance}
          availablePaymentMethods={exclusiveOffer.availablePaymentMethods}
          onSubmit={(method) => exclusiveOffer.onSubmit(method, selectedMembershipIds, membershipTotalInDag.toString())}
        />
      )}
    </DigitalProductBase>
  );
};
