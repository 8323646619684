import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import { CustomAddShopCart } from "../banner-section/ProductHunterSystemsBannerSection";
import { HeroSectionOption } from "../../../components/hero-section/HeroSection";
import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import { IconTag } from "../../../components/icon/IconTag";
import { CurrencySign, formatPrice } from "../../../components/price/Price";
import { Separator } from "../../../components/separator/Separator";
import introImage from "./assets/imagen-tbl-mbl.png";
import { useWindowDimensionsContext } from "../../../components/windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./product-hunter-systems-intro-section.module.scss";

export interface ProductHunterSystemsIntroSectionProps {
  productCode: keyof typeof ServiceProductCode;
  product?: HeroSectionOption & {
    availability?: ServiceOfferAvailability;
    upgradableSubscriptions?: { id?: string }[];
    comingSoon?: boolean;
    period?: { code?: ServicePeriodCode };
  };
}

export const ProductHunterSystemsIntroSection: React.FC<ProductHunterSystemsIntroSectionProps> = ({
  productCode,
  product,
}) => {
  // access translation keys
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensionsContext();

  const tiers = [
    { name: "Tier 1", amount: "$600", fee: "40%", range: "$50 - $1 000" },
    { name: "Tier 2", amount: "$1 050", fee: "35%", range: "$1 001 - $5 000" },
    { name: "Tier 3", amount: "$1 500", fee: "30%", range: "$5 001 - $10 000" },
    { name: "Tier 4", amount: "$3 000", fee: "25%", range: "$5 001 - Unlimited" },
  ];

  return (
    <ViewSection
      withBackground
      image={<AsyncImg src={introImage} className={styles.image} style={{ width: "70%" }} />}
      gap="GAP_40"
      gutter={160}
    >
      <Heading className={styles.title} gutter={8} level={4}>
        {t("Lifetime Software Licenses")}
      </Heading>
      <SubTitle withBottomLine>{t("Huntersystems.io")}</SubTitle>
      <div className={styles.content}>
        <p>
          {t(
            "Our software utilizes a hybrid trading system that seamlessly blends advanced AI algorithmic trading robots with expert human analysis — both fundamental and technical — packaged into an easy-to-use solution.",
          )}
        </p>
        <p>{t("HunterSystems offers four lifetime software licenses tailored to different portfolio sizes:")}</p>
        <div className={styles["pricing-container"]}>
          {/* Desktop Table */}
          {!isMobile && (
            <div className={styles["table-container"]}>
              <table className={styles["pricing-table"]}>
                <thead>
                  <tr>
                    <th>License</th>
                    <th>Price</th>
                    <th>Fee</th>
                    <th>Deposit (USD)</th>
                  </tr>
                </thead>
                <tbody>
                  {tiers.map((tier, index) => (
                    <tr key={index}>
                      <td>{tier.name}</td>
                      <td>{tier.amount}</td>
                      <td>{tier.fee}</td>
                      <td>{tier.range}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Mobile Card Layout */}
          {isMobile && (
            <div className={styles["card-container"]}>
              {tiers.map((tier, index) => (
                <div key={index} className={styles["pricing-card"]}>
                  <h2>{tier.name}</h2>
                  <p>
                    <strong>Price:</strong> {tier.amount}
                  </p>
                  <p>
                    <strong>Fee:</strong> {tier.fee}
                  </p>
                  <p>
                    <strong>Deposit (USD):</strong> {tier.range}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <p>
          {t(
            "The clients open an account with Doo Prime, our recommended licensed broker, and deposits funds. Only the clients themselves have access to these funds. They then connect their account to the trading system via the broker’s dashboard and set their own safety limits.",
          )}
        </p>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              "<strong>Disclaimer:</strong> Trading involves significant risk, and past performance is not indicative of future results. The information provided above is for informational purposes only and does not constitute financial or investment advice. Trading and investments involve risks, and you should carefully consider your risk tolerance before making any investments or trades. HunterSystems does not guarantee profits, and the client remains solely responsible for monitoring their account and settings. All trading decisions are made at the client’s discretion. By using HunterSystems, you acknowledge that you have read, understood, and accepted these risks.",
            ),
          }}
        />

        {product && !product.comingSoon && (
          <div className={styles["cart-wrapper"]}>
            <CustomAddShopCart
              productCode={productCode}
              {...product}
              activeSubscriptionId={product?.upgradableSubscriptions?.[0]?.id}
              periodCode={product?.period?.code}
              fullPrice={(parseFloat(product?.fullPriceNext ?? "0") ?? parseFloat(product?.fullPrice ?? "0")).toFixed(
                2,
              )}
              discountedPrice={product?.fullPriceNext !== product?.fullPrice ? product?.fullPrice : null}
              className={styles["cart-button"]}
              labelClassName={styles["cart-button-label"]}
            />

            {product?.fullPriceNext !== product?.fullPrice &&
              product.availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE && (
                <span className={styles["discount-label"]}>
                  <IconTag />
                  {t("Save {{price}} now", {
                    price: `${formatPrice(
                      Number(product.fullPriceNext) - Number(product.fullPrice),
                      product.currencyCode,
                      true,
                    )}${CurrencySign[product.currencyCode]}`,
                  })}
                </span>
              )}
          </div>
        )}
      </div>
      <Separator className={styles["bottom-separator"]} type="SECONDARY" position="BOTTOM" />
    </ViewSection>
  );
};
