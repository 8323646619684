import React from "react";
import { Link } from "react-router-dom";

import IconShoppingCart from "../icon/IconShoppingCart";
import { Routes } from "../../services/constants";
import { useCartItemCount } from "../../hooks/useCartItemCount";

import styles from "./cart.module.scss";

export const Cart: React.FC = () => {
  const count = useCartItemCount();

  return (
    <Link to={Routes.CHECKOUT_CART} className={styles["shopping-cart"]}>
      <IconShoppingCart className={styles["cart-icon"]} width={24} height={24} />
      <span className={styles.counter}>{count}</span>
    </Link>
  );
};
