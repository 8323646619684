import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddServicesToCartButton } from "../add-services-to-cart-button/AddServicesToCartButton";
import { SERVICE_BUTTON_ICON_SIZE } from "../../services/constants";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";
import { formatLocaleDate, timeLeft } from "../../services/formatDate";
import { AddToCartButtonIconGutter, AddToCartButtonProps } from "../add-to-cart-button/AddToCartButton";
import { ServiceProductCode, ServicePeriodCode, PaymentMethod, ServiceOfferAvailability } from "../../graphql/schema";
import { DigitalProductBase, DigitalProductBaseProps } from "../digital-product-base/DigitalProductBase";
import { DigitalProductExclusiveOfferModal } from "../digital-product-exclusive-offer/DigitalProductExclusiveOfferModal";
import { OfferSubmitMethod } from "../digital-product-exclusive-offer/offers/Offers";
import { Button } from "../button/Button";
import { Color } from "../../services/buttonLinkConstants";

import styles from "./digital-product.module.scss";

export interface DigitalProductExclusiveOffer {
  id: string;
  periodCode: keyof typeof ServicePeriodCode;
  dateStart: Date;
  dateEnd: Date;
  expiresInMS: number;
  dagsBalance: number;
  priceInEur: string;
  priceInDag: string;
  availablePaymentMethods: PaymentMethod[];
  onSubmit(method: OfferSubmitMethod, offerIds: string[], totalPrice: string): Promise<void>;
}

export interface DigitalProductProps extends DigitalProductBaseProps, Pick<AddToCartButtonProps, "serviceCode"> {
  externalUrl?: string | false;
  website?: string;
  publicUrl?: string;
  className?: string;
  comingSoon?: boolean;
  isPaymentInfoVisible?: boolean;
  productCode?: keyof typeof ServiceProductCode;
  periodCode?: keyof typeof ServicePeriodCode;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  exclusiveOfferInfo?: DigitalProductExclusiveOffer;
  onModalOpen?(t: boolean): void;
}

export const DigitalProduct: React.FC<DigitalProductProps> = ({
  website,
  publicUrl,
  productCode,
  periodCode,
  availability,
  isPaymentInfoVisible,
  activeSubscriptionId,
  gracePeriod,
  isLoading,
  onGracePeriodRenewalSuccess,
  onPaymentMethodChange,
  exclusiveOfferInfo,
  comingSoon,
  onModalOpen,
  ...props
}) => {
  const [t, i18n] = useTranslation();
  const [isExclusiveOfferOpen, setIsExclusiveOfferOpen] = useState(false);
  const translatePeriod = useServicePeriodTranslation();
  const isActive = availability
    ? ["UNAVAILABLE_ALREADY_SUBSCRIBED", "UNAVAILABLE_FOR_UPGRADE"].includes(availability)
    : false;

  return (
    <DigitalProductBase
      isPaymentInfoVisible={isPaymentInfoVisible ?? true}
      isProductActive={isActive}
      title={props.title}
      detail={props.detail}
      publicUrl={publicUrl}
      logo={props.logo}
      price={props.price}
      priceNext={props.priceNext}
      producerName={props.producerName}
      subscribers={props.subscribers}
      isLoading={isLoading}
      exclusiveOffer={
        exclusiveOfferInfo && (
          <>
            <strong>
              <i>{t("Exclusive offer from {{product}}", { product: props.title })}</i>
            </strong>
            {" | "}
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "As of {{date}} you are exclusively invited to purchase {{product}} {{period}} membership <strong>by using dagcoins!</strong>",
                  {
                    date: formatLocaleDate(exclusiveOfferInfo.dateStart, i18n.language),
                    product: props.title,
                    period: translatePeriod(ServicePeriodCode[exclusiveOfferInfo.periodCode], "NUMERIC"),
                  },
                ),
              }}
            />
          </>
        )
      }
      gracePeriod={gracePeriod}
      onGracePeriodRenewalSuccess={onGracePeriodRenewalSuccess}
      onExclusiveOfferClick={() => setIsExclusiveOfferOpen(true)}
      onPaymentMethodChange={onPaymentMethodChange}
      comingSoon={comingSoon}
    >
      {onModalOpen && (
        <Button
          className={styles["access-product-link"]}
          borderRadius="SMALL"
          color={Color.DARK_BLUE}
          fontSize={18}
          onClick={() => onModalOpen(true)}
          style={{ marginRight: 10 }}
        >
          <span className={styles["link-label"]}>{t("Read more")}</span>
        </Button>
      )}

      {!comingSoon && productCode && periodCode && (
        <AddServicesToCartButton
          className={styles["access-product-link"]}
          productCodes={[productCode]}
          periodCode={periodCode}
          kind={isActive ? "DARK_BLUE" : "ORANGE"}
          availability={availability}
          labelClassName={styles["link-label"]}
          iconSize={SERVICE_BUTTON_ICON_SIZE.PRIMARY}
          iconGutter={AddToCartButtonIconGutter.SMALL}
          activeSubscriptionId={activeSubscriptionId}
          externalUrl={props.externalUrl}
          serviceCode={props.serviceCode}
        />
      )}

      {!comingSoon && exclusiveOfferInfo && isExclusiveOfferOpen && (
        <DigitalProductExclusiveOfferModal
          label={props.title}
          description={t(
            "You are eligible to purchase {{product}} {{period}} membership by using dagcoin payments. This exclusive offer is <strong>ending on {{timeLeft}}</strong>, in {{date}}.",
            {
              product: props.title,
              period: translatePeriod(ServicePeriodCode[exclusiveOfferInfo.periodCode], "NUMERIC"),
              date: formatLocaleDate(exclusiveOfferInfo.dateEnd, i18n.language),
              timeLeft: timeLeft(exclusiveOfferInfo.expiresInMS, t),
            },
          )}
          close={() => setIsExclusiveOfferOpen(false)}
          totalPriceInEur={Number(exclusiveOfferInfo.priceInEur)}
          totalPriceInDags={Number(exclusiveOfferInfo.priceInDag)}
          dagsBalance={exclusiveOfferInfo.dagsBalance}
          availablePaymentMethods={exclusiveOfferInfo.availablePaymentMethods}
          onSubmit={(method) =>
            exclusiveOfferInfo.onSubmit(method, [exclusiveOfferInfo.id], exclusiveOfferInfo.priceInDag)
          }
        />
      )}
    </DigitalProductBase>
  );
};
