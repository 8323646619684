import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ServiceProductCode, useProductBancusViewQuery } from "../../graphql/schema";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { notUndefinedOrNull } from "../../services/notUndefinedOrNull";
import { translateProductName } from "../../services/translateProductName";
import NotFoundView from "../not-found-view/NotFoundView";
import { ProductBancusIntroSection } from "./intro-section/ProductBancusIntroSection";
import { ProductBancusBannerSection } from "./banner-section/ProductBancusBannerSection";

export const ProductBancusView: React.FC = () => {
  const { data, loading, called } = useProductBancusViewQuery({
    variables: { productCodes: [ServiceProductCode.BANCUS] },
  });
  const { t } = useTranslation();

  // check what product is selected
  const [selectedAwenturesProductCode, setSelectedAwenturesProductCode] = useState<ServiceProductCode>(
    ServiceProductCode.BANCUS,
  );

  const products = (data?.services ?? [])
    .map((product) => {
      const offer = product && product.offers.length > 0 ? product?.offers[0] : undefined;

      if (!product || !offer) {
        return null;
      }

      return {
        ...offer,
        name: translateProductName(product, t) ?? product.name,
        value: product.code,
        currencyCode: offer.currency.code,
      };
    })
    .filter(notUndefinedOrNull);

  const selectedProduct = products.find((p) => p.value === selectedAwenturesProductCode);
  if (!loading && called && !hasAvailableOffers(products)) {
    return (
      <NotFoundView text={t("Product not available, you will be redirected back to homepage after few seconds...")} />
    );
  }

  return (
    <>
      <ProductBancusBannerSection
        productCode={selectedAwenturesProductCode}
        options={products}
        onOptionChange={setSelectedAwenturesProductCode}
      />

      <ProductBancusIntroSection productCode={selectedAwenturesProductCode} product={selectedProduct} />
    </>
  );
};
