import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import classNames from "classnames";

import { Heading } from "../../../components/heading/Heading";
import { CurrencyCode, ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import { HeroSectionOption } from "../../../components/hero-section/HeroSection";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { Layout } from "../../../components/layout/Layout";
import { ProductSelector } from "../../../components/product-selector/ProductSelector";
import { AddServicesToCartButton } from "../../../components/add-services-to-cart-button/AddServicesToCartButton";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import awenturesBannerImage from "./assets/awenturesBannerImage.png";
import awenturesMobileBannerImage from "./assets/awenturesMobileBannerImage.png";
import { IconAddCart } from "../../../components/icon/IconAddCart";
import { CurrencySign, formatPrice } from "../../../components/price/Price";
import { useWindowDimensionsContext } from "../../../components/windowDimensionsProvider/WindowDimensionsProvider";

import styles from "./product-awentures-banner-section.module.scss";

export interface ProductAwenturesBannerSectionProps {
  productCode: keyof typeof ServiceProductCode;

  options?: (HeroSectionOption & {
    availability?: ServiceOfferAvailability;
    upgradableSubscriptions?: { id?: string }[];
    comingSoon?: boolean;
    period?: { code?: ServicePeriodCode };
  })[];
  onOptionChange?: (value: any) => any;
}

export const ProductAwenturesBannerSection: React.FC<ProductAwenturesBannerSectionProps> = ({
  productCode,
  options = [],
  onOptionChange,
}) => {
  // access translation keys
  const { t } = useTranslation();
  const { isMobile } = useWindowDimensionsContext();
  const selectedOption = options.find((opt) => opt.value === productCode);

  return (
    <div className={styles.wrap}>
      <div className={styles["banner-wrap"]}>
        <AsyncImg className={styles.banner} src={awenturesBannerImage} />
      </div>
      {isMobile && <AsyncImg className={styles["mobile-banner"]} src={awenturesMobileBannerImage} />}

      <ViewSection className={styles.content} image={""} gap="GAP_40">
        <SubTitle className={styles.subtitle}>{t("Awentures.com")}</SubTitle>
        <Heading className={styles.title} level={2}>
          <span
            dangerouslySetInnerHTML={{
              __html: t("<strong>Discover the World</strong>"),
            }}
          />
        </Heading>

        <p className={styles.text}>
          {t(
            "Dive into Awentures, your ultimate gateway to unparalleled travel experiences. Explore our vast database of resorts and hotels, many of which you'll recognize from popular travel sites, but here, they come with up to a staggering 75% discount! ",
          )}
        </p>

        <Layout className={styles["button-wrap"]} wrap="MOBILE">
          {options.filter((opt) => !opt.comingSoon).length > 1 && productCode && onOptionChange && (
            <ProductSelector
              className={styles["product-selector"]}
              options={options.map((opt) => ({
                ...opt,
                currencySign: CurrencyCode[opt.currencyCode],
                fullPrice: opt.fullPrice.toString(),
                discountedPrice: opt.discountedPrice?.toString(),
              }))}
              value={productCode}
              onSelect={onOptionChange}
            />
          )}

          {!selectedOption?.comingSoon && (
            <CustomAddShopCart
              className={styles["cart-button"]}
              labelClassName={styles["cart-button-label"]}
              //   fullPrice={selectedOption?.fullPrice}
              //   discountedPrice={selectedOption?.discountedPrice}
              fullPrice={selectedOption?.fullPriceNext ?? selectedOption?.fullPrice}
              discountedPrice={
                selectedOption?.fullPriceNext !== selectedOption?.fullPrice ? selectedOption?.fullPrice : null
              }
              currencyCode={selectedOption?.currencyCode}
              productCode={productCode}
              periodCode={selectedOption?.period?.code}
              activeSubscriptionId={selectedOption?.upgradableSubscriptions?.[0]?.id}
              availability={selectedOption?.availability}
            />
          )}
        </Layout>

        <p className={styles["pricing-note"]}>{t("Price for non sf suite customers: 399$ per year")}</p>
        {/* <Link className={styles["link"]} to={""} externalUrl={"https://www.sfsuite.com/membors"}>
          <IconLink width={14} height={14} />
          Membors
        </Link> */}
      </ViewSection>
    </div>
  );
};

export interface CustomAddShopCartProps {
  productCode: keyof typeof ServiceProductCode;
  fullPrice?: string;
  discountedPrice?: string | null;
  currencyCode?: keyof typeof CurrencyCode;
  periodCode?: ServicePeriodCode;
  availability?: ServiceOfferAvailability;
  activeSubscriptionId?: string | null;
  className?: string;
  labelClassName?: string;
}

// If needed - create a separate component from this
// As it is only used by ProductMemborsView components, will export it from here.
export const CustomAddShopCart: React.FC<CustomAddShopCartProps> = ({
  productCode,
  fullPrice,
  discountedPrice,
  currencyCode,
  periodCode,
  availability,
  activeSubscriptionId,
  className,
  labelClassName,
}) => {
  const [t] = useTranslation();

  // Price component kinda does what we need, but still..
  function getTranslatedOffer(
    t: TFunction,
    price: string,
    currencyCode?: keyof typeof CurrencyCode,
    period?: ServicePeriodCode,
  ) {
    let periodString = "";
    switch (period) {
      case ServicePeriodCode.TWELVE_MONTHS:
        periodString = "/" + t("year");
        break;
      case ServicePeriodCode.SIX_MONTHS:
        periodString = "/" + t("6 months");
        break;
      case ServicePeriodCode.ONE_MONTH:
        periodString = "/" + t("month");
        break;
      case ServicePeriodCode.LIFETIME:
      default:
    }
    // const currency = currencyCode ?? "USD";
    const currency = "USD";

    return [formatPrice(price, currency, true), CurrencySign[currency], periodString].join("");
  }

  return (
    <AddServicesToCartButton
      className={classNames(styles["cart-button"], className)}
      labelClassName={labelClassName}
      hideCartIcon
      productCodes={[productCode]}
      serviceCode={productCode}
      periodCode={periodCode ?? "ONE_MONTH"}
      activeSubscriptionId={activeSubscriptionId}
      availability={availability}
    >
      {availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE ? (
        <div className={classNames(styles["custom-add-to-cart"], labelClassName)}>
          <IconAddCart width={16} height={16} />
          <span className={styles["separator"]} />

          {t("Add to cart")}

          {fullPrice && (
            <div className={styles["pricing"]}>
              {discountedPrice && (
                <span className={styles["pricing-old"]}>
                  {getTranslatedOffer(t, fullPrice, currencyCode, periodCode)}
                </span>
              )}
              {discountedPrice
                ? getTranslatedOffer(t, discountedPrice, currencyCode, periodCode)
                : getTranslatedOffer(t, fullPrice, currencyCode, periodCode)}
            </div>
          )}
        </div>
      ) : undefined}
    </AddServicesToCartButton>
  );
};

export const CustomAddShopCartDefault: React.FC<CustomAddShopCartProps> = ({
  productCode,
  fullPrice,
  discountedPrice,
  currencyCode,
  periodCode,
  availability,
  activeSubscriptionId,
  className,
}) => {
  const [t] = useTranslation();

  // Price component kinda does what we need, but still..
  function getTranslatedOffer(
    t: TFunction,
    price: string,
    currencyCode?: keyof typeof CurrencyCode,
    period?: ServicePeriodCode,
  ) {
    let periodString = "";
    switch (period) {
      case ServicePeriodCode.TWELVE_MONTHS:
        periodString = "/" + t("year");
        break;
      case ServicePeriodCode.SIX_MONTHS:
        periodString = "/" + t("6 months");
        break;
      case ServicePeriodCode.ONE_MONTH:
        periodString = "/" + t("month");
        break;
      case ServicePeriodCode.LIFETIME:
      default:
    }
    // const currency = currencyCode ?? "USD";
    const currency = "USD";

    return [formatPrice(price, currency, true), CurrencySign[currency], periodString].join("");
  }

  return (
    <AddServicesToCartButton
      className={className}
      hideCartIcon
      productCodes={[productCode]}
      serviceCode={productCode}
      periodCode={periodCode ?? "ONE_MONTH"}
      activeSubscriptionId={activeSubscriptionId}
      kind="DARK_BLUE"
      availability={availability}
    >
      {availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE ? (
        <div className={classNames(styles["custom-add-to-cart-default"])}>
          <IconAddCart width={16} height={16} />
          <span className={styles["separator"]} />

          {t("Add to cart")}

          {fullPrice && (
            <div className={styles["pricing"]}>
              {discountedPrice && (
                <span className={styles["pricing-old"]}>
                  {getTranslatedOffer(t, fullPrice, currencyCode, periodCode)}
                </span>
              )}
              {discountedPrice
                ? getTranslatedOffer(t, discountedPrice, currencyCode, periodCode)
                : getTranslatedOffer(t, fullPrice, currencyCode, periodCode)}
            </div>
          )}
        </div>
      ) : undefined}
    </AddServicesToCartButton>
  );
};
