import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ServiceProductCode, ServicePeriodCode } from "../../graphql/schema";
import { Button } from "../button/Button";
import { IconClose } from "../icon/IconClose";
import IconTrashbin2 from "../icon/IconTrashbin2";
import { Layout } from "../layout/Layout";
import Price, { CurrencyCode, CurrencySign } from "../price/Price";
import Quantity from "../quantity/Quantity";
import { ServiceIcon } from "../service-icon/ServiceIcon";
import { isFirstPeriodDiscounted } from "../../services/isFirstPeriodDiscounted";
import { useWindowDimensionsContext } from "../windowDimensionsProvider/WindowDimensionsProvider";
import { useServicePeriodTranslation } from "../../hooks/useServicePeriodTranslation";

import styles from "./cart-product.module.scss";

export type CartProductOption<T extends string> = {
  value: T;
  label: string;
};

export interface CartProductProps<T extends ServicePeriodCode> {
  productCode: keyof typeof ServiceProductCode;
  name: string;
  fullPrice: string;
  fullPriceNext?: string | null;
  discountedPrice?: string | null;
  discountedPriceNext?: string | null;
  currencyCode?: keyof typeof CurrencyCode;
  hasSuggestion?: boolean;
  priceDifference?: number;
  options: CartProductOption<T>[];
  selectedValue: T;
  label?: string | null;
  quantity?: number;
  isQuantityDisables?: boolean;
  onChange: (value: T) => any;
  onRemove?: () => void;
  isRemoving?: boolean;
  onQuantityUpdate?: (quantity: number) => void;
  productNotAvailableReason?: string;
  maxScrCredit?: string;
}

export function CartProduct<T extends ServicePeriodCode>({
  productCode,
  name,
  fullPrice,
  fullPriceNext,
  discountedPrice,
  discountedPriceNext,
  currencyCode,
  hasSuggestion,
  priceDifference,
  selectedValue,
  options,
  label,
  quantity,
  isQuantityDisables,
  onChange,
  onRemove,
  isRemoving,
  onQuantityUpdate,
  productNotAvailableReason,
  maxScrCredit,
}: CartProductProps<T>) {
  const { t } = useTranslation();
  const getPeriodName = useServicePeriodTranslation();
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(true);

  // get window dimensions
  const { isTabletPortraitOrBigger } = useWindowDimensionsContext();

  const selectedOptionLabel = options.find((o) => o.value === selectedValue)?.label || "";
  const isDisclaimerVisible = isFirstPeriodDiscounted({
    fullPrice,
    fullPriceNext,
    discountedPrice,
    discountedPriceNext,
  });

  let price: string = discountedPrice || fullPrice;
  if (productCode === ServiceProductCode.BANCUS) {
    price = (parseFloat(price) - 20).toFixed(2);
  }

  return (
    <div>
      <div className={styles.content}>
        <Layout className={styles["section-product"]}>
          <div className={styles["icon-wrap"]}>
            <ServiceIcon productCode={productCode} />
          </div>
          <div>
            {name}

            <div className={styles.price}>
              <Price price={price} currency={currencyCode} />

              {isDisclaimerVisible && "*"}
              <select
                className={styles["price-selector"]}
                value={selectedValue}
                onChange={(e) => onChange(e.target.value as unknown as T)}
              >
                {options.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.period}>{label || selectedOptionLabel}</div>
            {parseFloat(maxScrCredit ?? "0") > 0 && (
              <div className={styles.period} style={{ color: "#000" }}>
                {t("Can use up to {{maxScrCredit}} SCR ", { maxScrCredit })}
              </div>
            )}
            {isDisclaimerVisible && (
              <div className={styles["price-disclaimer"]}>
                {t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                  price: discountedPriceNext || fullPriceNext,
                  currencySign: currencyCode ? CurrencySign[currencyCode] : "",
                  period: getPeriodName(selectedValue, "SHORT"),
                })}
                {productNotAvailableReason && (
                  <div className={styles["price-warning"]}>{productNotAvailableReason}</div>
                )}
              </div>
            )}
          </div>
        </Layout>

        <Layout stretch="MOBILE">
          {quantity && onQuantityUpdate && (
            <Quantity
              name={"Quantity"}
              amount={quantity}
              onUpdate={onQuantityUpdate}
              isVertical={isTabletPortraitOrBigger}
              disabled={isQuantityDisables}
            />
          )}
          <Button
            className={styles.trashbin}
            kind="TEXT"
            onClick={() => (!isRemoving && onRemove ? onRemove() : undefined)}
            disabled={isRemoving}
          >
            <IconTrashbin2 fill="#B5B5BE" width={20} height={24} />
          </Button>
        </Layout>
      </div>

      {hasSuggestion && priceDifference && isSuggestionVisible && (
        <div className={styles["suggestion-wrap"]}>
          <div className={styles["suggestion-label"]}>{t("Popular choice")}</div>
          <div className={styles.suggestion}>
            {t("Switch to annual plan & save")} <Price price={priceDifference} currency={currencyCode} />
          </div>
          <Button className={styles.close} kind="TEXT" onClick={() => setIsSuggestionVisible(false)}>
            <IconClose width={12} height={12} fill={"#fff"} />
          </Button>
        </div>
      )}
    </div>
  );
}
