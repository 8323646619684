import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import { CustomAddShopCart } from "../banner-section/ProductBancusBannerSection";
import { HeroSectionOption } from "../../../components/hero-section/HeroSection";
import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import { IconTag } from "../../../components/icon/IconTag";
import { CurrencySign, formatPrice } from "../../../components/price/Price";
import { Separator } from "../../../components/separator/Separator";
import bancusImage from "./assets/bancusImage.png";

import styles from "./product-bancus-intro-section.module.scss";

export interface ProductBancusIntroSectionProps {
  productCode: keyof typeof ServiceProductCode;
  product?: HeroSectionOption & {
    availability?: ServiceOfferAvailability;
    upgradableSubscriptions?: { id?: string }[];
    comingSoon?: boolean;
    period?: { code?: ServicePeriodCode };
  };
}

export const ProductBancusIntroSection: React.FC<ProductBancusIntroSectionProps> = ({ productCode, product }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <ViewSection
      withBackground
      image={<AsyncImg src={bancusImage} className={styles.image} />}
      gap="GAP_40"
      gutter={160}
    >
      <Heading className={styles.title} gutter={8} level={4}>
        {t("Bancus Card for crypto")}
      </Heading>
      <SubTitle withBottomLine>{t("Bancus.io")}</SubTitle>
      <div className={styles.content}>
        <p>
          {t(
            "At Bancus, we are empowering people through innovative, accessible and secure financial solutions. The Bancus card, is a debit card that allows users all around the world to spend their cryptocurrencies at places that accept traditional debit cards. Get yours today!",
          )}
        </p>
        <ul style={{ marginLeft: "30px" }}>
          <li>{t("Card cost: $150")}</li>
          <li>{t("Shipping fee: approx. $20")}</li>
          <li>{t("Maximum load limit: $50 000")}</li>
          <li>{t("Increased load limit: after KYC")}</li>
          <li>{t("Daily spending limit: $2500 (can be increased)")}</li>
          <li>{t("Monthly/Yearly maintenance: $0")}</li>
          <li>{t("Load fee: 3.5%")}</li>
        </ul>
        <p>
          {t(
            "When you order the card, it is delivered approximately in 30 days. Make sure you have correct name, address and phone number saved in your profile for successful delivery! Once you receive the card, you will have instructions on how to set up your account and activate the card.",
          )}
        </p>
        {product && !product.comingSoon && (
          <div className={styles["cart-wrapper"]}>
            <CustomAddShopCart
              productCode={productCode}
              {...product}
              activeSubscriptionId={product?.upgradableSubscriptions?.[0]?.id}
              periodCode={product?.period?.code}
              fullPrice={(
                (parseFloat(product?.fullPriceNext ?? "0") ?? parseFloat(product?.fullPrice ?? "0")) - 20
              ).toFixed(2)}
              discountedPrice={product?.fullPriceNext !== product?.fullPrice ? product?.fullPrice : null}
              className={styles["cart-button"]}
              labelClassName={styles["cart-button-label"]}
            />

            {product?.fullPriceNext !== product?.fullPrice &&
              product.availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE && (
                <span className={styles["discount-label"]}>
                  <IconTag />
                  {t("Save {{price}} now", {
                    price: `${formatPrice(
                      Number(product.fullPriceNext) - Number(product.fullPrice),
                      product.currencyCode,
                      true,
                    )}${CurrencySign[product.currencyCode]}`,
                  })}
                </span>
              )}
          </div>
        )}
      </div>
      <Separator className={styles["bottom-separator"]} type="SECONDARY" position="BOTTOM" />
    </ViewSection>
  );
};
