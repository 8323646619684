import React from "react";
import { useTranslation } from "react-i18next";

import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Heading } from "../../../components/heading/Heading";
import { ViewSection } from "../../../components/view-section/ViewSection";
import { SubTitle } from "../../../components/sub-title/SubTitle";
import { CustomAddShopCart } from "../banner-section/ProductAwenturesBannerSection";
import { HeroSectionOption } from "../../../components/hero-section/HeroSection";
import { ServiceOfferAvailability, ServicePeriodCode, ServiceProductCode } from "../../../graphql/schema";
import { IconTag } from "../../../components/icon/IconTag";
import { CurrencySign, formatPrice } from "../../../components/price/Price";
import { Separator } from "../../../components/separator/Separator";
import awenturesImage from "./assets/awenturesImage.png";

import styles from "./product-awentures-intro-section.module.scss";

export interface ProductAwenturesIntroSectionProps {
  productCode: keyof typeof ServiceProductCode;
  product?: HeroSectionOption & {
    availability?: ServiceOfferAvailability;
    upgradableSubscriptions?: { id?: string }[];
    comingSoon?: boolean;
    period?: { code?: ServicePeriodCode };
  };
}

export const ProductAwenturesIntroSection: React.FC<ProductAwenturesIntroSectionProps> = ({ productCode, product }) => {
  // access translation keys
  const { t } = useTranslation();

  return (
    <ViewSection
      withBackground
      image={<AsyncImg src={awenturesImage} className={styles.image} />}
      gap="GAP_40"
      gutter={160}
    >
      <Heading className={styles.title} gutter={8} level={4}>
        {t("Awentures is the best-kept secret in travel")}
      </Heading>
      <SubTitle withBottomLine>{t("Awentures.com")}</SubTitle>
      <div className={styles.content}>
        <p>
          {t(
            "Life is fleeting, and every day is a canvas waiting to be painted with memories. Don't let budget constraints hold you back. With Awentures, make every day as special as possible, exploring the world's wonders more frequently and affordably.",
          )}
        </p>
        <p>
          {t(
            "Beyond accommodations, embark on majestic cruises, secure rental cars, engage in thrilling activities and book tour.",
          )}
        </p>
        {product && !product.comingSoon && (
          <div className={styles["cart-wrapper"]}>
            <CustomAddShopCart
              productCode={productCode}
              {...product}
              activeSubscriptionId={product?.upgradableSubscriptions?.[0]?.id}
              periodCode={product?.period?.code}
              fullPrice={product?.fullPriceNext ?? product?.fullPrice}
              discountedPrice={product?.fullPriceNext !== product?.fullPrice ? product?.fullPrice : null}
              className={styles["cart-button"]}
              labelClassName={styles["cart-button-label"]}
            />

            {product?.fullPriceNext !== product?.fullPrice &&
              product.availability === ServiceOfferAvailability.AVAILABLE_FOR_PURCHASE && (
                <span className={styles["discount-label"]}>
                  <IconTag />
                  {t("Save {{price}} now", {
                    price: `${formatPrice(
                      Number(product.fullPriceNext) - Number(product.fullPrice),
                      product.currencyCode,
                      true,
                    )}${CurrencySign[product.currencyCode]}`,
                  })}
                </span>
              )}
          </div>
        )}
      </div>

      <Separator className={styles["bottom-separator"]} type="SECONDARY" position="BOTTOM" />
    </ViewSection>
  );
};
