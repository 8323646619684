import { useEffect } from "react";
import { Redirect, useHistory, useLocation, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useReactiveVar, useApolloClient } from "@apollo/client";

import {
  useAppQuery,
  useStoreUserConsentMutation,
  UserConsentCode,
  ServiceOfferAvailability,
  useAppGrowMigrationAccessButtonMutation,
  UserVerificationStatus,
} from "../../graphql/schema";
import { getMaintenanceHtml } from "../../services/getMaintenanceHtml";
import { Loader } from "../loader/Loader";
import WindowDimensionsProvider from "../windowDimensionsProvider/WindowDimensionsProvider";
import { NewVersionNotifier } from "../new-version-notifier/NewVersionNotifier";
import { isNewAppVersionAvailableVar } from "../../cache/isNewAppVersionAvailable";
import { CookieConsent } from "../cookie-consent/CookieConsent";
import { isInStandaloneMode } from "../../services/isInStandaloneMode";
import { getItem } from "../../services/localStorage";
import {
  DisabledRoutesForUs,
  IS_BANK_PRODUCT_ENABLED,
  luckyLoreRegistrationId,
  Routes,
  SecondaryHeaderPathnames,
  TertiaryHeaderPathnames,
  TheViewProductCodes,
} from "../../services/constants";
import NotFoundView from "../../views/not-found-view/NotFoundView";
import RegisterLuckyLoreUserView from "../../views/lucky-lore-registration/RegisterLuckyLoreUserView";
import { UserToolbar } from "../user-toolbar/UserToolbar";
import { Header } from "../header/Header";
import HomeView from "../../views/home-view/HomeView";
import { Footer } from "../footer/Footer";
import { TermsAndConditions } from "../terms-and-conditions/TermsAndConditions";
import { AboutView } from "../../views/about-view/AboutView";
import LoginView from "../../views/login-view/LoginView";
import { AccountView } from "../../views/account-view/AccountView";
import ValidateCodeView from "../../views/validate-code-view/ValidateCodeView";
import CheckOutView from "../../views/checkout-view/CheckOutView";
import SfFeeView from "../../views/sf-fee-view/SfFeeView";
import LogoutView from "../../views/logout-view/LogoutView";
import { ProductForexInsidersLandingView } from "../../views/product-forex-insiders-landing-view/ProductForexInsidersLandingView";
import { ProductTheViewLandingView } from "../../views/product-the-view-landing-view/ProductTheViewLandingView";
import { ProductBankLandingView } from "../../views/product-bank-landing-view/ProductBankLandingView";
// import { SpecialRegistrationView } from "../../views/special-registration/SpecialRegistrationView";
import { RequestInviteView } from "../../views/request-invite-view/RequestInviteView";
import RegisterUserView from "../../views/register-user-view/RegisterUserView";
import RestorePasswordView from "../../views/restore-password-view/RestorePasswordView";
import ForgotPasswordView from "../../views/forgot-password-view/ForgotPasswordView";
import ContactView from "../../views/contact-view/ContactView";
import { EmailConfirmedView } from "../../views/email-confirmation-view/children/EmailConfirmedView";
// import { BusinessTrainingView } from "../../views/business-training-view/BusinessTrainingView";
import { removeUpsellIds } from "../../services/handleUpsellPosition";
import { isLoggedInVar } from "../../cache/isLoggedIn";
import { useOnWindowFocusChange } from "../../hooks/useOnWindowFocusChange";
import { ProductSelectorProduct } from "../products-selector/ProductsSelector";
import { IconLogoForexInsiders } from "../icon/IconLogoForexInsiders";
import { IconLogoBankProduct } from "../icon/IconLogoBankProduct";
import { AsyncImg } from "../async-img/AsyncImg";
import { hasAvailableOffers } from "../../services/hasAvailableOffers";
import { getServicesOffers } from "../../services/getServicesOffers";
import { isUserFromUs } from "../../services/isUserFromUs";
import IconBox from "../icon/IconBox";
import { IconGift2 } from "../icon/IconGift2";
import IconSubscriptions from "../icon/IconSubscriptions";
import IconUserCard from "../icon/IconUserCard";
import IconCheck from "../icon/IconCheck";
import { IconList } from "../icon/IconList";
import IconBankNote from "../icon/IconBankNote";
import IconMoneyBag from "../icon/IconMoneyBag";
import IconPieChart from "../icon/IconPieChart";
import { StripeElements } from "../stripe-elements/StripeElements";
import { tracker } from "../../libs/trackers";
import { RequireAuth } from "../require-auth/RequireAuth";
import { shouldRedirectToBecomeAffiliateViewVar } from "../../cache/shouldRedirectToBecomeAffiliateView";
import { isUserAlreadyRedirectedToAffiliateViewVar } from "../../cache/isUserAlreadyRedirectedToAffiliateView";
import { MigrationNotifier } from "../migration-notifier/MigrationNotifier";
import { ProductMemborsView } from "../../views/product-membors-view/ProductMemborsView";
import { ProductMemborsAccessView } from "../../views/product-membors-access-view/ProductMemborsAccessView";
import { IconLogoMembors } from "../icon/IconLogoMembors";
import RestoreConfirmationPasswordView from "../../views/account-view/restore-confirmation-password-view/RestoreConfirmationPasswordView";
import { ProductAwenturesView } from "../../views/product-awentures-view/ProductAwenturesView";
import { ProductBancusView } from "../../views/product-brancus-view/ProductBancusView";
import { ProductHunterSystemsView } from "../../views/product-hunter-systems-view/ProductHunterSystemsView";

export default function App() {
  const client = useApolloClient();
  const { data, loading, refetch, error } = useAppQuery({});
  const [accountLinkMutation] = useAppGrowMigrationAccessButtonMutation();
  const { t, i18n } = useTranslation();
  const isNewAppVersionAvailable = useReactiveVar(isNewAppVersionAvailableVar);
  const isAuthenticated = useReactiveVar(isLoggedInVar);
  const isUserAlreadyRedirectedToAffiliateView = useReactiveVar(isUserAlreadyRedirectedToAffiliateViewVar);
  const location = useLocation();
  const { listen, push } = useHistory();
  const [storeUserConsent] = useStoreUserConsentMutation();

  const hasAccessToWebOffice = data?.viewer?.hasAccessToWebOffice ?? false;
  const isViewerActive = data?.viewer?.isActive === "Y";
  const affiliate = data?.getAffiliate;
  const isUsCountry = data?.me ? isUserFromUs(data.me) : undefined;
  const viewerId = data?.viewer?.id;
  const hasPaidFees = data?.me?.activeMarketingFeeSubscription && data?.me?.activePlatofrmFeeSubscription;

  useOnWindowFocusChange((isInFocus) => {
    if (isInFocus) {
      client.refetchQueries({ include: "active" });
    }
  });

  useEffect(() => {
    listen((location) => {
      if (location.pathname !== "/checkout/cart/special-offer/") {
        removeUpsellIds();
      }
    });
  }, [listen]);

  // track logged in user
  useEffect(() => {
    if (isAuthenticated && viewerId) {
      tracker.identify(viewerId);
    }
  }, [isAuthenticated, viewerId]);

  // track affiliate
  useEffect(() => {
    if (data?.viewer) {
      tracker.trackEvent("referralDataReceived", { viewer: data.viewer });
    }
  }, [data]);

  const requiredConsents = (data?.me.consents ?? [])
    // filter out those which still are not agreed to
    .filter((c) => c.required && !c.granted)
    // if user is not affiliate, remove affiliate specific consents
    .filter((c) => (data?.me.info?.isAffiliate ? c.isForAffiliates : c.isForCustomers));

  const acceptedConsents = (data?.me.consents ?? []).filter((c) => c.granted);

  async function handleUpdateConsents(codes: UserConsentCode[], granted: boolean) {
    await Promise.all(codes.map((code) => storeUserConsent({ variables: { code, granted } })));

    if (granted) {
      await refetch();
    } else {
      push(Routes.LOGOUT);
    }
  }

  // render tertiary Header if url starts with pre-determined pathname
  const checkForSecondaryPathnames = () => {
    const secondaryHeaderPathnames = Object.values(SecondaryHeaderPathnames).find((url) =>
      location.pathname.startsWith(url),
    );

    if (secondaryHeaderPathnames === undefined) {
      return false;
    }

    return true;
  };

  // render tertiary Header if url starts with pre-determined pathname
  const checkForTertiaryPathnames = () => {
    const tertiaryHeaderPathnames = Object.values(TertiaryHeaderPathnames).find((url) =>
      location.pathname.startsWith(url),
    );

    if (tertiaryHeaderPathnames === undefined) {
      return false;
    }

    return true;
  };

  const handleGrowMigrationLinkClicked = async (password: string) => {
    const res = await accountLinkMutation({ variables: { secretPassword: password } });

    if (!res?.data?.growAccountAccessLink) {
      return Promise.reject(new Error("Validation failed"));
    }

    window.location.href = res.data.growAccountAccessLink;
  };

  // show logout link in header when url matches
  const renderLogoutLink = () => (location.pathname.includes("/account/email-confirmation") ? "/logout" : false);

  const accountMenuItems = [
    {
      icon: <IconBox />,
      label: t("Digital Products"),
      to: Routes.PRODUCTS,
      isVisible: true,
    },
    {
      icon: <IconList />,
      label: t("News"),
      to: Routes.NEWS_LIST,
      isVisible: true,
    },
    {
      icon: <IconGift2 />,
      label: t("Drive for Five"),
      to: Routes.DRIVE_FOR_FIVE,
      isVisible: false,
    },
    {
      icon: <IconSubscriptions />,
      label: t("Subscriptions"),
      to: Routes.SUBSCRIPTION_LIST,
      isVisible: true,
    },
    {
      icon: <IconUserCard />,
      label: t("My Profile"),
      to: Routes.PROFILE,
      isVisible: true,
    },
    {
      icon: <IconUserCard />,
      label: t("Dag Community"),
      to: Routes.DAG_COMMUNITY,
      isVisible: false,
    },
    {
      icon: <IconUserCard />,
      label: t("Games"),
      to: Routes.GAMES,
      isVisible: false,
    },
    {
      icon: <IconCheck />,
      label: t("My Orders"),
      to: Routes.INVOICES_LIST,
      isVisible: true,
    },
    {
      icon: <IconBankNote fill="unset" />,
      label: t("Payment Methods"),
      to: Routes.PAYMENT_METHODS,
      isVisible: true,
    },
    {
      icon: <IconMoneyBag />,
      label: t("Become an affiliate"),
      to: Routes.AFFILIATE,
      isVisible: !isViewerActive && hasPaidFees,
    },
    {
      icon: <IconPieChart />,
      label: t("Web Office"),
      to: `${process.env.REACT_APP_SUCCESSFACTORY_SERVER_PROXY}/${i18n.language}/member`,
      isVisible: hasAccessToWebOffice,
      isVisibleInMobile: false,
      isExternal: true,
    },
    // {
    //   icon: <IconMoneyBag />,
    //   label: t("Grow"),
    //   to: Routes.GROW_LINKING,
    //   isVisible: true,
    // },
  ]
    .filter((item) => item.isVisible)
    .map((item) => ({ ...item, isDisabled: isUsCountry ? DisabledRoutesForUs.includes(item.to as Routes) : false }));

  if (loading && !data) {
    return <Loader />;
  }
  if (error || !data) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
            padding: "0 10px",
          }}
          dangerouslySetInnerHTML={{ __html: getMaintenanceHtml() }}
        />
        {isNewAppVersionAvailable && (
          <NewVersionNotifier
            onClick={() => {
              clearBrowserCacheAndReload();
            }}
          />
        )}
      </>
    );
  }

  // check which routes to show based on application being used
  const handleContent = () => {
    if (!isAuthenticated && isInStandaloneMode() && getItem("installedAppRegistrationId") === luckyLoreRegistrationId) {
      return "LUCKY_LOORE";
    }

    return "DEFAULT";
  };

  // get user toolbar for logged in users
  const privateViewUserToolbar = () => (
    <UserToolbar
      isAffiliate={data.viewer?.isActive === "Y" ?? false}
      userName={data.me.info?.username}
      hasAccessToWebOffice={data.viewer?.hasAccessToWebOffice ?? false}
    />
  );

  if (isAuthenticated) {
    if (data?.viewer?.forceEmailChange) {
      const confirmEmailChange = location.pathname.includes("/confirm-email-change");
      const emailAccountChangeView = location.pathname.includes("account/email-confirmation");
      const viewerRequestLogout = location.pathname.includes("logout");
      if (viewerRequestLogout) {
        return <LogoutView />;
      }
      if (!emailAccountChangeView && !confirmEmailChange) {
        return <Redirect to="/account/email-confirmation" />;
      }
    }
  }

  const isUserVerified = data?.viewer?.verifyStatus === UserVerificationStatus.VERIFIED;

  // set redirect flag if user is logged in, has SF fee, but has not been yet registered as an affiliate
  if (
    !isUserAlreadyRedirectedToAffiliateView &&
    isUserAnAffiliateWithoutUsername(data.viewer?.isActive, data?.me.activeBusinessFeeSubscription)
  ) {
    isUserAlreadyRedirectedToAffiliateViewVar(true);
    shouldRedirectToBecomeAffiliateViewVar(true);
  }

  return (
    <WindowDimensionsProvider>
      {/* public and private user toolbars */}
      {!isAuthenticated && affiliate && <UserToolbar hasInvitation userName={affiliate.firstName} />}
      {isAuthenticated && privateViewUserToolbar()}

      {handleContent() !== "LUCKY_LOORE" && (
        <Header
          isAuthenticated={isAuthenticated}
          isViewerActive={isViewerActive}
          hasAccessToWebOffice={hasAccessToWebOffice}
          secondary={checkForSecondaryPathnames()}
          tertiary={checkForTertiaryPathnames()}
          navigateToUrl={renderLogoutLink()}
          products={getProductLinks({
            awentures: data.awentures,
            bancus: data.bancus,
            hunterSystems: data.hunterSystems,
          })}
          accountMenuItems={accountMenuItems}
          affiliateId={affiliate?.affiliateId ?? undefined}
        />
      )}

      {/* // show registration view for lucky loore's installed app */}
      {handleContent() === "LUCKY_LOORE" && (
        <Switch>
          <Route render={() => <RequireAuth children={<RegisterLuckyLoreUserView />} type="public" />} path="/" />
          <Route component={NotFoundView} />
        </Switch>
      )}

      {handleContent() === "DEFAULT" && (
        <StripeElements currencyCode={data.me.currency.code} locale={i18n.language}>
          <Switch>
            <Route exact path={Routes.HOME} render={() => <HomeView />} />
            {/* <Route exact path={Routes.ABOUT} render={() => <AboutView />} /> */}
            <Route
              exact
              path={Routes.LOGOUT}
              render={() => <RequireAuth children={<LogoutView />} type="private" redirectTo="HOME" />}
            />
            <Route exact path={Routes.LOGIN} render={() => <RequireAuth children={<LoginView />} type="public" />} />
            <Route
              path="/account"
              render={() => (
                <RequireAuth children={<AccountView accountMenuItems={accountMenuItems} />} type="private" />
              )}
            />
            <Route
              path="/restore-confirmation-password/:linkHash"
              render={() => <RequireAuth children={<RestoreConfirmationPasswordView />} type="private" />}
            />
            <Route
              exact
              path={Routes.VALIDATE_CODE}
              render={() => <RequireAuth children={<ValidateCodeView />} type="public" redirectTo="NOT_FOUND" />}
            />

            <Route path="/checkout" render={CheckOutView} />
            <Route path={`${Routes.SF_FEE}/:type(basic|pro)`} component={SfFeeView} />
            {IS_BANK_PRODUCT_ENABLED && <Route path={Routes.BANK_LANDING} component={ProductBankLandingView} />}
            <Route path={`${Routes.FOREX_LANDING}`} component={ProductForexInsidersLandingView} />
            <Route path={`${Routes.THE_VIEW_LANDING}`} component={ProductTheViewLandingView} />
            <Route path={`${Routes.MEMBERSHIP_LANDING}`} component={ProductMemborsView} />
            <Route path={`${Routes.AWENTURES_LANDING}`} component={ProductAwenturesView} />
            <Route path={`${Routes.BANCUS_LANDING}`} component={ProductBancusView} />
            <Route path={`${Routes.HUNTER_SYSTEMS_LANDING}`} component={ProductHunterSystemsView} />
            <Route exact path="/register-user/:id" render={() => <HomeView />} />
            <Route
              exact
              path={Routes.REQUEST_INVITE}
              render={() => <RequireAuth children={<RequestInviteView />} type="public" redirectTo="NOT_FOUND" />}
            />
            <Route exact path="/registration/:id" component={RegisterUserView} />
            <Route
              path="/forgot-password"
              render={() => <RequireAuth children={<ForgotPasswordView />} type="public" redirectTo="NOT_FOUND" />}
            />
            <Route
              path="/restore-password/:linkHash"
              render={() => <RequireAuth children={<RestorePasswordView />} type="public" redirectTo="NOT_FOUND" />}
            />
            <Route path={Routes.CONTACT} component={ContactView} />
            <Route exact path="/invite/:id/:name?" render={() => <HomeView />} />
            {/* <Route exact path={`${Routes.SPECIAL_INVITE}`} component={SpecialRegistrationView} /> */}
            {/* <Route
              path={Routes.TRAINING}
              render={() => <RequireAuth children={<BusinessTrainingView />} type="private" redirectTo="NOT_FOUND" />}
            /> */}
            <Route exact path={`${Routes.ACTIVATE_EMAIL_CONFIRMATION}/:hash`} component={EmailConfirmedView} />
            <Route component={NotFoundView} />
          </Switch>
        </StripeElements>
      )}

      {isAuthenticated && (
        <TermsAndConditions
          consents={requiredConsents}
          acceptedConsents={acceptedConsents}
          isVisible={requiredConsents.length > 0}
          isUsCountry={isUsCountry ?? false}
          onAccept={(codes) => handleUpdateConsents(codes, true)}
          onReject={(codes) => handleUpdateConsents(codes, false)}
        />
      )}

      {/* {data?.me?.growAccount && !data?.me?.growAccount.linkedAccount && data?.me?.growAccount.hasAccessLink && (
        <MigrationNotifier
          isUserVerified={isUserVerified}
          accountEmail={data?.me?.growAccount?.linkedAccount}
          onLinkClick={handleGrowMigrationLinkClicked}
        />
      )} */}

      {handleContent() !== "LUCKY_LOORE" && (
        <Footer bottomPadding={data?.me?.growAccount?.hasAccessLink ? 80 : undefined} />
      )}

      <Helmet>
        <title>SF Suite | {t("Discover, Access, Thrive")}</title>
        <meta property="og:title" content={`SF Suite | ${t("Discover, Access, Thrive")}`} />
        <meta
          property="og:description"
          content={t(
            "SF Suite has hundreds of thousands members working towards their success. SF Suite helps people reach their true potential and financial goals.",
          )}
        />
        <meta property="og:image" content={`${process.env.REACT_APP_HOST}/images/Banner-2.png`} />
        {data?.viewer && (
          <script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=3f074485-92c0-4518-bf17-b274d0708bf0"
          ></script>
        )}
      </Helmet>

      <CookieConsent />

      {isNewAppVersionAvailable && (
        <NewVersionNotifier
          onClick={() => {
            clearBrowserCacheAndReload();
          }}
        />
      )}
    </WindowDimensionsProvider>
  );
}

async function clearBrowserCacheAndReload() {
  // this forces to hard reload
  window.location.href = window.location.href.replace(/#.*$/, "");
}

interface Service {
  offers: { availability: keyof typeof ServiceOfferAvailability }[];
}

function getProductLinks(services: {
  bancus?: Service[] | null;
  awentures?: Service[] | null;
  hunterSystems?: Service[] | null;
}) {
  const productLinks: Record<"AWENTURES" | "BANCUS" | "HUNTER_SYSTEMS", ProductSelectorProduct> = {
    AWENTURES: {
      title: "Awentures.io",
      icon: <AsyncImg src={"/images/awentures-logo.png"} />,
      to: "AWENTURES_LANDING",
    },
    BANCUS: {
      title: "Bancus card",
      icon: <AsyncImg src={"/images/bancus-small-logo.png"} />,
      to: "BANCUS_LANDING",
    },
    HUNTER_SYSTEMS: {
      title: "HunterSystems",
      icon: <AsyncImg src={"/images/hunters-logo.png"} />,
      to: "HUNTER_SYSTEMS_LANDING",
    },
  };

  const availableLinks: ProductSelectorProduct[] = [];

  const awentureOffers = getServicesOffers(services.awentures ?? []);
  const bancusOffers = getServicesOffers(services.bancus ?? []);
  const hunterSystemsOffers = getServicesOffers(services.hunterSystems ?? []);

  if (hasAvailableOffers(awentureOffers)) {
    availableLinks.push(productLinks.AWENTURES);
  }

  if (hasAvailableOffers(bancusOffers)) {
    availableLinks.push(productLinks.BANCUS);
  }
  if (hasAvailableOffers(hunterSystemsOffers)) {
    availableLinks.push(productLinks.HUNTER_SYSTEMS);
  }
  return availableLinks;
}

function isUserAnAffiliateWithoutUsername(
  isActive?: string | null,
  activeBusinessFeeSubscription?: { id: string } | null,
) {
  if (!isActive) {
    return false;
  }

  return isActive === "N" && activeBusinessFeeSubscription !== null;
}
