import React from "react";

export interface IconPackageProps {
  hasTitle?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const IconPackage: React.FC<IconPackageProps> = ({ hasTitle, className, width = 20, height = 21 }) => (
  <svg className={className} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
    {hasTitle && <title>IconPackage</title>}
    <g id="package">
      <path
        id="Vector"
        opacity="0.4"
        d="M20 16.933V5.89425C20 5.28668 19.7238 4.71205 19.2494 4.33251L16.0957 1.80952C15.3864 1.24212 14.5052 0.932996 13.5969 0.932995L6.40313 0.932983C5.49484 0.932983 4.6136 1.24211 3.90434 1.80951L0.750607 4.33251C0.276178 4.71205 0 5.28668 0 5.89424V16.933C0 19.1421 1.79086 20.933 4 20.933H16C18.2091 20.933 20 19.1421 20 16.933Z"
        fill="white"
      />
      <path id="Rectangle 821" d="M7 4.93298H13V10.933L10 8.93298L7 10.933V4.93298Z" fill="white" />
      <path
        id="Vector_2"
        d="M13.5968 0.932995L6.40307 0.932983C5.49478 0.932983 4.61354 1.24211 3.90428 1.80951L0.75055 4.33251C0.541806 4.4995 0.371441 4.70427 0.246094 4.93298H19.7538C19.6284 4.70427 19.4581 4.4995 19.2493 4.33251L16.0956 1.80952C15.3863 1.24212 14.5051 0.932996 13.5968 0.932995Z"
        fill="white"
      />
    </g>
  </svg>
);
