import { useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ApolloError } from "@apollo/client";

import { Link } from "../../../components/link/Link";
import { Button } from "../../../components/button/Button";
import Field from "../../../components/field/Field";
import { Heading } from "../../../components/heading/Heading";
import { IconCheckmarkThin } from "../../../components/icon/IconCheckmarkThin";
import { Loader } from "../../../components/loader/Loader";
import NotFound from "../../../components/not-found/NotFound";
import { Section, SectionGutter } from "../../../components/section/Section";
import { Separator, SeparatorGutter } from "../../../components/separator/Separator";
import {
  useBecomeAffiliateViewQuery,
  useBecomeAffiliateMutation,
  ServiceProductCode,
  UserConsentCode,
  useBecomeAffiliateViewStoreUserConsentMutation,
} from "../../../graphql/schema";
import { gsmRegexp, ServiceInfo, spaceRegexp } from "../../../services/constants";
import { handleFormErrors } from "../../../services/handleFormErrors";
import { BusinessFeeOption } from "../../../components/business-fee-option/BusinessFeeOption";
import { IconArrowHandDrawn3 } from "../../../components/icon/IconArrowHandDrawn3";
import { getDocumentUrl } from "../../../services/getDocumentUrl";
import { isUserFromUs } from "../../../services/isUserFromUs";
import { useMount } from "../../../hooks/useMount";
import { shouldRedirectToBecomeAffiliateViewVar } from "../../../cache/shouldRedirectToBecomeAffiliateView";

import styles from "./become-affiliate-view.module.scss";

interface BecomeAffiliateFormValues {
  activationCode?: string;
  username: string;
  atcAgreement?: boolean;
  atcPlaceIncomeCenter?: boolean;
  leftUsername?: string;
  rightUsername?: string;
  wrowAgreement?: boolean;
}

const SF_FEE_SUBSCRIPTION_CODES = [ServiceProductCode.SF_MARKETING_FEE, ServiceProductCode.PLATFORM_FEE];

const listOfRequiredAffiliateConsents = [
  UserConsentCode.ATC1,
  UserConsentCode.ATC2,
  UserConsentCode.ATC3,
  UserConsentCode.ATC4,
  UserConsentCode.WROW,
  UserConsentCode.WROW2,
];

export default function BecomeAffiliateView() {
  const [t] = useTranslation();

  const query = useBecomeAffiliateViewQuery();
  const isUsCountry = isUserFromUs(query.data?.me);
  const [incomeCenterPlacement, setIncomeCenterPlacement] = useState<boolean>(false);
  const requiredConsents = (query.data?.me.consents ?? []).filter(
    (c) =>
      /* c.required && */ !c.granted && c.isForAffiliates && listOfRequiredAffiliateConsents.indexOf(c.code) !== -1,
  );

  // set forced redirect back to false so user wont be forced to redirect to this page again
  useMount(() => {
    shouldRedirectToBecomeAffiliateViewVar(false);
  });

  const { register, errors, handleBecomeAffiliate, isSubmitting } = useAffiliateViewForm({
    requiredConsentCodes: requiredConsents.map((c) => c.code),
    onSubmitError: () => {
      query.refetch();
    },
    incomeCenterPlacement,
  });

  // condition for disabling parts of form
  const hasActiveSubscription = query.data?.me.activeServiceProductCodes.some((code) =>
    SF_FEE_SUBSCRIPTION_CODES.includes(code),
  );

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  if (query.data?.me.info?.isAffiliate) {
    return <NotFound>{t("You are already an affiliate")}</NotFound>;
  }

  return (
    <div className={styles["affiliate-view"]}>
      <Loader visibleOnFirstLoad={query} />
      <form className={styles.form} onSubmit={handleBecomeAffiliate}>
        <div className={styles.business}>
          <div className={styles["affiliate-description"]}>
            <Heading level={5} center="MOBILE">
              {t("Become an affiliate")}
            </Heading>
            <p className={styles.description}>
              {t(
                "As an SF Suite affiliate you can have your own referral link and by sharing it with your friends & family, you can earn direct referral commissions from their product purchases.",
              )}
            </p>
            {/* <p className={styles.description}>
              {t(
                "All partners have to participate in the `SF Business training` course. With this online training, you'll receive all the required know-how about the products that you can sell, have access to the private sales tools, and learn about sales, sales psychology and the business opportunities.",
              )}
            </p> */}
          </div>
          {/* {!hasActiveSubscription && hasActiveSubscription !== undefined && (
            <>
              <Section className={styles["business-fee-option"]} gutter={SectionGutter.MEDIUM}>
                <Heading className={styles["option-section-title"]} level={6}>
                  {t("Step 1. Choose and purchase the preferred SF Business Fee")}
                  <div className={styles["arrow-wrap"]}>
                    <div className={styles["arrow-text"]}>{t("Step 1")}</div>
                    <IconArrowHandDrawn3 />
                  </div>
                </Heading>
                {businessFeeBasicOffer && (
                  <>
                    <Separator gutter={SeparatorGutter.SEMI_LARGE} />
                    <BusinessFeeOption
                      productCode="BUSINESS_FEE_BASIC"
                      productName={ServiceInfo["BUSINESS_FEE_BASIC"].title}
                      availability={businessFeeBasicOffer.availability}
                      activeSubscriptionId={businessFeeBasicOffer.upgradableSubscriptions[0]?.id}
                      periodCode={businessFeeBasicOffer.period.code}
                      price={businessFeeBasicOffer.discountedPrice || businessFeeBasicOffer.fullPrice}
                      currencyCode={businessFeeBasicOffer.currency.code}
                    />
                  </>
                )}
                {businessFeeProOffer && (
                  <>
                    <Separator gutter={SeparatorGutter.SEMI_LARGE} />
                    <BusinessFeeOption
                      productCode="BUSINESS_FEE_PRO"
                      productName={ServiceInfo["BUSINESS_FEE_PRO"].title}
                      availability={businessFeeProOffer.availability}
                      activeSubscriptionId={businessFeeProOffer.upgradableSubscriptions[0]?.id}
                      periodCode={businessFeeProOffer.period.code}
                      price={businessFeeProOffer.discountedPrice || businessFeeProOffer.fullPrice}
                      currencyCode={businessFeeProOffer.currency.code}
                    />
                  </>
                )}
                <Separator />
              </Section>
            </>
          )} */}
          <Section className={styles["username-wrap"]} gutter={SectionGutter.LARGE}>
            <Heading className={classNames({ [styles["title--disabled"]]: !hasActiveSubscription })} level={6}>
              {hasActiveSubscription
                ? t("Set yourself a public username")
                : t("Step 2. Set yourself a public username")}
            </Heading>
            <div>
              <Field
                inputClassName={classNames({ [styles["name-field--disabled"]]: !hasActiveSubscription })}
                type="text"
                label={t("Username")}
                name="username"
                defaultValue={
                  query.data?.me.info?.username !== query.data?.me.info?.email ? query.data?.me.info?.username : ""
                }
                internalRef={register}
                error={errors.username}
                disabled={!hasActiveSubscription}
              />
            </div>
          </Section>

          <Section gutter={40} className={styles["consent-wrap"]}>
            <>
              {/* <div
                key="place-income-centers"
                className={classNames(styles.consent, styles["income-centers-consent-wrap"])}
              >
                <Field
                  type="checkbox"
                  checkboxStyle="SECONDARY"
                  name="atcPlaceIncomeCenter"
                  internalRef={register}
                  error={errors.atcPlaceIncomeCenter}
                  label={
                    hasActiveProSubscription
                      ? t("Place Income Centers instantly in Left and Right Team")
                      : t(
                          "Place Income Centers instantly in Left and Right Team<br>(only available for Business Fee Pro)",
                        )
                  }
                  labelHasHtml
                  disabled={!hasActiveProSubscription}
                  labelClassName={classNames(styles["checkbox-label"], {
                    [styles["checkbox-label--disabled"]]: !hasActiveProSubscription,
                  })}
                  onChange={() => {
                    setIncomeCenterPlacement(!incomeCenterPlacement);
                  }}
                />
                <div></div>

                {incomeCenterPlacement && (
                  <Section className={styles["income-center-wrap"]} gutter={SectionGutter.MEDIUM}>
                    <label className={styles["income-center-label"]}>{t("Left Team")}</label>
                    <Field
                      inputClassName={classNames({ [styles["name-field--disabled"]]: !hasActiveProSubscription })}
                      type="text"
                      label={t("Username")}
                      name="leftUsername"
                      defaultValue={""}
                      internalRef={register}
                      error={errors.leftUsername}
                      disabled={!hasActiveProSubscription}
                    />
                    <label className={styles["income-center-label"]}>{t("Right Team")}</label>
                    <Field
                      inputClassName={classNames({ [styles["name-field--disabled"]]: !hasActiveProSubscription })}
                      type="text"
                      label={t("Username")}
                      name="rightUsername"
                      defaultValue={""}
                      internalRef={register}
                      error={errors.rightUsername}
                      disabled={!hasActiveProSubscription}
                    />
                  </Section>
                )}
              </div> */}
              {requiredConsents.map(({ code }) => {
                switch (code) {
                  // TODO: find a way to unify this code with <TermsAndConditions /> component
                  case UserConsentCode.ATC1:
                  case UserConsentCode.ATC2:
                  case UserConsentCode.ATC3:
                  case UserConsentCode.ATC4:
                    return (
                      <div key={code} className={styles.consent}>
                        <Field
                          type="checkbox"
                          checkboxStyle="SECONDARY"
                          name="atcAgreement"
                          internalRef={register}
                          error={errors.atcAgreement}
                          label={
                            <>
                              {t("I have read and agree to the")}{" "}
                              <Link
                                to={"#"}
                                externalUrl={getDocumentUrl("AFFILIATE_AGREEMENT", isUsCountry)}
                                color="LIGHT_GREY"
                                kind="TEXT"
                                weight="SEMI_BOLD"
                                height="AUTO"
                              >
                                {t("Affiliate Agreement")}
                              </Link>
                            </>
                          }
                          disabled={!hasActiveSubscription}
                          labelClassName={classNames(styles["checkbox-label"], {
                            [styles["checkbox-label--disabled"]]: !hasActiveSubscription,
                          })}
                        />
                      </div>
                    );

                  case UserConsentCode.WROW:
                  case UserConsentCode.WROW2:
                    return (
                      <div key={code} className={styles.consent}>
                        <Field
                          type="checkbox"
                          checkboxStyle="SECONDARY"
                          name="wrowAgreement"
                          internalRef={register}
                          error={errors.wrowAgreement}
                          label={t(
                            "I agree to the immediate execution of the Affiliate Agreement by SF Suite and I expressly waive my right to a refund",
                          )}
                          disabled={!hasActiveSubscription}
                          labelClassName={classNames(styles["checkbox-label"], {
                            [styles["checkbox-label--disabled"]]: !hasActiveSubscription,
                          })}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              })}
            </>
          </Section>
          <Button
            type="submit"
            shape="ROUND"
            color="BLUE"
            height="LARGE"
            fontSize={16}
            weight="MEDIUM"
            width={340}
            gutter="LARGE"
            iconRight={<IconCheckmarkThin className={styles.icon} />}
            disabled={isSubmitting || !hasActiveSubscription}
            isLoading={isSubmitting}
          >
            {t("Become an affiliate")}
          </Button>

          {isSubmitting && (
            <p className={styles["loading-message"]}>
              {t("Updating account, please be patient. It may take several minutes...")}
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

function useAffiliateViewForm({
  requiredConsentCodes,
  onSubmitError,
  incomeCenterPlacement,
}: {
  requiredConsentCodes: UserConsentCode[];
  onSubmitError: () => void;
  incomeCenterPlacement: boolean;
}) {
  const [t] = useTranslation();
  const { push } = useHistory();
  const [becomeAffiliate, { loading }] = useBecomeAffiliateMutation({
    refetchQueries: ["BecomeAffiliateView"],
  });
  const [storeUserConsent, { loading: storeConsentLoading }] = useBecomeAffiliateViewStoreUserConsentMutation();

  const atcSchema =
    requiredConsentCodes.includes(UserConsentCode.ATC1) || requiredConsentCodes.includes(UserConsentCode.ATC2)
      ? yup.boolean().oneOf([true], t("Please agree with the affiliate agreement"))
      : yup.boolean().notRequired();

  const wrowSchema = requiredConsentCodes.includes(UserConsentCode.WROW)
    ? yup.boolean().oneOf([true], t("Please agree with the agreement")).required(t("Please agree with the agreement"))
    : yup.boolean().notRequired();

  const becomeAffiliateSchema: yup.SchemaOf<BecomeAffiliateFormValues> = yup.object().shape({
    activationCode: yup.string().min(20, t("Invalid activation code")).max(20, t("Invalid activation code")),
    username: yup
      .string()
      .required(t("Please provide a username"))
      .test("is-gsm", t("Should only contain latin chars"), (value) => gsmRegexp.test(value || ""))
      .test("has-space", t("Username should have no space"), (value) => !spaceRegexp.test(value || ""))
      .min(4, t("Username should be at least {{char}} char long", { char: 4 }))
      .max(245, t("Username should be not longer than {{char}} chars", { char: 245 })),
    atcAgreement: atcSchema,
    atcPlaceIncomeCenter: yup.boolean().notRequired(),
    leftUsername: yup
      .string()
      .trim()
      .test("is-gsm", t("Should only contain latin chars"), (value: any) => gsmRegexp.test(value || ""))
      .test("has-space", t("Username should have no space"), (value: any) => !spaceRegexp.test(value || ""))
      .min(4, t("Username should be at least {{char}} char long", { char: 4 }))
      .max(245, t("Username should be not longer than {{char}} chars", { char: 245 }))
      .when(
        ["atcPlaceIncomeCenter", "username", "rightUsername"],
        // @ts-ignore schema param is actually there but not typed
        (atcPlaceIncomeCenter, username, rightUsername, schema) =>
          atcPlaceIncomeCenter === true
            ? schema
                .required(t("Please provide a username"))
                .notOneOf([username, rightUsername], t("Username can not be same"))
            : schema,
      ),
    rightUsername: yup
      .string()
      .test("is-gsm", t("Should only contain latin chars"), (value) => gsmRegexp.test(value || ""))
      .test("has-space", t("Username should have no space"), (value) => !spaceRegexp.test(value || ""))
      .min(4, t("Username should be at least {{char}} char long", { char: 4 }))
      .max(245, t("Username should be not longer than {{char}} chars", { char: 245 }))
      .when(
        ["atcPlaceIncomeCenter", "username"],
        // @ts-ignore schema param is actually there but not typed
        (atcPlaceIncomeCenter, username, schema) =>
          atcPlaceIncomeCenter === true
            ? schema.required(t("Please provide a username")).notOneOf([username], t("Username can not be same"))
            : schema,
      ),
    wrowAgreement: wrowSchema,
  });

  const { register, handleSubmit, errors, setError } = useForm<BecomeAffiliateFormValues>({
    resolver: yupResolver(becomeAffiliateSchema),
    defaultValues: {
      wrowAgreement: false,
      atcAgreement: false,
      atcPlaceIncomeCenter: incomeCenterPlacement,
      leftUsername: "",
      rightUsername: "",
    },
  });

  const handleBecomeAffiliate = handleSubmit(
    async ({ username, activationCode, leftUsername, rightUsername }) => {
      try {
        // become affiliate
        await becomeAffiliate({
          variables: {
            username,
            activationCode: activationCode || null,
            leftUsername,
            rightUsername,
          },
        });

        // store consents

        await Promise.all(requiredConsentCodes.map((code) => storeUserConsent({ variables: { code, granted: true } })));

        toast.success(t("You are now an affiliate!"));
        push("/");
      } catch (error) {
        if (error instanceof ApolloError) {
          handleFormErrors({ error, t, setError });
        }

        toast.error(t("Something went wrong"));
        onSubmitError();

        return;
      }
    },
    (error) => {
      console.error(error);
    },
  );

  return { register, errors, handleBecomeAffiliate, isSubmitting: loading || storeConsentLoading };
}
