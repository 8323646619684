import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Container } from "../../components/container/Container";
import { LoginForm } from "../../components/login-form/LoginForm";
import { Panel, PanelWidth } from "../../components/panel/Panel";
import { Separator, SeparatorGutter } from "../../components/separator/Separator";
import { useWindowDimensionsContext } from "../../components/windowDimensionsProvider/WindowDimensionsProvider";
import { Routes } from "../../services/constants";
import { Section } from "../../components/section/Section";

import styles from "./loginView.module.scss";
import homeStyles from "../home-view/home-view.module.scss";

export default function LoginView() {
  const { isMobile } = useWindowDimensionsContext();
  const [t] = useTranslation();

  return (
    <div className={homeStyles.home}>
      <Section gutter={0}>
        <div className={styles["container-wrap"]}>
          <div className={styles.container}>
            <div className={styles["content-wrap"]}>
              <Panel
                panelStyle={"PRIMARY"}
                width={PanelWidth.SMALL}
                title={t("Hey, welcome back!")}
                caption={t("Login to your SF Suite account")}
                fullWidth={!isMobile}
              >
                <LoginForm />

                <Separator gutter={SeparatorGutter.MEDIUM} />
                <Link to={Routes.FORGOT_PASSWORD} className={styles["forgotten-password-link"]}>
                  {t("Forgot password? Click here...")}
                </Link>
              </Panel>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
