import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { ProductBadge, ProductBadgeKind } from "../../../components/product-badge/ProductBadge";
import { Heading } from "../../../components/heading/Heading";
import { ServiceInfo } from "../../../services/constants";
import {
  AddServicesToCartButton,
  AddServicesToCartButtonProps,
} from "../../../components/add-services-to-cart-button/AddServicesToCartButton";
import {
  ServiceProductCode,
  ServicePeriodCode,
  ServiceOfferAvailability,
  CurrencyCode,
  ServiceOffer,
} from "../../../graphql/schema";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { ViewSection, ViewSectionProps } from "../../../components/view-section/ViewSection";
import { AddToCartSelector, AddToCartSelectorProps } from "../../../components/add-to-cart-selector/AddToCartSelector";
import { Link, LinkProps } from "../../../components/link/Link";
import {
  CustomAddShopCart,
  CustomAddShopCartDefault,
} from "../../product-awentures-view/banner-section/ProductAwenturesBannerSection";

import styles from "./product-showcase.module.scss";

export interface ProductShowcaseProps
  extends ViewSectionProps,
    Pick<LinkProps, "externalUrl">,
    Pick<AddServicesToCartButtonProps, "serviceCode"> {
  productCode: keyof typeof ServiceProductCode;
  periodCode: keyof typeof ServicePeriodCode;
  badgeColor: keyof typeof ProductBadgeKind;
  logo: ReactNode;
  title: string;
  badgeLabel?: string;
  availability: keyof typeof ServiceOfferAvailability | null | undefined;
  activeSubscriptionId: string | null | undefined;
  secondaryFlow?: AddToCartSelectorProps;
  selectedProduct?: any;
}

export const ProductShowcase: React.FC<ProductShowcaseProps> = ({
  children,
  productCode,
  periodCode,
  badgeColor,
  logo,
  title,
  badgeLabel,
  availability,
  secondaryFlow,
  activeSubscriptionId,
  selectedProduct,
  ...props
}) => {
  const [t] = useTranslation();

  const serviceInfo = ServiceInfo[ServiceProductCode[productCode]];

  const getButtonInfo = () => {
    if (selectedProduct) {
      return (
        <CustomAddShopCartDefault
          productCode={ServiceProductCode.AWENTURES}
          activeSubscriptionId={undefined}
          periodCode={ServicePeriodCode.TWELVE_MONTHS}
          fullPrice={selectedProduct?.fullPriceNext ?? selectedProduct?.fullPrice}
          discountedPrice={null}
          availability={selectedProduct.availability}
          className={styles.button}
          labelClassName={styles["cart-button-label"]}
        />
      );
    }

    if (secondaryFlow === undefined) {
      return (
        <AddServicesToCartButton
          className={styles.button}
          productCodes={[productCode]}
          periodCode={periodCode}
          availability={availability}
          kind="DARK_BLUE"
          externalUrl={props.externalUrl}
          activeSubscriptionId={activeSubscriptionId}
          serviceCode={props.serviceCode}
        />
      );
    }

    return (
      <AddToCartSelector
        packages={secondaryFlow.packages}
        modalPanelProps={secondaryFlow.modalPanelProps}
        hasAccess={secondaryFlow.hasAccess}
        buttonStyle="SECONDARY"
      />
    );
  };

  return (
    <ViewSection {...props} image={typeof props.image === "string" ? <AsyncImg src={props.image} /> : props.image}>
      <div className={styles.head}>
        <div className={styles["title-wrap"]}>
          <div className={styles["title-logo"]}>{logo}</div>
          <Heading className={styles["title-text"]} level={4}>
            {title}
          </Heading>
        </div>

        {badgeLabel && (
          <ProductBadge className={styles.badge} color={badgeColor}>
            {badgeLabel}
          </ProductBadge>
        )}
      </div>
      {children}
      <div className={styles["button-wrap"]}>
        {getButtonInfo()}

        <Link
          className={classNames(styles.button, styles["button--secondary"])}
          to={serviceInfo.publicUrl}
          color="WHITE"
          borderRadius="SMALL"
          fontSize={16}
          weight="MEDIUM"
          stretch
        >
          {t("Learn more")}
        </Link>
      </div>
    </ViewSection>
  );
};
