import { TFunction } from "i18next";

import { ServiceProductCode } from "../graphql/schema";

export function translateProductName(product: { code: ServiceProductCode; name?: string }, t: TFunction) {
  switch (product.code) {
    case ServiceProductCode.VIEW_BASIC:
      return t("Basic");

    case ServiceProductCode.VIEW_STANDARD:
      return t("Standard");

    case ServiceProductCode.VIEW_SUPER:
      return t("Super");

    case ServiceProductCode.VIEW_ULTRA:
      return t("Ultra");

    case ServiceProductCode.VIEW_ULTRA_PLUS:
      return t("Ultra+");

    case ServiceProductCode.HUNTER_SYSTEMS_BASIC:
      return t("Tier 1");

    case ServiceProductCode.HUNTER_SYSTEMS_STANDARD:
      return t("Tier 2");

    case ServiceProductCode.HUNTER_SYSTEMS_PLUS:
      return t("Tier 3");

    case ServiceProductCode.HUNTER_SYSTEMS_PRO:
      return t("Tier 4");

    default:
      return product.name ?? null;
  }
}
